import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import styles from '../../styles';

export default class DeviceChecklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, checkList: this.props.route.params.checkList, pulses: '', dosage: '', herd: '', online: '', alarm: '', waterflow: '', sensorMovement: '' };
    }
    componentDidMount() {
        
        this.setState({ isLoading: true, checkList: this.props.route.params.checkList, pulses: '', dosage: '', herd: '', online: '', alarm: '', waterflow: '', sensorMovement: '' })
        if(this.props.route.params.checkList == ''){
            console.log("checklist: ", this.props.route.params.checkList)
            // this.setState({
            //     checkList: []
            // })
        }
        this.renderFunction()
    }


    async renderFunction(){
        console.log(this.state.checkList)

        let checkerArr = this.state.checkList
        if(this.state.checkList == 'No checklist'){
                this.setState({
                    noData: 'TRUE'
                })
        }
        else if(this.state.checkList.length == 0){
            this.setState({
                noData: 'TRUE'
            })
        }

        let pulseError = []
        let herdError = []
        let onlineError = []
        let alarmError = []
        let waterflowError = []
        let sensorMovementError = []
        for (let i = 0; i < checkerArr.length; i++) {
            if(checkerArr[i][2] == 0){//checks pulses per active pump --returns red if no pulses
                pulseError[i] = checkerArr[i][1] + ', '
                this.setState({
                    pulses: 'error',
                    pulseError: pulseError
                })
              }
              else if(checkerArr[i][4] == 0){//checks herd# per active pump --returns red if no lsu
                herdError[i] = checkerArr[i][1] + ', '
                this.setState({
                    herd: 'error',
                    herdError: herdError
                })
              }
              else if(checkerArr[i][6] == 'FALSE'){//checks if device was communicating in last 2 hours --returns red if false
                onlineError[i] = checkerArr[i][1] + ', '
                this.setState({
                    online: 'error',
                    onlineError: onlineError
                })
              }
              else if(checkerArr[i][7] == 'TRUE'){//checks if there was a PWR alarm today --returns red if true
                alarmError[i] = checkerArr[i][1] + ', '
                this.setState({
                    alarm: 'error',
                    alarmError: alarmError
                })
              }
              else if(checkerArr[i][9] == 'No Water'){//checks if device has any waterflow in last 24 hours notifys by tank 
                waterflowError[i] = checkerArr[i][1] + ', '
                this.setState({
                    waterflow: 'error',
                    waterflowError: waterflowError
                })
              }
              else if(checkerArr[i][10] < 1){//checks if device sensor has moved in past 24 hours
                sensorMovementError[i] = checkerArr[i][1] + ', '
                this.setState({
                    sensorMovement: 'error',
                    sensorMovementError: sensorMovementError
                })
              }
        }
        this.setState({
            isLoading: false
        })
        
        
        console.log("Is loading: ", this.state.isLoading)
    }
    

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        if (this.state.noData) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <Text>The device returned no recent data. This likely means it has been offline for more than 8 hours OR all the tanks are set to zero dose.</Text>
                </View>
            );
        }
        return (
            <View style={{ flex: 1, flexDirection: 'column', padding: 20 }}>
                <View style={{ flex: 1, flexDirection: 'row', height: 50, justifyContent: 'center' }}>
                    {this.state.pulses == '' ?
                        <Text>Tanks Pulsing --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                            <Text>Tanks Pulsing --ERROR--</Text>
                            <Text>{this.state.pulseError}</Text>
                        </View>
                    }
                </View>
                <View style={{ flex: 1, flexDirection: 'row', height: 50, justifyContent: 'center' }}>
                    {this.state.herd == '' ?
                        <Text>Herd# --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                        <Text>Herd# --ERROR--</Text>
                        <Text>{this.state.herdError}</Text>
                        </View>
                    }
                </View>
                <View style={{ flex: 1, flexDirection: 'row', height: 50,  justifyContent: 'center' }}>
                    {this.state.online == '' ?
                        <Text>Online 2hrs --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                        <Text>Offline over 2hrs --ERROR--</Text>
                        <Text>{this.state.onlineError}</Text>
                        </View>
                    }
                </View>
                <View style={{ flex: 1, flexDirection: 'row', height: 50, justifyContent: 'center' }}>
                    {this.state.alarm == '' ?
                        <Text>PWR Alarm check --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                        <Text>PWR Alarm active --ERROR--</Text>
                        <Text>{this.state.alarmError}</Text>
                        </View>
                    }
                </View>
                <View style={{ flex: 1, flexDirection: 'row', height: 50, justifyContent: 'center' }}>
                    {this.state.waterflow == '' ?
                        <Text>Waterflow --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                        <Text>No Wateflow 24hrs --ERROR--</Text>
                        <Text>{this.state.waterflowError}</Text>
                        </View>
                    }
                </View>
                <View style={{ flex: 1, flexDirection: 'row', height: 50, justifyContent: 'center' }}>
                    {this.state.sensorMovement == '' ?
                        <Text>Sensor Moving --OK--</Text> :
                        <View style={{backgroundColor: '#FF0000'}}>
                        <Text>No Sensor movement 24hrs --ERROR--</Text>
                        <Text>{this.state.sensorMovementError}</Text>
                        </View>
                    }
                </View>
                <TouchableOpacity style={styles.button}
                onPress={() => this.props.navigation.navigate('Farm Details', {
                    Id: this.props.route.params.Id,
                    DeviceId: this.props.route.params.DeviceId,
                    user: this.props.route.params.user
                  })}>
                <Text style={styles.buttonTitle}>Exit</Text>
                </TouchableOpacity>
            </View>
        );          
    }
}