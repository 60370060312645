import { ActivityIndicator, View } from 'react-native'
import { Caption, DataTable, IconButton, Text, Title } from 'react-native-paper'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class ViewDelivery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tanks: [] };
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true })
            this.getData()
        })
    }
    async getData() {
        var requestBody = {
            context: 'deliveryDetails',
            DeliveryId: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        console.log(data)
        this.setState({
            isLoading: false,
            deliveryNumber: data[0][0],
            date: data[0][2].substring(0, data[0][2].indexOf(':') - 2),
            userFeedback: data[0][3],
            deliveredBy: data[0][4],
            tanks: [JSON.parse(data[0][5]), JSON.parse(data[0][6]), JSON.parse(data[0][7]),
            JSON.parse(data[0][8]), JSON.parse(data[0][9]), JSON.parse(data[0][10])],
            isCollapsed: [true, true, true, true, true, true],
            submissionTime: data[0][13],
            extraDelivery: data[0][14] == "Not Available" ? "Not Available" : JSON.parse(data[0][14])

        })
        let ed = JSON.parse(data[0][14])
        ed['tankNo'] = 'Extra Delivery'
        this.setState({
            extraDelivery: ed
        })

    }
    accordian(tankNum) {
        var tanksCollapsed = this.state.isCollapsed
        tanksCollapsed[tankNum - 1] = !tanksCollapsed[tankNum - 1]
        this.setState({
            isCollapsed: tanksCollapsed
        })
    }

    getTankDisplay(tankData) {
        if (tankData != undefined) {
            var batchData = tankData['Batch']
            if (batchData[0]) {
                if (this.state.isCollapsed[tankData['tankNo'] - 1]) {
                    return (
                        <DataTable>
                            <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                                <DataTable.Title onPress={() => (this.accordian(tankData['tankNo']))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankData['tankNo']}</Title></DataTable.Title>
                            </DataTable.Header>
                        </DataTable>
                    )
                }
                return (
                    <DataTable>
                        <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                            <DataTable.Title onPress={() => (this.accordian(tankData['tankNo']))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankData['tankNo']}</Title></DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row key={"levelBefore" + tankData['tankNo']}>
                            <DataTable.Cell>
                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Level (before): </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{tankData['levelBefore'] > 0 ? tankData['levelBefore'] : 0}%</Text>
                            </DataTable.Cell>
                            <DataTable.Row key={"levelAfter" + tankData['tankNo']}></DataTable.Row>
                            <DataTable.Cell>
                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Level (after): </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{tankData['levelAfter'] > 0 ? tankData['levelAfter'] : 0}%</Text>
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            batchData.map((item, index) => {
                                return (<View>
                                    <DataTable.Row key={"Batch" + tankData['tankNo'] + "-" + index}>
                                        <DataTable.Cell>
                                            <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Product: </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{item[3]}</Text>
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Batch: </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{item[1]}</Text>
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Amount: </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{item[2] > 0 ? item[2] : 0} kg</Text>
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                    <DataTable.Row key={"Batch" + tankData['tankNo'] + "-" + index + 'B'}>
                                        <DataTable.Cell>
                                            <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Container Type: </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{item[4]}</Text>
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Container ID: </Caption><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>{item[5]}</Text>
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                </View>
                                )
                            })
                        }
                    </DataTable>
                )
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <View>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginBottom: '2%', marginTop: '3%' }}>
                            <IconButton color={'#7fbe41'} icon='arrow-left' onPress={
                                () => {
                                    this.props.navigation.navigate('View Delivery', {
                                        Id: (this.props.route.params.Id - 1),
                                        user: this.props.route.params.user
                                    });
                                    this.getData()
                                }
                            } />

                            <Text style={{ marginTop: 15, alignSelf: 'center', marginBottom: 15, fontSize: 24 }}>View Delivery Details #{this.state.deliveryNumber}</Text>
                            <IconButton color={'#7fbe41'} icon='arrow-right' onPress={
                                () => {
                                    this.props.navigation.navigate('View Delivery', {
                                        Id: (this.props.route.params.Id + 1),
                                        user: this.props.route.params.user
                                    });
                                    this.getData()
                                }
                            } />
                        </View>


                        <DataTable style={{ marginTop: 0 }}>
                            <DataTable.Header style={{ marginTop: 0, backgroundColor: 'rgba(52,93,174,0.3)' }}>
                                <DataTable.Title style={{ marginTop: 0 }}><Title>Details</Title></DataTable.Title>
                            </DataTable.Header>
                        </DataTable>
                        <DataTable.Row key={"Date"}>
                            <DataTable.Cell>
                                <Caption>Date: </Caption><Text style={{ textAlign: 'right' }}>{this.state.date}</Text>
                            </DataTable.Cell>
                            <DataTable.Cell>
                                <Caption>Time: </Caption><Text style={{ textAlign: 'right' }}>{this.state.submissionTime}</Text>
                            </DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row key={"DeliveredBy"}>
                            <DataTable.Cell>
                                <Caption>Delivered By: </Caption>{this.state.deliveredBy}
                            </DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row key={"UserFeedback"}>
                            <DataTable.Cell>
                                <Caption>User Feedback: </Caption>{this.state.userFeedback}
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            this.state.tanks.map(item => <View>{this.getTankDisplay(item)}</View>)
                        }
                        {
                            this.state.extraDelivery == "Not Available" ? <View></View> : <View>{this.getTankDisplay(this.state.extraDelivery)}</View>
                        }
                    </View>
                </KeyboardAwareScrollView>
            </View>
        )
    }
}