import { AsyncStorage} from 'react-native'

export async function getDataAPI(requestBody) {
    const token = await AsyncStorage.getItem('token')
    const originalFetch = require('isomorphic-fetch');
    const fetch = require('fetch-retry')(originalFetch, {
    retries: 10,
    retryDelay: 800
    });
    var data = {}
    const url = global.api + '/search'
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `${token}`
        },
        body: JSON.stringify(requestBody)})
        .then(response => response.json())
        .then(responseJson => {
            data = responseJson
        })
        .catch(error => {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",error)
        });

        return data;
}

export async function setDataAPI(requestBody) {
    const token = await AsyncStorage.getItem('token')
    var data = {}
    var originalFetch = require('isomorphic-fetch');
    var fetch = require('fetch-retry')(originalFetch, {
    retries: 10,
    retryDelay: 800
    });
    var url =  global.api + '/save'
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `${token}`
        },
        body: JSON.stringify(requestBody)})
        .then(response => response.json())
        .then(responseJson => {
            data = responseJson
        })
        .catch(error => {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",error)
        });

    return data;
}
