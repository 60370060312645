// Import getEnvVars() from environment.js
import getEnvVars from './environment';
const { apiUrl, apiKey } = getEnvVars();


global.ip = apiUrl //erp-terra-api domain name
global.ipLocal = 'http://127.0.0.1:5000'
global.api = global.ip

global.Apikey = apiUrl //browser key

global.products = [[1, "No Product"], [3, "Bloat"], [5, "Phosphorus"], [6, "Selenium"], [7, "Thrive"], [8, "Dry Cow"], [9, "C-Mag"], [10, "Low Mag"], [11, "High Mag"], [12, "Fertility"], [13, "Maintain HC"], [14, "Calving+"], [15, "Young Stock"], [16, "High Phos Fertility"], [17, "Beef Finisher"], [18, "Nitrobond"], [19, "Copper"], [20, "Zinc"], [21, "Cobalt"], [22, "Iodine"]]
