import { ActivityIndicator, Dimensions, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'

import { DataTable } from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { Searchbar } from 'react-native-paper'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default class adminChecklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            filteredData: [],
            dimensions: {
                window,
                screen
            }
        };
    }

    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
            return item[33].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
        console.log("filteredData",filteredData)
    };

    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    };

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true })
            this.getData()
            Dimensions.addEventListener("change", this.onChange);
        })


    }
    async getData() {
        var requestBody = {
            context: 'getAdminChecklist',
            distributorId: this.props.route.params.distributorId
        }
        var checklist = await getDataAPI(requestBody)

        var filteredChecklist = []
        for (let i = 0; i < checklist.length; i++) {
            if(checklist[i][2] == 0){
                filteredChecklist.push(checklist[i])
            }
            else if(checklist[i][6] == 'FALSE'){
                filteredChecklist.push(checklist[i])
            }
            else if(checklist[i][7] == 'TRUE'){
                filteredChecklist.push(checklist[i])
            }
            else if(checklist[i][9] == 'No Water'){
                filteredChecklist.push(checklist[i])
            }

        }
        this.setState({
            isLoading: false,
            data: filteredChecklist,
            filteredData: filteredChecklist,
        })
    }



    render() {
        const { dimensions } = this.state;
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1 }}>
                    <Searchbar placeholder="Search checklists by farm..." onChangeText={this.search} value={this.state.searchText} />
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        if (dimensions.window.width < 500) {
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search checklists by farm..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Farm</DataTable.Title>
                                    <DataTable.Title>TankId</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData.map(checklist => {
                                                return (
                                                    <DataTable.Row onPress={() => this.props.navigation.navigate('Farm Details', {
                                                        Id: checklist[43],
                                                        CompanyName: checklist[33],
                                                        BoxName: checklist[34],
                                                        DeviceId: checklist[0],
                                                        user: this.props.route.params.user
                                                    })}>
                                                        <DataTable.Cell>
                                                            {checklist[33]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[1]}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search checklist by farm..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Farm</DataTable.Title>
                                    <DataTable.Title>TankId</DataTable.Title>
                                    <DataTable.Title>PWR Alarm</DataTable.Title>
                                    <DataTable.Title>Waterflow</DataTable.Title>
                                    <DataTable.Title>Pulses</DataTable.Title>
                                    <DataTable.Title>HerdSize</DataTable.Title>
                                    <DataTable.Title>Dosage</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData.map(checklist => {
                                                return (
                                                    <DataTable.Row onPress={() => this.props.navigation.navigate('Farm Details', {
                                                        Id: checklist[43],
                                                        CompanyName: checklist[33],
                                                        BoxName: checklist[34],
                                                        DeviceId: checklist[0],
                                                        user: this.props.route.params.user
                                                    })}>
                                                        <DataTable.Cell>
                                                            {checklist[33]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[1]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[7]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[9]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[2]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[4]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[3]}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )
        }
    }
}
