import { ActivityIndicator, Dimensions, Picker, SafeAreaView, ScrollView, TextInput, TouchableOpacity, View } from 'react-native'
import { Button, IconButton, List, Modal, Paragraph, Portal, Searchbar, Subheading, Text, Title } from 'react-native-paper'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { database } from 'firebase';
import styles from '../../styles';

export default class AssignRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productDropdownDefaults: [['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product']],
            isLoading: true, routeCode: this.props.route.params.routeCode,
            routeData: [], farmList: [], userAssigned: 'UNASSIGNED', farmSelected: [],
            visible: false, searchText: '', filteredFarmsList: [], farmToAddSelected: [],
            removeVisible: false, selectedCount: 0, hasPriority: 2, isActive: 'true',
            productVisible: false, products: [], productDropdown: [['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product']],
            productAmount: [[], [], [], [], [], []],
            farmPressed: [], farmPressed_dates: [['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1]],
            allTanks: [], mainProductDropdown: [], tanksRowsCount: [1, 1, 1, 1, 1, 1], screenHeight: 600, mineralNotesTextArea: '', miscFocused: false, farmEmptyDates: [],
            routeNotesFocused: false, routeNotesTextArea: ''
        };
    }

    showProductModal = () => {
        this.setState({ productVisible: true })
    }
    hideProductModal = () => {
        this.setState({ productVisible: false })
    }

    showModal = () => {
        this.setState({ visible: true })
    }
    hideModal = () => {
        this.setState({ visible: false })
    }

    showConfirmRemove = () => {
        this.setState({ removeVisible: true })
    }
    hideConfirmRemove = () => {
        this.setState({ removeVisible: false })
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({
                productDropdownDefaults: [['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product']],
                isLoading: true, routeCode: this.props.route.params.routeCode,
                routeData: [], farmList: [], userAssigned: 'UNASSIGNED', farmSelected: [],
                visible: false, searchText: '', filteredFarmsList: [], farmToAddSelected: [],
                removeVisible: false, selectedCount: 0, hasPriority: 2, isActive: true,
                productVisible: false, products: [], productDropdown: [['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product'], ['No Product']],
                productAmount: [[], [], [], [], [], []],
                farmPressed: [], farmPressed_dates: [['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1]],
                allTanks: [], mainProductDropdown: [], tanksRowsCount: [1, 1, 1, 1, 1, 1], mineralNotesTextArea: '', miscFocused: false, routeNotesFocused: false, routeNotesTextArea: ''
            })
            this.getData()
        })
    }

    async getCapacity() {
        var tempArr = []


        for (let i = 0; i < this.state.allTanks.length; i++) {

            var allTanks = this.state.allTanks[i].vols
            console.log(this.state.allTanks)
            console.log(this.state.allTanks[i].vols)
            // if(this.state.allTanks[i].vols)
            var shuffleArr = []
            for (let j = 0; j < allTanks.length; j++) {
                var shuffledArray = [allTanks[j][5], allTanks[j][0], allTanks[j][9], allTanks[j][3], allTanks[j][1]]
                shuffleArr.push(shuffledArray)
            }

            tempArr.push(shuffleArr)
        }
        this.setState({
            tankCapacities: tempArr
        })
        console.log("--------------------------------TankCapacities----------------------------------")
        console.log(this.state.tankCapacities)
    }

    async getEmptyDateForFarmPressed(farm) { //for product modal
        let dates = this.state.farmPressed_dates
        var deviceTanks = await this.getEDReq(farm[4])

        console.log("In getEmptyDate for farm pressed!!!!!!!")
        console.log("emptydate data for this device: ", deviceTanks)

        for (let i = 0; i < deviceTanks.length; i++) {
            if (deviceTanks[i]) {
                if (deviceTanks[i][3] == 'Herd LSU 0' || deviceTanks[i][3] == 'Tank dosage 0' || deviceTanks[i][3] == 'NO READINGS' || deviceTanks[i][3] == 'Tank capacity 0') {
                    dates[i] = ['unknown', '', i + 1]
                    continue
                }
                else {
                    dates[i] = [deviceTanks[i][3], deviceTanks[i][2], i + 1]
                }
            }



        }

        this.setState({
            farmPressed_dates: dates
        })
    }

    async getEDReq(deviceId) {
        var requestBody = {
            context: 'getEmptyDateForAnyFarm',
            DeviceId: deviceId
        }
        var data = await getDataAPI(requestBody)

        return data
    }
    getClosestED(edList) {
        let mostRecent = ['01/01/2900', 'Magnesium']
        for (let i = 0; i < edList.length; i++) {
            console.log(edList[i])
            if (edList[i][3] == 'Herd LSU 0' || edList[i][3] == 'Tank dosage 0' || edList[i][3] == 'NO READINGS' || edList[i][3] == 'Tank capacity 0' || edList[i][3] == 'NO DATA FOR 2 DAYS' || edList[i][3] == null) {
                continue
            }
            var mrdateParts = mostRecent[0].split("/")
            console.log("mrdateparts#########:", mrdateParts)
            let d1 = new Date(+mrdateParts[2], mrdateParts[1] - 1, +mrdateParts[0])


            var dateParts = edList[i][3].split("/")
            // month is 0-based, that's why we need dataParts[1] - 1
            let d2 = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

            console.log("Static set date: ", d1)
            console.log("Compare date: ", d2)
            console.log("Compare date raw: ", edList[i][3])
            if (d2 < d1) {
                console.log("Date 1 is less than date 2")
                mostRecent = [edList[i][3], edList[i][2]]
            }
        }
        if (mostRecent[0] == '01/01/2900') {
            return ['', '']
        }
        return mostRecent
    }
    async getEmptyDates() {
        var newArr = []
        newArr = this.state.farmList
        let ed = this.state.farmEmptyDates

        for (let i = 0; i < newArr.length; i++) {
            ed[i] = [newArr[i][4], this.getClosestED(await this.getEDReq(newArr[i][4]))]

        }
        console.log("!!!!!!!!!!!!!!!!!! new ed (emptyDate) results: ", ed)
        this.setState({
            farmEmptyDates: ed
        })

        console.log("---------------------empty dates-------------------------")
        console.log(this.state.farmList)
    }

    async getData() {
        var requestBody = {
            context: 'routeInfo',
            RouteCode: this.state.routeCode
        }
        var response = await getDataAPI(requestBody)

        await this.getScreenHeight()
        //set all selected farms to false initially
        var fSelected = []
        response['RouteFarms'].forEach(farm => {
            fSelected[farm[0]] = false
        });
        var fAddSelected = []
        response['AllFarmsList'].forEach(f => {
            fAddSelected[f[0]] = false
        });

        //get priority order for farms
        var fPriority = []
        var fPNew = []
        var sortedCount = 0
        response['RouteFarms'].forEach(farm => { if (farm[3] >= 0) { sortedCount = sortedCount + 1 } });
        response['RouteFarms'].forEach(farm => { if (farm[3] < 0) { farm[3] = sortedCount; sortedCount = sortedCount + 1 } });
        //if theres unsorted farms, add them on the end
        if (fPNew.length > 0) {
            fPNew.forEach(f => { fPriority.push(f) })

        }
        this.setState({
            routeData: response['RouteData'],
            farmList: this.sortByPriority(response['RouteFarms']),
            userList: response['UserList'],
            userAssigned: response['RouteData'][0] != undefined ? response['RouteData'][0][1] : 'UNASSIGNED',
            allFarmsList: response['AllFarmsList'],
            farmSelected: fSelected,
            farmToAddSelected: fAddSelected,
            farmPriority: fPriority,
            hasPriority: response['RouteData'][0] != undefined ? response['RouteData'][0][2] : 2,
            selectedCount: 0,
            isActive: response['RouteData'][0] != undefined ? response['RouteData'][0][3] : false,
            products: response['Products'],
            filteredFarmsList: this.getFiltered(response['AllFarmsList']),
            routeNotesTextArea: response['RouteData'][0][4]
        })

        await this.getRecentIssues()
        var allTanks = []
        for (let i = 0; i < this.state.farmList.length; i++) {
            var thing = {
                vols: await this.getTankVolume(this.state.farmList[i][4]),
                Id: this.state.farmList[i][4]
            }

            allTanks.push(thing)
        }

        this.setState({
            allTanks: allTanks
        })

        await this.getCapacity()

        console.log("-----------------------FarmList------------------------")
        console.log(this.state.farmList)
        console.log("-----------------------FarmList END------------------------")
        console.log(response)

        await this.getEmptyDates()

        this.setState({
            isLoading: false
        })
    }
    async getMostRecentIssue(id) {
        var requestBody = {
            context: 'getMostRecentIssue',
            FarmId: id
        }
        var data = await getDataAPI(requestBody)
        console.log(data[0])
        return data[0]
    }
    async getRecentIssues() {
        let fl = this.state.farmList
        let ids = []
        let result = []
        for (let i = 0; i < fl.length; i++) {
            ids.push(fl[i][0])
        }
        for (let i = 0; i < ids.length; i++) {
            result.push([ids[i], await this.getMostRecentIssue(ids[i])])
        }
        this.setState({
            recentIssues: result
        })
    }
    getIssueText(id) {
        let text = ''
        for (let i = 0; i < this.state.recentIssues.length; i++) {
            if (this.state.recentIssues[i][0] != undefined) {
                if (this.state.recentIssues[i][0] == id) {
                    text = this.state.recentIssues[i][1] == undefined ? '' : this.state.recentIssues[i][1]
                }
            }
        }
        return text
    }

    getFiltered(obj) {
        return obj.filter(function (item) {
            return item[1].toLowerCase().includes('b');
        })
    }
    async getTankVolume(deviceId) {
        var requestBody = {
            context: 'tankVolumes',
            DeviceId: deviceId
        }
        var data = await getDataAPI(requestBody)

        return data
    }

    async submitFarmsToRoute() {
        this.hideModal()
        var fToAdd = []
        for (let i = 0; i < this.state.farmToAddSelected.length; i++) {
            if (this.state.farmToAddSelected[i]) {
                for (let j = 0; j < this.state.allFarmsList.length; j++) {
                    if (this.state.allFarmsList[j][0] == i) {
                        fToAdd.push(this.state.allFarmsList[j][0])
                    }
                }
            }
        }
        var requestBody = {
            context: 'addFarmToRoute',
            FarmIds: fToAdd,
            RouteCode: this.state.routeCode
        }
        var response = await setDataAPI(requestBody)
        this.getData()
        this.setState({ result: response[0] })
    }

    async submit() {
        console.log("routeNote: ", this.state.routeNote)
        var requestBody = {
            context: 'submitRouteInfo',
            RouteCode: this.state.routeCode,
            UserAssigned: this.state.userAssigned,
            Priority: this.state.hasPriority,
            RouteFarms: this.state.farmList,
            IsActive: this.state.isActive,
            Note: this.state.routeNotesTextArea == null ? '' : this.state.routeNotesTextArea.replace(/'/g, "''")
        }
        var response = await setDataAPI(requestBody)
        this.getData()

        this.setState({ result: response[0] })
    }

    async submitProducts(farm) {
        var products = this.state.productDropdown
        var amounts = this.state.productAmount
        var mainProd = this.state.mainProductDropdown
        var f = farm

        var productInfo = {
            TANK1: { MainProduct: mainProd[0], Products: products[0], Amounts: amounts[0] },
            TANK2: { MainProduct: mainProd[1], Products: products[1], Amounts: amounts[1] },
            TANK3: { MainProduct: mainProd[2], Products: products[2], Amounts: amounts[2] },
            TANK4: { MainProduct: mainProd[3], Products: products[3], Amounts: amounts[3] },
            TANK5: { MainProduct: mainProd[4], Products: products[4], Amounts: amounts[4] },
            TANK6: { MainProduct: mainProd[5], Products: products[5], Amounts: amounts[5] }
        }
        console.log(productInfo)
        console.log(JSON.stringify(productInfo))
        var requestBody = {
            context: 'updateFarmProducts',
            FarmId: farm[0],
            ProductInfo: JSON.stringify(productInfo)
        }
        var response = await setDataAPI(requestBody)
        this.getData()
        this.setState({ result: response[0] })
        this.handleProductDismiss()
    }
    getIcon(index) {
        if (this.state.farmSelected[index]) {
            return "check-circle"
        }
        return "checkbox-blank-circle-outline"
    }
    getIconAddFarm(index) {
        if (this.state.farmToAddSelected[index]) {
            return "check-circle"
        }
        return "checkbox-blank-circle-outline"
    }
    setChecked(index) {
        var checkedList = this.state.farmSelected
        checkedList[index] = !checkedList[index]
        this.setState({
            farmSelected: checkedList,
            selectedCount: checkedList[index] ? this.state.selectedCount + 1 : this.state.selectedCount - 1
        })
    }
    setFarmToAddChecked(index) {
        var checkedList = this.state.farmToAddSelected
        checkedList[index] = !checkedList[index]
        this.setState({
            farmToAddSelected: checkedList
        })
    }
    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.allFarmsList.filter(function (item) {
            return item[1].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredFarmsList: filteredData });
    };
    renderModal() {
        return (
            <View style={styles.modalView}>
                <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <List.Section style={{ flex: 4, flexDirection: 'column', backgroundColor: 'rgb(233,233,233)' }}>
                        <SafeAreaView>
                            <ScrollView style={{ height: 320 }}>
                                {this.state.filteredFarmsList.map((item, index) => {
                                    return (
                                        <List.Item style={{ borderWidth: 1, borderColor: 'rgb(211,211,211)' }}
                                            onPress={() => { this.setFarmToAddChecked(item[0]) }}
                                            title={item[1]} description={item[2] + " | Current Route: " + item[5]}
                                            key={index} left={() =>
                                                <IconButton onPress={() => { this.setFarmToAddChecked(item[0]) }} compact="false" mode="contained"
                                                    icon={this.getIconAddFarm(item[0])}
                                                />}
                                        />)
                                }
                                )}
                            </ScrollView>
                        </SafeAreaView>
                    </List.Section>
                </KeyboardAwareScrollView>
                <Button color={'#7fbe41'} mode='contained' onPress={() => { this.submitFarmsToRoute() }} icon="plus" > Add Selected </Button>
            </View >
        )
    }
    renderConfirmModal() {
        return (
            <View style={[styles.modalView, { flexDirection: 'column', justifyContent: 'center', margin: 90 }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>Are you sure?</Title>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10 }}>
                    <Button color={'#be4141'} mode='contained' onPress={() => { this.removeFarmFromRoute() }}> Yes </Button>
                    <Button color={'#f0f0f0'} mode='contained' onPress={this.hideConfirmRemove} > Cancel </Button>
                </View >
            </View>
        )
    }
    async getScreenHeight() {
        let screenHeight = Math.round(Dimensions.get('window').height);
        let screenWidth = Math.round(Dimensions.get('window').width);
        console.log('screenHeight: ', screenHeight)
        this.setState({
            screenHeight: screenHeight,
            screenWidth: screenWidth
        })
    }

    renderProductModal() {
        if (this.state.farmPressed.length === 0) {
            return
        }
        return (
            <SafeAreaView style={[styles.modalView, { marginTop: '5%', marginBottom: '5%', alignSelf: 'center', width: '100%', height: this.state.screenHeight - 80 }]}>
                <ScrollView>
                    <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>Enter Products and Amounts</Title>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Subheading style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>{this.state.farmPressed[1]}</Subheading>
                            <Paragraph style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>{"(" + this.state.farmPressed[2] + ")"}</Paragraph>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <TextInput
                                style={[{ height: 130, width: '90%', backgroundColor: 'rgb(239, 239, 239)', borderRadius: 1, borderColor: 'black', marginRight: '3%' }]}
                                multiline
                                value={this.state.mineralNotesTextArea}
                                onChangeText={mineralNotesTextArea => this.setState({ mineralNotesTextArea })
                                }
                            />
                        </View>
                    </View>
                    {this.getProductEntry(0)}
                    <View
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 4,
                            marginBottom: 10
                        }}
                    />
                    {this.getProductEntry(1)}
                    <View
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 4,
                            marginBottom: 10
                        }}
                    />
                    {this.getProductEntry(2)}
                    <View
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 4,
                            marginBottom: 10
                        }}
                    />
                    {this.getProductEntry(3)}
                    <View
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 4,
                            marginBottom: 10
                        }}
                    />
                    {this.getProductEntry(4)}
                    <View
                        style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 4,
                            marginBottom: 10
                        }}
                    />
                    {this.getProductEntry(5)}

                </ScrollView>
                <Button style={{ margin: 10, backgroundColor: '#345DAE' }} mode="contained" onPress={() => { this.submitProducts(this.state.farmPressed) }}>Update Products</Button>
                <Button style={{ margin: 10, backgroundColor: '#FF0000' }} mode="contained" onPress={() => { this.hideProductModal() }}>Exit</Button>
            </SafeAreaView>
        )
    }
    handleMainProductChange(index, value) {
        var arr = this.state.mainProductDropdown
        arr[index] = value
        this.setState({
            mainProductDropdown: arr
        })
    }
    handleProductChange(i, j, value) {
        var arr = this.state.productDropdown
        arr[i][j] = value
        this.setState({
            productDropdown: arr
        })
    }
    handleAmountChange(i, j, value) {
        var arr = this.state.productAmount
        arr[i][j] = value
        this.setState({
            productAmount: arr
        })
    }
    showVolume(index) {
        for (let i = 0; i < this.state.tankCapacities.length; i++) {
            var tank = this.state.tankCapacities[i]
            if (tank[index] != undefined) {
                if (this.state.farmPressed[4] == tank[index][2]) { //compare device ids
                    if (tank[index][4] == null) {
                        var fillVar = 0
                        var fillText = 'N/A'
                    }
                    else {
                        var fillVar = tank[index][4]
                        var fillText = tank[index][4]
                    }
                    return (
                        <View style={{ marginLeft: '3%', marginTop: '1%', flexDirection: "row", height: 50 }}>
                            <View style={{ flexDirection: "column", alignContent: "center", justifyContent: "center" }}>
                                <AnimatedCircularProgress
                                    margin={3}
                                    padding={5}
                                    size={50}
                                    width={7}
                                    fill={fillVar}
                                    tintColor="#00e0ff"
                                    backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                {fillText}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                                <Text style={{ alignSelf: 'center' }}>Capacity: {tank[index][0]}kg</Text>
                                <Text style={{ alignSelf: 'center' }}>Space left: {(tank[index][0] / 100) * (100 - fillVar)}kg</Text>
                            </View>
                        </View>
                    )
                }

            }
        }
        {
            return (
                <View style={{ marginLeft: '3%', flexDirection: "row", height: 50 }}>
                    <AnimatedCircularProgress
                        margin={10}
                        padding={5}
                        size={50}
                        width={7}
                        fill={0}
                        tintColor="#00e0ff"
                        backgroundColor="#3d5875">
                        {
                            (fill) => (
                                <Text>
                                    { 'N/A'}
                                </Text>
                            )
                        }
                    </AnimatedCircularProgress>
                    <Text style={{ alignSelf: 'center' }}>Capacity: N/A</Text>
                </View>
            )
        }
    }
    updateRowCount(index, jindex, isIncreased) {
        var x = this.state.tanksRowsCount

        x[index] = isIncreased ? x[index] + 1 : x[index] - 1
        if (x[index] > 5) {
            x[index] = 5 //max 5 rows per tank cos complicated reasons
        }
        this.setState({
            tanksRowsCount: x
        })

        if (jindex >= 0) {
            var y = this.state.productDropdown
            y[index][jindex] = 'No Product'

            var z = this.state.productAmount
            z[index][jindex] = null

            this.setState({
                productDropdown: y,
                productAmount: z
            })
        }
        console.log(index, isIncreased, this.state.tanksRowsCount)
    }
    getTankRow(i, j, removeRow) {
        return (
            <View style={{ flexDirection: 'row', marginBottom: '2%', marginTop: '2%', marginRight: '2%', width: '98%', alignSelf: 'center', justifyContent: 'center' }}>
                <Picker
                    selectedValue={this.state.productDropdown[i][j]}
                    onValueChange={product => this.handleProductChange(i, j, product)}
                    style={{ alignSelf: 'center', padding: '1%', marginRight: '3%', marginLeft: '3%' }}
                    mode="dropdown">
                    {this.state.products.map((item, index) => {
                        return (<Picker.Item label={item[1]} value={item[1]} key={index + '-' + i + '-' + j} />)
                    })}
                </Picker>
                <TextInput
                    style={{ backgroundColor: '#fff', padding: '1%', alignSelf: 'center', marginRight: '2%', borderWidth: 1, borderColor: '#345DAE' }}
                    placeholder="Amount (Kg)"
                    placeholderTextColor="#60605e"
                    keyboardType={'numeric'}
                    value={this.state.productAmount[i][j]}
                    onChangeText={productAmount => this.handleAmountChange(i, j, productAmount)}
                />
                <IconButton style={{ marginLeft: '3%' }} onPress={() => { removeRow ? this.updateRowCount(i, j, false) : {} }} compact="true" mode="contained" icon={removeRow ? 'minus' : ''} />
            </View>
        )
    }
    getTankRows(i) {
        switch (this.state.tanksRowsCount[i]) {
            case 1:
                return (
                    <View>
                        {this.getTankRow(i, 0, false)}
                    </View>
                )
            case 2:
                return (
                    <View>
                        {this.getTankRow(i, 0, false)}
                        {this.getTankRow(i, 1, true)}
                    </View>
                )
            case 3:
                return (
                    <View>
                        {this.getTankRow(i, 0, false)}
                        {this.getTankRow(i, 1, false)}
                        {this.getTankRow(i, 2, true)}
                    </View>
                )
            case 4:
                return (
                    <View>
                        {this.getTankRow(i, 0, false)}
                        {this.getTankRow(i, 1, false)}
                        {this.getTankRow(i, 2, false)}
                        {this.getTankRow(i, 3, true)}
                    </View>
                )
            case 5:
                return (
                    <View>
                        {this.getTankRow(i, 0, false)}
                        {this.getTankRow(i, 1, false)}
                        {this.getTankRow(i, 2, false)}
                        {this.getTankRow(i, 3, false)}
                        {this.getTankRow(i, 4, true)}
                    </View>
                )
            default:
                break;
        }
    }
    getDateText(i) {
        if (this.state.farmPressed_dates[i] != undefined) {
            return this.state.farmPressed_dates[i][2] == i + 1 ? 'Empty Date: ' + this.state.farmPressed_dates[i][0] : ''
        }
        return ''
    }
    getProductEntry(index) {
        return (
            <View style={{ flex: 1, width: '100%' }}>
                <Text style={[styles.title, { marginTop: '1%' }]}>TANK {index + 1}</Text>
                <Text style={[styles.title, { marginTop: '1%', marginBottom: '2%' }]}>{
                    this.getDateText(index)
                }</Text>

                <View style={{ flexDirection: 'row', flex: 1, marginBottom: '1%' }}>
                    <View style={{ flexDirection: 'column', width: '100%', marginBottom: '3%', alignSelf: 'center', justifyContent: 'center' }}>
                        <View style={{ flexDirection: 'row', flex: 1, marginBottom: '3%', alignSelf: 'center', justifyContent: 'center' }}>
                            {
                                this.showVolume(index)
                            }
                            <Picker
                                selectedValue={this.state.mainProductDropdown[index]}
                                onValueChange={product => this.handleMainProductChange(index, product)}
                                style={{ marginRight: '4%', marginLeft: '4%', width: '20%' }}
                                mode="dropdown">
                                <Picker.Item label={"None"} value={"None"} key={'prodType' + 'None'} />
                                <Picker.Item label={"MAG"} value={"MAG"} key={'prodType' + 'MAG'} />
                                <Picker.Item label={"MIN"} value={"MIN"} key={'prodType' + 'MIN'} />
                                <Picker.Item label={"PHOS"} value={"PHOS"} key={'prodType' + 'PHOS'} />
                                <Picker.Item label={"DRY COW"} value={"DRY COW"} key={'prodType' + 'DRYCOW'} />
                                <Picker.Item label={"BLOAT"} value={"BLOAT"} key={'prodType' + 'BLOAT'} />
                            </Picker>
                            <Button style={{ width: '30%' }} color={'#7fbe41'} mode='outlined' onPress={() => { this.updateRowCount(index, -1, true) }} icon="plus" > Add Product </Button>
                        </View>
                        {
                            this.getTankRows(index)
                        }
                    </View>
                </View>

            </View>
        )
    }

    async getMineralNotes(f) {
        console.log("farmlist", f)

        var requestBody = {
            context: 'farmDetails',
            Id: f[0]
        }
        var response = await getDataAPI(requestBody)
        if (response) {
            if (response[0]) {
                if (response[0][8] == 'null' || response[0][8] == '' || response[0][8] == null) {
                    this.setState({ mineralNotesTextArea: 'No mineral notes' })
                }
                else {
                    this.setState({ mineralNotesTextArea: response[0][8] })
                }

            }
        }
        else {
            this.setState({ mineralNotesTextArea: 'No mineral notes' })
        }

        console.log("MineralNotes", response[0][8])

    }

    async handleFarmPress(f) {
        this.getMineralNotes(f)
        if (f[6] != null && f[6].length > 0) {
            var products = JSON.parse(f[6])
            console.log(products)
            if (products['TANK1']['Products']) {
                var mnPr = this.state.mainProductDropdown
                mnPr[0] = products['TANK1']['MainProduct']
                mnPr[1] = products['TANK2']['MainProduct']
                mnPr[2] = products['TANK3']['MainProduct']
                mnPr[3] = products['TANK4']['MainProduct']
                mnPr[4] = products['TANK5']['MainProduct']
                mnPr[5] = products['TANK6']['MainProduct']

                var prDr = this.state.productDropdown
                prDr[0] = products['TANK1'].hasOwnProperty('Products') ? products['TANK1']['Products'] : this.state.productDropdownDefaults
                prDr[1] = products['TANK2'].hasOwnProperty('Products') ? products['TANK2']['Products'] : this.state.productDropdownDefaults
                prDr[2] = products['TANK3'].hasOwnProperty('Products') ? products['TANK3']['Products'] : this.state.productDropdownDefaults
                prDr[3] = products['TANK4'].hasOwnProperty('Products') ? products['TANK4']['Products'] : this.state.productDropdownDefaults
                prDr[4] = products['TANK5'].hasOwnProperty('Products') ? products['TANK5']['Products'] : this.state.productDropdownDefaults
                prDr[5] = products['TANK6'].hasOwnProperty('Products') ? products['TANK6']['Products'] : this.state.productDropdownDefaults

                var prAm = this.state.productAmount
                prAm[0] = products['TANK1'].hasOwnProperty('Amounts') ? products['TANK1']['Amounts'] : []
                prAm[1] = products['TANK2'].hasOwnProperty('Amounts') ? products['TANK2']['Amounts'] : []
                prAm[2] = products['TANK3'].hasOwnProperty('Amounts') ? products['TANK3']['Amounts'] : []
                prAm[3] = products['TANK4'].hasOwnProperty('Amounts') ? products['TANK4']['Amounts'] : []
                prAm[4] = products['TANK5'].hasOwnProperty('Amounts') ? products['TANK5']['Amounts'] : []
                prAm[5] = products['TANK6'].hasOwnProperty('Amounts') ? products['TANK6']['Amounts'] : []

                this.setState({
                    mainProductDropdown: mnPr,
                    productDropdown: prDr,
                    productAmount: prAm
                })
            }
        }
        this.getEmptyDateForFarmPressed(f)
        this.setState({
            farmPressed: f
        })
        this.showProductModal()
    }
    handleProductDismiss() {
        this.hideProductModal()
        this.setState({
            farmPressed: [],
            farmPressed_dates: [['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1], ['unknown', '', -1]],
            mainProductDropdown: [],
            productAmount: [[], [], [], [], [], []],
            productDropdown: this.state.productDropdownDefaults
        })
    }
    sortByPriority(farmsToSort) {
        return farmsToSort.sort(function (a, b) {
            return a[3] - b[3]
        });
    }
    setFarmEnabled() {
        var farms = this.state.farmList
        var farmsSelected = this.state.farmSelected
        var count = this.state.selectedCount
        for (let i = 0; i < farmsSelected.length; i++) {
            if (farmsSelected[i]) {
                for (let j = 0; j < farms.length; j++) {
                    if (farms[j][0] == i) {
                        farms[j][5] = !farms[j][5]
                        farmsSelected[i] = false
                        count = count - 1
                    }
                }
            }
        }
        this.setState({ farmList: farms, farmSelected: farmsSelected, selectedCount: count })
    }
    moveSelected(direction) {
        if (this.state.selectedCount != 1) {
            return
        }
        var farms = this.state.farmList
        var farmsSelected = this.state.farmSelected
        for (let i = 0; i < farmsSelected.length; i++) {
            if (farmsSelected[i]) {
                for (let j = 0; j < farms.length; j++) {
                    //change priority
                    if (farms[j][0] == i) {
                        switch (direction) {
                            case 'up':
                                if (farms[j][3] != 0) {
                                    farms[j][3] = farms[j][3] - 1
                                    farms[j - 1][3] = farms[j - 1][3] + 1
                                }
                                break;
                            case 'down':
                                if (farms[j][3] != farms.length - 1) {
                                    farms[j][3] = farms[j][3] + 1
                                    farms[j + 1][3] = farms[j + 1][3] - 1
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        farms = this.sortByPriority(farms)
        this.setState({ farmList: farms })
    }
    async removeFarmFromRoute() {
        this.hideConfirmRemove()
        var fToRemove = []
        for (let i = 0; i < this.state.farmSelected.length; i++) {
            if (this.state.farmSelected[i]) {
                for (let j = 0; j < this.state.farmList.length; j++) {
                    if (this.state.farmList[j][0] == i) {
                        fToRemove.push(this.state.farmList[j][0])
                    }
                }
            }
        }
        var requestBody = {
            context: 'addFarmToRoute',
            FarmIds: fToRemove,
            RouteCode: 0
        }
        //also set priority to -1
        var response = await setDataAPI(requestBody)
        this.setState({ result: response[0] })
        this.getData()
    }

    getChevronStyle() {
        if (this.state.selectedCount == 1) {
            return { borderColor: 'black', borderWidth: 1 }
        }
        return { borderColor: 'grey', borderWidth: 1 }
    }
    getBtnStyle() {
        if (this.state.selectedCount >= 1) {
            return { borderColor: 'black', borderWidth: 1 }
        }
        return { borderColor: 'grey', borderWidth: 1 }
    }
    getChevronIconStyle() {
        if (this.state.selectedCount == 1) {
            return "black"
        }
        return "grey"
    }
    getRemoveBtnColor() {
        if (this.state.selectedCount < 1) {
            return "grey"
        }
        return "red"
    }
    getEnableBtnColor() {
        if (this.state.selectedCount < 1) {
            return "grey"
        }
        return "black"
    }

    getEnabledFarmStyle(enabled) {
        if (enabled) {
            return {
                backgroundColor: 'rgba(0,255,0,0.1)'
            }
        }
        return {
            backgroundColor: 'rgba(255,0,0,0.1)'
        }
    }

    getNextEmptyDateText(farm) {
        // if (farm[7] != undefined && farm[7] != "unknown") {
        //     return farm[2] + ' | ' + 'Next empty date ' + farm[7] + ' (' + farm[8] + ')'
        // } else if (farm[7] == "unknown") {
        //     return farm[2] + ' | ' + 'Next empty date ' + farm[7]
        // }
        let ed = this.state.farmEmptyDates
        for (let i = 0; i < ed.length; i++) {
            if (ed[i][0] == farm[4]) {
                if (ed[i][1][0] != '2900-01-01') {
                    return farm[2] + ' | ' + 'Next empty date ' + ed[i][1][0] + ' (' + ed[i][1][1] + ')'
                } else {
                    return 'No Date Available'
                }
            }

        }
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View >
            );
        }
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                        <Text style={[styles.title, { marginTop: 15 }]}>Configure Route {this.state.routeCode}</Text>
                        <View style={{ flex: 1, flexDirection: 'column', marginTop: 10, marginBottom: 10, justifyContent: 'center', alignContent: 'center' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <Text style={[styles.subTitle, { marginVertical: 10 }]}>&nbsp;Route Active?:&nbsp; &nbsp;</Text>
                                <Picker
                                    selectedValue={this.state.isActive}
                                    onValueChange={isActive => this.setState({ isActive })}
                                    style={{ width: 160, alignSelf: 'center', flex: 1, padding: 10, marginRight: 20, marginLeft: 50 }}
                                    mode="dropdown">
                                    <Picker.Item label={"No"} value={false} key={1} />
                                    <Picker.Item label={"Yes"} value={true} key={0} />
                                </Picker>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <Text style={[styles.subTitle, { marginVertical: 10 }]}>&nbsp;Priority:&nbsp; &nbsp;</Text>
                                <Picker
                                    selectedValue={this.state.hasPriority}
                                    onValueChange={hasPriority => this.setState({ hasPriority })}
                                    style={{ width: 160, alignSelf: 'center', flex: 1, padding: 10, marginRight: 20, marginLeft: 50 }}
                                    mode="dropdown">
                                    <Picker.Item label="1 (highest)" value={0} key={0} />
                                    <Picker.Item label="2 (high)" value={1} key={1} />
                                    <Picker.Item label="3 (average)" value={2} key={2} />
                                    <Picker.Item label="4 (low)" value={3} key={3} />
                                    <Picker.Item label="5 (lowest)" value={4} key={4} />
                                </Picker>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <Text style={[styles.subTitle, { marginVertical: 10 }]}>&nbsp;Driver Assigned:&nbsp; &nbsp;</Text>
                                <Picker
                                    selectedValue={this.state.userAssigned}
                                    onValueChange={userAssigned => this.setState({ userAssigned })}
                                    style={{ width: 160, alignSelf: 'center', flex: 1, padding: 10, marginRight: 20, marginLeft: 50 }}
                                    mode="dropdown">
                                    {this.state.userList.map((item, index) => {
                                        return (<Picker.Item label={item[1] + " " + item[2]} value={item[3]} key={index} />)
                                    })}
                                </Picker>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                                <TextInput
                                    style={[styles.inputContainerStyle, { width: '50%', borderColor: 'black', borderWidth: 1, borderRadius: 5, height: this.state.routeNotesFocused ? 150 : 100 }]}
                                    label="Route notes"
                                    multiline
                                    placeholder=" Route notes..."
                                    onFocus={() => { this.setState({ routeNotesFocused: true }) }}
                                    onBlur={() => { this.setState({ routeNotesFocused: false }) }}
                                    theme={{
                                        colors: {
                                            placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                            underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                                        }
                                    }}
                                    value={this.state.routeNotesTextArea}
                                    onChangeText={routeNotesTextArea => this.setState({ routeNotesTextArea })
                                    }
                                />
                        </View>
                        </View>

                        
                        <View style={{ borderRadius: 20, flex: 1, flexDirection: 'column', backgroundColor: 'white', borderWidth: 2, borderColor: 'grey', marginTop: 20 }}>
                            <View style={{ flex: 1, flexDirection: 'row', marginTop: 10}}>
                                <View style={{ flex: 3, flexDirection: 'column' }}>
                                    <Subheading style={{ flex: 7, marginLeft: 20, fontWeight: 'bold' }}>Sort Farms</Subheading>
                                </View>
                                <View style={{ flex: 2, flexDirection: 'column' }}>
                                    <Portal>
                                        <Modal visible={this.state.visible} onDismiss={this.hideModal}>
                                            {this.renderModal()}
                                        </Modal>
                                    </Portal>
                                    <Button style={{ marginRight: 20 }} color={'#7fbe41'} mode='outlined' onPress={this.showModal} icon="plus" > Add Farm </Button>
                                </View>
                            </View>
                            <View style={{flex: 4, flexDirection: 'row', marginTop: 10 }}>
                            <View style={{ flex: 3, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 20 }}>
                                    <SafeAreaView style={{flex: 1, borderWidth: 2, borderColor: '#999999' }}>
                                        <ScrollView style={{ flex: 1}}>
                                            {this.state.farmList.map((item, index) => {
                                                return (
                                                    <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(126,126,126)' }, this.getEnabledFarmStyle(item[5])]}
                                                        onPress={() => { this.handleFarmPress(item) }}
                                                        title={item[1]}
                                                        description={this.getNextEmptyDateText(item) + ' | ' + this.getIssueText(item[0])}
                                                        key={index}
                                                        left={() =>
                                                            <IconButton onPress={() => { this.setChecked(item[0]) }}
                                                                compact="false" mode="contained" icon={this.getIcon(item[0])}
                                                            />}
                                                    />)
                                            })}
                                            <Portal>
                                                <Modal visible={this.state.productVisible} onDismiss={() => { this.handleProductDismiss() }}>
                                                    {this.renderProductModal()}
                                                </Modal>
                                            </Portal>
                                        </ScrollView>
                                    </SafeAreaView>
                                </View>
                                <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <IconButton style={this.getChevronStyle()} onPress={() => { this.moveSelected('up') }} icon={"chevron-up"} color={this.getChevronIconStyle()} />
                                    <IconButton style={this.getChevronStyle()} onPress={() => { this.moveSelected('down') }} icon={"chevron-down"} color={this.getChevronIconStyle()} />
                                    <IconButton style={this.getBtnStyle()} onPress={() => { this.setFarmEnabled() }} icon={"close-box"} color={this.getEnableBtnColor()} />
                                    <IconButton style={this.getBtnStyle()} onPress={() => { if (this.state.selectedCount > 0) { this.showConfirmRemove() } }} icon={"delete"} color={this.getRemoveBtnColor()} />
                                    <Portal>
                                        <Modal visible={this.state.removeVisible} onDismiss={this.hideConfirmRemove}>
                                            {this.renderConfirmModal()}
                                        </Modal>
                                    </Portal>
                                </View>
                            </View>
                            <TouchableOpacity style={[styles.button, { marginBottom: 10 }]} onPress={() => { this.submit() }}>
                                <Text style={styles.buttonTitle}>Submit Changes</Text>
                            </TouchableOpacity>
                        </View>

                        
                </KeyboardAwareScrollView>
            </View>
        )
    }
}