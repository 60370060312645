import '../../globals.js'

import { ActivityIndicator, Dimensions, FlatList, Image, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';

import React, {useState} from 'react';
import { Searchbar } from 'react-native-paper'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';


export default class SearchFarms extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, searchText: '', screenHeight: 600, userId: props.route.params.user.id, user:  props.route.params.user  };
  }


  async getData() {
    const distributor= this.props.route.params.user.userDistrib
    // console.log( 'DistirbutorId:',distributor[0])
    var requestBody = {
      context: 'getAllFarms',
      distributorId: distributor[0]
    }
    var data = await getDataAPI(requestBody)
    // console.log(this.formatData(data))
    await this.getScreenHeight()
    this.setState({
      isLoading: false,
      data: this.formatData(data),
      filteredData: this.formatData(data)
    })
  }
  componentDidMount() {
    this.focusSubscription = this.props.navigation.addListener('focus', () => {
      this.setState({ isLoading: true, searchText: '', filteredData: [], screenHeight: 600 })
      this.getData();
    })
  }


  //   async loadUserDistributorsList() {
  //     var requestBody = {
  //         context: 'getUserDistrubutors',
  //         UserId: this.state.userId
  //     }
  //     var data = await getDataAPI(requestBody)
  //     var currentUserDistrib = this.props.route.params.user.userDistrib
  //
  //     this.setState({ userDistribs: data  })
  //     console.log('Data',data)
  //
  //     const distributor = data[0];
  //     // setPartner(distributor[0][1])
  //     this.props.route.params.user.userDistrib = distributor
  //
  //     this.getData()
  //     // if (Platform.OS == 'ios' || Platform.OS == 'android') {
  //     //     await this.checkForUpdate()
  //     // }
  // }

  async getScreenHeight() {
    let screenHeight = Math.round(Dimensions.get('window').height);
    let screenWidth = Math.round(Dimensions.get('window').width);
    // console.log('screenHeight: ', screenHeight)
    this.setState({
        screenHeight: screenHeight,
        screenWidth: screenWidth
    })
}

  calcStyle(row) {
    var r = 127, g = 190, b = 65
    // if (row[4] == true) {
    //   r = 255
    //   g = 30
    //   b = 30
    // }
    var rgb = 'rgb(' + r + ',' + g + ',' + b + ')'
    var style = {
      backgroundColor: rgb,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 20,
      height: 48,
      borderColor: 'black',
      borderWidth: 1,
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center'
    }
    return style
  }
  formatData(ds) {
    ds = ds.sort((a, b) => a[3].toString().toLowerCase().localeCompare(b[3].toString().toLowerCase()))
    ds.forEach((i) => {
      ds.forEach((j) => {
        if (i[1] === j[1] && i[0] != j[0]) {
          i[1] = i[1] + " (" + i[2] + ")"
          j[1] = j[1] + " (" + j[2] + ")"
          if (i[2] == j[2]) {
            i[1] = i[1] + " 1"
            j[1] = j[1] + " 2"
          }
        }
      })
    })
    return ds
  }

  search = (searchText) => {
    this.setState({ searchText: searchText });

    let filteredData = this.state.data.filter(function (item) {
      return item[1].toLowerCase().includes(searchText.toLowerCase());
    });

    this.setState({ filteredData: filteredData });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1 }}>
          <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
          <ActivityIndicator style={{marginTop: '20%'}} />
        </View>
      );
    }
    else {
      return (
        <View style={{ flex: 1}}>
          <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
          <SafeAreaView >
            <ScrollView style={{ height: this.state.screenHeight - 120 }}>
              <FlatList
                data={this.state.filteredData}
                renderItem={({ item }) => (
                  <TouchableOpacity style={this.calcStyle(item)}
                    onPress={() => this.props.navigation.navigate('Farm Details', {
                            DeviceId: item[4], user: this.state.user, Id: item[0]
                    })}>
                    <Text style={styles.buttonTitle}>{item[1]}</Text>
                  </TouchableOpacity>
                )}
                keyExtractor={(item, index) => { return item[0].toString() }}
              />
            </ScrollView>
          </SafeAreaView>
        </View>
      );
    }
    return (
      <View style={{ flex: 1}}>
        <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
      </View>
    );

  }
}
