import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native'

import { DataTable } from 'react-native-paper'
import React from 'react'
import { TextInput } from 'react-native-paper';
import { getCurrentTimeStamp } from '../_shared/Helpers'
import { setDataAPI } from '../_shared/ERP_API'
import { showMessage } from 'react-native-flash-message'
import styles from '../../styles';

export default class FarmIssues extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, issueData: this.props.extraData, addingIssue: false, issueSelected: -1 };
    }

    componentDidMount() {
        this.setState({
            issueSelected: -1,
            isLoading: false,
            resolvedText: '',
            issueData: this.props.extraData,
            addIssueText: ''
        })
    }

    async submitNewIssue() {
        this.setState({ addingIssue: false })

        var requestBody = {
            context: 'farmIssues',
            FarmId: this.props.route.params.Id,
            CreatedBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName),
            IssueText: this.state.addIssueText
        }
        var data = await setDataAPI(requestBody)
        this.setState(
            {
                msg: data
            })
        if (this.state.msg == 'success') {
            showMessage({
                message: "Issue submitted",
                type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
            })
            var newData = await this.props.onChange(this.props.route.params.Id, this.props.route.params.user.userAccess)
            this.setState({
                issueData: newData,
                addIssueText: '',
                resolvedText: '',
            })
        }
    }
    async submitIssueResolution() {
        var dateNow = getCurrentTimeStamp()
        var requestBody = {
            context: 'farmIssueResolved',
            Id: this.state.issueSelected,
            ResolvedText: this.state.resolvedText,
            ResolvedDate: dateNow,
            ResolvedBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName)
        }
        var data = await setDataAPI(requestBody)
        this.setState(
            {
                msg: data,
                issueSelected: -1,
                resolvedText: '',
                addIssueText: ''
            })
        if (this.state.msg == 'success') {
            showMessage({
                message: "Issue marked as resolved",
                type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
            })
            var newData = await this.props.onChange(this.props.route.params.Id, this.props.route.params.user.userAccess)
            this.setState({
                issueData: newData
            })
        }
    }

    getButtonStyle() {
        if (this.state.resolvedText == null || this.state.resolvedText == '') {
            return styles.greyButton_reduceMargin
        }
        else { return styles.activeButton_reduceMargin }
    }

    getExpandedRow(row) {
        if (this.state.issueSelected == row[0]) {
            return (
                <View style={{ flex: 1, flexDirection: 'column' }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Text style={[styles.description,{marginBottom:0}]}>{row[1]}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <Text style={[styles.description,{marginBottom:0}]}>Created By: {row[2]}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <Text style={styles.description}>Created Date: {row[3].substring(0, row[3].indexOf(':') - 2)}</Text>
                    </View>
                    { (this.props.route.params.user.userAccess == 'Admin' && row[4]) ?
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <Text style={[styles.description,{marginBottom:0}]}>Resolved by: {row[5]}</Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <Text style={[styles.description]}>Resolved explanation: {row[6]}</Text>
                        </View>
                    </View>

                        :

                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <TextInput 
                                style={[styles.inputContainerStyle, styles.textArea, styles.marginBottom, { flex: 3 }]}
                                label="*Resolved Reason"
                                multiline
                                placeholder="new box fitted etc."
                                theme={{
                                    colors: {
                                        placeholder: 'rgb(86,86,86)', text: 'rgb(43,43,43)', primary: 'rgb(255,43,43)',
                                        underlineColor: 'transparent', background: 'rgb(245, 245, 245)'
                                    }
                                }}
                                value={this.state.resolvedText}
                                onChangeText={resolvedText => this.setState({ resolvedText })
                                }
                            />
                            <TouchableOpacity style={[this.getButtonStyle(), { flex: 1, alignSelf: 'stretch' }]}
                                onPress={() => { this.submitIssueResolution() }}>
                                <Text style={styles.buttonTitle}>Resolve</Text>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
            )
        }
    }
    getRowCellFrame(row){
        if(this.props.route.params.user.userAccess == 'Admin' && row[4]){
            return({
                borderWidth: 2,
                borderRadius: 5,
                borderColor: 'green',
                backgroundColor: 'white',
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10
            })
        }
        return ({
                borderWidth: 2,
                borderRadius: 5,
                borderColor: 'red',
                backgroundColor: 'white',
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 10
        })
    }
    isHighlighted(row) {
        if (row == this.state.issueSelected) {
            return {
                backgroundColor: 'white',
                borderRadius: 5,
                borderColor: 'black'
            }
        }
        return {

        }
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        return (
            <View style={styles.viewContainer}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 20 }}>
                    <Text style={[{ color: 'red', margin: 5, marginBottom: 15, marginLeft: 15, flex: 7 }, styles.title]}>Farm Issues</Text>
                    <TouchableOpacity style={[styles.button, { flex: 3, height: 32, borderRadius: 16, marginBottom: 16 }]}
                        onPress={() => { this.setState({ addingIssue: !this.state.addingIssue }) }}>
                        <Text style={styles.buttonTitle}>{this.state.addingIssue ? 'Cancel' : 'Add'}</Text>
                    </TouchableOpacity>
                </View>
                {
                    this.state.addingIssue &&
                    <View style={{ flex: 1}}>
                        <TextInput
                            style={[styles.inputContainerStyle, styles.textArea, { marginBottom: 0 }]}
                            label="New Issue"
                            multiline
                            placeholder="Device herd size showing 0.."
                            theme={{
                                colors: {
                                    placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                    underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                                }
                            }}
                            value={this.state.addIssueText}
                            onChangeText={addIssueText => this.setState({ addIssueText })
                            }
                        />
                        <TouchableOpacity style={[styles.button, { flex: 3, height: 32, borderRadius: 16, marginBottom: 16 }]}
                            onPress={() => { this.submitNewIssue() }}>
                            <Text style={styles.buttonTitle}>Submit</Text>
                        </TouchableOpacity>
                    </View>
                }
                <View style={{flex: 1}}>
                <DataTable style={{width: '100%'}}>
                    {
                        this.state.issueData.map(issue => {
                            return (
                                <View style={this.getRowCellFrame(issue)} key={issue[0]}>
                                    <DataTable.Row key={issue[0]} style={this.isHighlighted(issue[0])}
                                        onPress={() => {
                                            this.setState({
                                                issueSelected: (issue[0] != this.state.issueSelected ? issue[0] : -1),
                                                resolvedText: ''
                                            })
                                        }}>
                                        <DataTable.Cell>
                                            {issue[1]}
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                    {
                                        this.getExpandedRow(issue)
                                    }
                                </View>
                            )
                        })
                    }
                </DataTable>
                </View>
            </View>
        )
    }
}