import { ActivityIndicator, Dimensions, FlatList, SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native'
import { Button, IconButton, List, Searchbar, Switch, Text, Title } from 'react-native-paper'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class RouteManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, searchText: '', allRoutes: [], allFarms: [], filteredData: [], userList: [], showInactiveRoutesOn: false };
    }
    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true, filteredData: [], allRoutes: [], allFarms: [], userList: [], showInactiveRoutesOn: false })
            this.getData()
            this.getFarmData()
        })
    }

    onToggleSwitch = () => this.setState({ showInactiveRoutesOn: !this.state.showInactiveRoutesOn });

    search = (searchText) => {
        this.setState({ searchText: searchText });
        let filteredData = []
        if (isNaN(searchText)) {
            filteredData = this.state.allFarms.filter(function (item) {
                if (item[1].toLowerCase().toString().includes(searchText.toLowerCase())) {
                    return item
                }
            });
        }
        else {
            filteredData = this.state.allRoutes.filter(function (item) {
                return item[0].toString().includes(searchText);
            });
            console.log("filtered number data", filteredData)

        }
        this.setState({ filteredData: filteredData });
    };

    async getData() {
        var requestBody = {
            context: 'getAllRouteCodes'
        }
        var allRoutesResponse = await getDataAPI(requestBody)
        this.setState(
            {
                allRoutes: allRoutesResponse['RouteCodes'],
                userList: allRoutesResponse['UserList']
            })
    }

    async getFarmData() {
        var requestBody = {
            context: 'getAllFarms'
        }
        var allFarmsResponse = await getDataAPI(requestBody)
        this.setState(
            {
                isLoading: false,
                allFarms: allFarmsResponse
            })
    }
    getEnabledRouteStyle(enabled) {
        if (enabled) {
            return {
                backgroundColor: 'rgba(0,255,0,0.1)'
            }
        }
        return {
            backgroundColor: 'rgba(255,0,0,0.1)'
        }
    }
    getRenderItem(item, index, filterType) {
        console.log(item)
        if (filterType == 0) {
            return (
                <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(200,200,200)' }]}
                    onPress={() => this.props.navigation.navigate('Assign Routes', {
                        routeCode: item[0],
                        user: this.props.route.params.user
                    })}
                    title={"Route " + item[0]}
                    key={index}
                />
            )
        }
        else if (filterType == 1) {
            return (
                <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(126,126,126)' }]}
                    onPress={() => this.props.navigation.navigate('Assign Routes', {
                        routeCode: item[5],
                        user: this.props.route.params.user
                    })}
                    title={"Route " + item[5]}
                    key={index}
                />)
        }
        return (
            <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(126,126,126)' }]}
                onPress={() => this.props.navigation.navigate('Assign Routes', {
                    routeCode: item[0],
                    user: this.props.route.params.user
                })}
                title={"Route " + item[0]}
                key={index}
            />)
    }
    getItemStyle(active) {
        if (active) {
            return {
                backgroundColor: 'rgba(233,255,233,0.5)'
            }
        }
        return {
            backgroundColor: 'rgba(255,233,233,0.5)'
        }

    }
    arrange(array) {
        var arranged = array.sort((a, b) => a[0].toString().localeCompare(b[0].toString()))
        console.log("length:" + arranged.length)
        return arranged
    }
    getAssignedName(id) {
        for (let i = 0; i < this.state.userList.length; i++) {
            if (id == this.state.userList[i][3]) {
                return this.state.userList[i][1] + ' ' + this.state.userList[i][2]
            }
        }
        return 'UNASSIGNED'
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1}}>
                    <Searchbar placeholder="Search routes..." onChangeText={this.search} value={this.state.searchText} />
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">
                    <Searchbar placeholder="Search routes..." onChangeText={this.search} value={this.state.searchText} />
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', margin: 10 }}>
                        <Text style={{ marginRight: 10 }}>Show Inactive</Text>
                        <Switch value={this.state.showInactiveRoutesOn} onValueChange={this.onToggleSwitch} color='#345dae' />
                    </View>
                    {this.state.searchText == '' &&
                        <SafeAreaView style={{ borderWidth: 1, borderColor: '#999999', height: 550 }}>
                            <ScrollView style={{ height: 550 }}>
                                <List.Section style={{ flex: 4, flexDirection: 'column', justifyContent: 'center', backgroundColor: 'rgb(233,233,233)', marginLeft: 5 }}>
                                    {this.arrange(this.state.allRoutes).map((item, index) => {
                                        if (this.state.showInactiveRoutesOn) {
                                            console.log(item)
                                            return (
                                                <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(200,200,200)', borderRadius: 2 }, this.getItemStyle(item[2])]}
                                                    onPress={() => this.props.navigation.navigate('Assign Routes', {
                                                        routeCode: item[0],
                                                        user: this.props.route.params.user
                                                    })}
                                                    title={"Route " + item[0]}
                                                    description={"Assigned to: " + this.getAssignedName(item[3])}
                                                    key={index}
                                                />)
                                        }
                                        else if (item[2] == true) {
                                            console.log(item)
                                            return (
                                                <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(200,200,200)', borderRadius: 2 }, this.getItemStyle(item[2])]}
                                                    onPress={() => this.props.navigation.navigate('Assign Routes', {
                                                        routeCode: item[0],
                                                        user: this.props.route.params.user
                                                    })}
                                                    title={"Route " + item[0]}
                                                    description={"Assigned to: " + this.getAssignedName(item[3])}
                                                    key={index}
                                                />)

                                        }
                                    })}
                                </List.Section>
                            </ScrollView>
                        </SafeAreaView>
                    }

                    {(this.state.searchText != '' && isNaN(this.state.searchText)) &&
                        <SafeAreaView style={{ borderWidth: 1, borderColor: '#999999' }}>
                            <ScrollView style={{ height: 600 }}>
                                <List.Section style={{ flex: 4, flexDirection: 'column', justifyContent: 'center', backgroundColor: 'rgb(233,233,233)', marginLeft: 5 }}>
                                    {this.arrange(this.state.filteredData).map((item, index) => {
                                        console.log(item)
                                        return (
                                            <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(126,126,126)' }]}
                                                onPress={() => this.props.navigation.navigate('Assign Routes', {
                                                    routeCode: item[5],
                                                    user: this.props.route.params.user
                                                })}
                                                title={"Route " + item[5]}
                                                key={index}
                                            />)
                                    })}
                                </List.Section>
                            </ScrollView>
                        </SafeAreaView>
                    }
                    {(this.state.searchText != '' && !isNaN(this.state.searchText)) &&
                        <SafeAreaView style={{ borderWidth: 1, borderColor: '#999999' }}>
                            <ScrollView style={{ height: 600 }}>
                                <List.Section style={{ flex: 4, flexDirection: 'column', justifyContent: 'center', backgroundColor: 'rgb(233,233,233)', marginLeft: 5 }}>
                                    {this.arrange(this.state.filteredData).map((item, index) => {
                                        console.log(item)
                                        return (
                                            <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(126,126,126)' }]}
                                                onPress={() => this.props.navigation.navigate('Assign Routes', {
                                                    routeCode: item[0],
                                                    user: this.props.route.params.user
                                                })}
                                                title={"Route " + item[0]}
                                                description={"Assigned to: " + this.getAssignedName(item[3])}
                                                key={index}
                                            />)
                                    })}
                                </List.Section>
                            </ScrollView>
                        </SafeAreaView>
                    }

                </KeyboardAwareScrollView>
            </View>
        )

    }
}