import { AsyncStorage } from 'react-native';

export async function getDataLocal(ACCESS_TOKEN) {
    try {
        const value = await AsyncStorage.getItem(ACCESS_TOKEN);
        if (value !== null) {
            console.log(JSON.parse(value));
            return (JSON.parse(value))
        }
    } catch (error) {
        console.log("error is: " + error);
        return ("error")
    }
}
export async function setDataLocal(ACCESS_TOKEN, requestBody) {
    await AsyncStorage.setItem(ACCESS_TOKEN, JSON.stringify(requestBody), (err) => {
        if (err) {
            console.log("an error occured saving local data");
            throw err;
        }
        console.log("success saving local data");
        return ("success")
    }).catch((err) => {
        console.log("error is: " + err);
        return ("error: " + err)
    });
}
export async function removeItemValue(ACCESS_TOKEN) {
    try {
        await AsyncStorage.removeItem(ACCESS_TOKEN);
        return true;
    }
    catch (exception) {
        return false;
    }
}
