import * as Location from 'expo-location';
import * as React from 'react'

import {ActivityIndicator,  Image, Linking, Platform, Text, TouchableOpacity, View} from 'react-native'
import {Modal, Portal, Snackbar, Dialog, RadioButton, Button as PButton} from 'react-native-paper';
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'
import { getDataLocal, removeItemValue } from '../_shared/ERP_Local';

import { Entypo } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { captureScreen } from 'react-native-view-shot';
import { showMessage } from 'react-native-flash-message'
import styles from '../../styles';
import packageJson from '../../../package.json';


const containerStyle = {backgroundColor: 'white', padding: 20 };


export default class AdminManagementHomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPriv: '',
            id: props.route.params.user.id,
            pendingExists: false,
            email: props.route.params.user.email,
            username: props.route.params.user.firstName,
            toastVisible: false,
            screenshot: {},
            distributorName: props.route.params.distributorName,
            distributorId: props.route.params.distributorId,
            distrubutor: {},
            distributorList: props.route.params.distributorList,
            partnerVisible: false,
            checkedDistributor: null
        };
    }

    async checkIfAdmin() {
        var requestBody = {
            context: 'getUserPrivs',
            UserId: this.state.id,
        }
        var data = await getDataAPI(requestBody)
        // console.log('Data ',data)
        this.setState({ userPriv: data[0][0], currVersion: data[0][1] })

        this.props.route.params.user.userAccess = this.state.userPriv
        if (Platform.OS == 'ios' || Platform.OS == 'android') {

        }
    }

    adminButton() {
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin") {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Admin Management', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Admin Portal"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }

    viewIssuesButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('View Issues', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"View Issues"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }


    viewRouteMAnagementButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Route Management', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Route manager"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }
    viewWaterDetailsButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Admin Extra Functions Search', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Water details"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }
    viewDeviceChecklistButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Admin Checklist', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Device checklist"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }

    viewDeliveryButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Admin deliveries', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Deliveries"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }
    viewEmptyDatesButton(){
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('Empty dates', {
                user: this.props.route.params.user, distributorId: this.state.distributorId
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Empty dates"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }

    viewUserDistributorButton(){
        if (this.state.userPriv == "GlobalAdmin" && Platform.OS != 'ios' && Platform.OS != 'android') {
            return <TouchableOpacity style={styles.button_alt} onPress={() => this.props.navigation.navigate('User Management', {
                user: this.props.route.params.user
            })}>
                <View>
                    <Text style={styles.buttonTitle}>{"User Management"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }

    hideModal () {
        this.setState({ visible: false })
    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    modalItems() {
        if (this.state.isLoading) {
            return (
                <View style={[styles.modalView, { margin: '5%', width: '90%', height: 200, alignItems: 'center', justifyContent: 'center' }]}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            )
        }
        return (
            <View style={[styles.modalView, { margin: '5%', width: '90%', height: 200 }]}>
                <Text style={[styles.title, { marginTop: 20, textAlign: 'center' }]}>There is a new update!</Text>
                <Text style={[styles.title, { textAlign: 'center' }]}>Would you like to update now?</Text>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10, height: 48 }}>
                    <TouchableOpacity style={[styles.button_red, { width: '40%', marginVertical: '4%', marginLeft: 20, marginRight: 20 }]}
                        onPress={() => { this.hideModal() }}>
                        <Text style={styles.buttonTitle}>No</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, { width: '40%', marginVertical: '4%', marginLeft: 20, marginRight: 20 }]}
                        onPress={() => {
                            this.retrieveUpdate()
                        }}>
                        <Text style={styles.buttonTitle}>Yes</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    async getLocationAndMap() {
        let { status } = await Location.requestPermissionsAsync();
        if (status !== 'granted') {
            setErrorMsg('Permission to access location was denied');
            alert("Permission for location denied.")
            var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found
            this.setState({ imageURL: url })
        }
        else {
            try {
                var currLocation = await Location.getCurrentPositionAsync({ enableHighAccuracy: true, accuracy: Location.Accuracy.Highest });
                if (currLocation.coords.latitude != null && currLocation.coords.longitude != null || currLocation == undefined) {
                    var latitude = currLocation.coords.latitude
                    var longitude = currLocation.coords.longitude
                    var url = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=400x400&markers=color:blue%7Clabel:Delivery%7C${latitude},${longitude}&key=${global.Apikey}`

                    this.setState({ imageURL: url })
                }
                else {
                    var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                    this.setState({ imageURL: url })
                }
            } catch (error) {
                console.log(error)
                var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                this.setState({ imageURL: url })
            }
        }
    }
    async checkDeliveryNumber() {
        var requestBody = {
            context: 'checkDeliveryNumber'
        }
        var data = await getDataAPI(requestBody)
        return data
    }
    async emailQuantities(reqBody) {
        var emailBody = ``
        for (let i = 0; i < 6; i++) {
            switch (i) {
                case 0:
                    let tank1Det = reqBody['Tank1']
                    if (tank1Det != undefined) {
                        console.log("1", tank1Det)
                        for (let j = 0; j < tank1Det.Batch.length; j++) {
                            if (tank1Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank1Det['tankNo']}<br>
                                                                        Product: ${tank1Det.Batch[j][3]}<br>
                                                                        Batch: ${tank1Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank1Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank1Det.containers[j][0]}<br>
                                                                        ${tank1Det.containers[j][0]} #: ${tank1Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 1:
                    let tank2Det = reqBody['Tank2']
                    if (tank2Det != undefined) {
                        console.log("2", tank2Det)
                        for (let j = 0; j < tank2Det.Batch.length; j++) {
                            if (tank2Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank2Det['tankNo']}<br>
                                                                        Product: ${tank2Det.Batch[j][3]}<br>
                                                                        Batch: ${tank2Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank2Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank2Det.containers[j][0]}<br>
                                                                        ${tank2Det.containers[j][0]} #: ${tank2Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 2:
                    let tank3Det = reqBody['Tank3']
                    if (tank3Det != undefined) {
                        console.log("3", tank3Det)
                        for (let j = 0; j < tank3Det.Batch.length; j++) {
                            if (tank3Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank3Det['tankNo']}<br>
                                                                        Product: ${tank3Det.Batch[j][3]}<br>
                                                                        Batch: ${tank3Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank3Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank3Det.containers[j][0]}<br>
                                                                        ${tank3Det.containers[j][0]} #: ${tank3Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 3:
                    let tank4Det = reqBody['Tank4']
                    if (tank4Det != undefined) {
                        console.log("4", tank4Det.Batch)
                        for (let j = 0; j < tank4Det.Batch.length; j++) {
                            if (tank4Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank4Det['tankNo']}<br>
                                                                        Product: ${tank4Det.Batch[j][3]}<br>
                                                                        Batch: ${tank4Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank4Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank4Det.containers[j][0]}<br>
                                                                        ${tank4Det.containers[j][0]} #: ${tank4Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 4:
                    let tank5Det = reqBody['Tank5']
                    if (tank5Det != undefined) {
                        console.log("5", tank5Det.Batch)
                        for (let j = 0; j < tank5Det.Batch.length; j++) {
                            if (tank5Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank5Det['tankNo']}<br>
                                                                        Product: ${tank5Det.Batch[j][3]}<br>
                                                                        Batch: ${tank5Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank5Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank5Det.containers[j][0]}<br>
                                                                        ${tank5Det.containers[j][0]} #: ${tank5Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 5:
                    let tank6Det = reqBody['Tank6']
                    if (tank6Det != undefined) {
                        console.log("6", tank6Det.Batch)
                        for (let j = 0; j < tank6Det.Batch.length; j++) {
                            if (tank6Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank6Det['tankNo']}<br>
                                                                        Product: ${tank6Det.Batch[j][3]}<br>
                                                                        Batch: ${tank6Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank6Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank6Det.containers[j][0]}<br>
                                                                        ${tank6Det.containers[j][0]} #: ${tank6Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
            }
        }
        console.log(emailBody)
        return emailBody
    }
    async sendEmail(reqBody) {
        var deliveryNumberString = await this.checkDeliveryNumber()

        var formDate = new Date().toLocaleString()

        var deliveryNumber = parseInt(deliveryNumberString)
        deliveryNumber++

        var requestBody = {
            context: 'emailAccounts',
            Body: `<html>
            <head>
                <title></title>
                <link href="https://svc.webspellchecker.net/spellcheck31/lf/scayt3/ckscayt/css/wsc.css" rel="stylesheet" type="text/css" />
            </head>
            <body>Delivery Docket #${deliveryNumber}<br />
            Date: ${formDate}<br />
            <br />
            Customer name: ${this.props.route.params.customer}<br />
            &nbsp;
            
            &nbsp;</div>
            
            ${await this.emailQuantities(reqBody)}
            
            
            <div>&nbsp;</div>
            
    
            <div>Delivered by: ${this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName}</div>
            <div>Delivery location</div>
            <img src="${this.state.imageURL}" width="450" height="300"></img>
            </body>
            </html>
            `,
            Subject: `Delivery #${deliveryNumber}`
        }
        var data = await setDataAPI(requestBody)
        return data[0]
    }
    async checkSubmissionTime(time) {
        var requestBody = {
            context: 'checkSubmissionTime',
            submissionTime: time
        }
        var data = await getDataAPI(requestBody)
        console.log(data)
        return data[0]
    }

    componentDidMount() {
        this.checkIfAdmin();
        console.log(this.props.route.params)
    }

    _saveScreenshotAsync = async () => {
        try {
        await captureScreen({
            format: "png",
            quality: 0.8
          }).then(
            uri => this.setState({ screenshot: uri }),
            error => console.error("Oops, snapshot failed", error)
          )

        } catch (e) {
          console.log("ERROR: ",e)
        }

    }

    render() {
        return (
            <View style={[styles.container, { justifyContent: 'space-between' }]}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <View>
                        <Text style={[styles.title, { paddingTop: 15 }]}>Welcome, {this.state.username}</Text>
                        { this.state.distributorList && this.state.distributorList.length > 1 &&
                            <TouchableOpacity
                            onPress={() => this.openDialog()}
                            style={styles.button_switch}>
                            <View>
                                <Text style={styles.buttonTitle}>{this.state.distributorName}</Text>
                            </View>
                            </TouchableOpacity>
                        }
                        <Portal>
                            <Modal visible={this.state.partnerVisible} onDismiss={() => { this.hideDialog() }} contentContainerStyle={containerStyle}>
                                  <View>
                                     {/*<Text style={[styles.title, { paddingTop: 15 }]}>Select Distributor</Text>*/}
                                     <RadioButton.Group onValueChange={value => this.setDistributor(value)} value={this.state.distributorId}>
                                         {this.state.distributorList && this.state.distributorList.map(function(value, index){
                                           return ( <RadioButton.Item label={value[1]} value={value[0]} /> )
                                         })
                                         }
                                     </RadioButton.Group>
                                </View>
                            </Modal>
                        </Portal>
                        <TouchableOpacity
                            onPress={() => this.props.navigation.navigate('Search Farms', {
                                user: this.props.route.params.user,
                            })}
                            style={styles.button_alt}>
                            <View>
                                <Text style={styles.buttonTitle}>{"Search Farms"}</Text>
                            </View>
                        </TouchableOpacity>
                        {
                            this.adminButton()
                        }
                        {
                            this.viewUserDistributorButton()
                        }
                        {
                            this.viewIssuesButton()
                        }
                        {
                            this.viewRouteMAnagementButton()
                        }
                        {
                            this.viewWaterDetailsButton()
                        }
                        {
                            this.viewDeviceChecklistButton()
                        }
                        {
                            this.viewDeliveryButton()
                        }
                        {
                            this.viewEmptyDatesButton()
                        }
                        <Text style={styles.title}> </Text>

                        <Portal>
                            <Modal visible={this.state.visible} onDismiss={() => { this.hideModal() }}>
                                {this.modalItems()}
                            </Modal>
                        </Portal>
                    </View>
                </KeyboardAwareScrollView>
                <Text style={{ textAlignVertical: "center",textAlign: "center", marginBottom: 5,  color: "#7fbe41" }}>
                    {this.state.distributorName}
                </Text>
                <Text style={{ textAlignVertical: "center",textAlign: "center", marginBottom: 25 }}>
                    Current Version: {packageJson.version}
                </Text>
                {/*<Text style={{ bottom: 25, position: 'absolute', right: 5 }}>Current Version: {this.state.currVersion}</Text>*/}

                <Snackbar
                    visible={this.state.toastVisible}
                    onDismiss={() => this.setState({ toastVisible: false })}
                    action={{
                        label: 'Close',
                        onPress: () => {
                        },
                    }}
                    style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                >
                    <Text>{this.state.toastBody}</Text>
                </Snackbar>
            </View>
        )
    }
}
