import { Text, TouchableOpacity, View } from 'react-native';

import React from 'react';
import styles from '../src/styles';

export default function AdminMenu(props) {
    return (
        <View style={{flex: 1}}>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('View Issues', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"View issues"}</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('Route Management', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"Route manager"}</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('Admin Extra Functions Search', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"Water details"}</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('Admin Checklist', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"Device checklist"}</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('Admin deliveries', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"Deliveries"}</Text>
            </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}
            onPress={() => props.navigation.navigate('Empty dates', {
                user: props.route.params.user
            })}
        >
            <View>
                <Text style={styles.buttonTitle}>{"Empty dates"}</Text>
            </View>
        </TouchableOpacity>
        </View>
    )
}