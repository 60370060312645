import { ActivityIndicator, Text, View } from 'react-native'
import { Caption, DataTable, Title } from 'react-native-paper'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import styles from '../../styles';

export default class ViewVisits extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tanks: [], isLoading: true, visitData: this.props.route.params.visitData, Id: this.props.route.params.Id };
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true, visitData: this.props.route.params.visitData, Id: this.props.route.params.Id })
            console.log(this.props.route.params)
            this.getSelectedVisitDetails()
        })
    }

    async getSelectedVisitDetails(){
        var tempItem = this.state.visitData
        var selectedId = this.state.Id
        var tanksToShow = []
        var tempObj = {}
        for(var i =0; i < tempItem.length; i++){
            if(tempItem[i][0] == selectedId){
                for(var j=5; j < 11; j++){
                    if(tempItem[i][j] != {} && Object.keys(tempItem[i][j]).length > 0){
                        tempObj = JSON.parse(tempItem[i][j])
                        console.log("pushing: ", tempObj)
                        tanksToShow.push([j-4,tempObj])
                    }
                }
                this.setState({
                    itemToShow: tempItem[i],
                    isLoading: false,
                    tanksToShow: tanksToShow
                })
                return
            }
        }
        

    }

    getTankDisplay(itemToShow) {
        var tankDetails = itemToShow
        console.log("itemToShow", tankDetails)
        //Check tank dosage exists before rendering
        if (tankDetails[1]['Dosage'] != undefined) {
            return (
                <DataTable>
                    <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                        <DataTable.Title onPress={() => alert("No more details found here.")}><Title>Tank {tankDetails[0]}</Title></DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row key={"Dosage" + tankDetails[0]}>
                        <DataTable.Cell>
                            <Caption>Dosage: </Caption><Text>{tankDetails[1]['Dosage']}g</Text>
                        </DataTable.Cell>
                    </DataTable.Row>
                    <DataTable.Row key={"Accuracy" + tankDetails[0]}>
                        <DataTable.Cell>
                            <Caption>Accuracy: </Caption><Text>{tankDetails[1]['Accuracy']}</Text>
                        </DataTable.Cell>
                    </DataTable.Row>  
                    <DataTable.Row key={"Calibration" + tankDetails[0]}>
                        <DataTable.Cell>
                            <Caption>Calibration: </Caption><Text>{tankDetails[1]['Calibration']}</Text>
                        </DataTable.Cell>
                    </DataTable.Row> 
                </DataTable>
            )
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        else{
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <View>
                        <Text style={{ marginTop: 15, alignSelf: 'center', marginBottom: 15, fontSize: 24 }}>View Visit Details #{this.state.itemToShow[0]}</Text>
                        <DataTable style={{ marginTop: 0 }}>
                            <DataTable.Header style={{ marginTop: 0, backgroundColor: 'rgba(52,93,174,0.3)' }}>
                                <DataTable.Title style={{ marginTop: 0 }}><Title>Visit Details</Title></DataTable.Title>
                            </DataTable.Header>
                        </DataTable>
                        <DataTable.Row key={"Date"}>
                            <DataTable.Cell>
                                <Caption>Date: </Caption><Text style={{ textAlign: 'right' }}>{this.state.itemToShow[2]}</Text>
                            </DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row key={"VisitedBy"}>
                            <DataTable.Cell>
                                <Caption>Visited By: </Caption>{this.state.itemToShow[3]}
                            </DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row key={"Comments"}>
                            <DataTable.Cell>
                                <Caption>Comments: </Caption>{this.state.itemToShow[4]}
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            this.state.tanksToShow.map(item => <View>{this.getTankDisplay(item)}</View>)
                        }
                    </View>
                </KeyboardAwareScrollView>
            </View>
        )
        }
    }
}