import { ActivityIndicator, SafeAreaView, ScrollView, Text, View } from 'react-native'
import { Button, Modal, Portal, Title } from 'react-native-paper'

import { AnimatedCircularProgress } from 'react-native-circular-progress';
import React from 'react'
import { TouchableOpacity } from 'react-native';
import styles from '../../styles';

export default class TankLevelsDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tankVolumes: this.props.tankVolumes, tankCount: 0, tankModalVisible: false, tankNo: '' };
    }

    componentDidMount() {
        if (this.props.tankVolumes.length > 0) {
            this.setState({
                tankVolumes: this.props.tankVolumes,
                tankCount: this.props.tankCount
            })
        }
        else {
            //this device is offline
            //use tables ERP_Volume_History and ERP_Tank_Accuracy_1week to get details for offline boxes
            //change tank count to the this.props.tankCount & sent tankVolumes = new object formatted the
            this.setState({
                tankVolumes: this.props.tankVolumes,
                tankCount: this.props.tankCount //change to this.props.tankCount
            })
        }

    }

    renderTankDetails() {
        let tempArr = []
        //check if a tank  was pressed
        if (this.state.tankNo === '') {
            return
        }
        //extract data for the tank from tankVolumes object
        for (let i = 0; i < this.state.tankVolumes.length; i++) {
            if (this.state.tankNo === this.state.tankVolumes[i][0]) {
                tempArr.push(this.state.tankVolumes[i])
            }
        }
        //check if tempArr empty
        if (tempArr.length === 0) {
            return (
                <View style={{ marginLeft: 60 }}>
                    <Text>Product: No data</Text>
                    <Text>Dosage: No data</Text>
                    <Text>TankSize: No data</Text>
                    <Text>Calibration: No data</Text>
                    <Text>Accuracy7: No data</Text>
                    <Text>Accuracy21: No data</Text>
                    <Text>DaysLeft: No data</Text>
                    <Text>Last Connect: No data</Text>
                </View>
            )
        }

        console.log("NEW TANK VOLUMES OBJECT", this.state.tankVolumes)
        console.log("TEMP ARRAY TO SHOW", tempArr)
        return (
            <View style={{ marginLeft: 60 }}>
                <Text>Product: <Text style={{ fontWeight: "bold" }}>{tempArr[0][3]}</Text></Text>
                <Text>Dosage: <Text style={{ fontWeight: "bold" }}>{tempArr[0][4]}g</Text></Text>
                <Text>TankSize: <Text style={{ fontWeight: "bold" }}>{tempArr[0][5]}kg</Text></Text>
                <Text>Calibration: <Text style={{ fontWeight: "bold" }}>{tempArr[0][6]}</Text></Text>
                <Text>Accuracy7: <Text style={{ fontWeight: "bold" }}>{tempArr[0][7]}</Text></Text>
                <Text>Accuracy21: <Text style={{ fontWeight: "bold" }}>{tempArr[0][13]}</Text></Text>
                <Text>DaysLeft: <Text style={{ fontWeight: "bold" }}>{tempArr[0][8]}</Text></Text>
                <Text>Last Connect: <Text style={{ fontWeight: "bold" }}>{tempArr[0][12]}</Text></Text>
            </View>
        )
    }
    renderTankModal() {
        return (
            <SafeAreaView style={[styles.modalView, { marginTop: '5%', marginBottom: '5%', width: 250, height: 270, alignSelf: 'center' }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>{this.state.tankNo}</Title>
                {this.renderTankDetails()}
                <Button style={{ margin: 10, backgroundColor: '#FF0000' }} mode="contained" onPress={() => { this.hideTankModal() }}>Exit</Button>
            </SafeAreaView>
        )
    }

    handleTankDismiss() {
        this.hideTankModal()
        // this.setState({

        // })
    }

    showTankModal = (i) => {
        this.setState({
            tankModalVisible: true,
            tankNo: i
        })
    }

    hideTankModal = () => {
        this.setState({ tankModalVisible: false })
    }

    render() {
        return (
            /* Holds all the tanks columns in this row */
            < View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                < View style={{ flex: 3, flexDirection: 'column', paddingTop: 15 }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK1')}>
                            {this.state.tankCount >= 1 ? <AnimatedCircularProgress
                                margin={40}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[0] ? this.state.tankVolumes[0][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            { this.state.tankVolumes[0][1] ? this.state.tankVolumes[0][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount >= 1 ? <Text>{this.state.tankVolumes[0] ? this.state.tankVolumes[0][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount >= 1 ? <Text>{this.state.tankVolumes[0] ? this.state.tankVolumes[0][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK4')}>
                            {this.state.tankCount > 3 ? <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[3] ? this.state.tankVolumes[3][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            { this.state.tankVolumes[3] ? this.state.tankVolumes[3][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 3 ? <Text>{this.state.tankVolumes[3] ? this.state.tankVolumes[3][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 3 ? <Text>{this.state.tankVolumes[3] ? this.state.tankVolumes[3][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                </View >

                <View style={{ flex: 3, flexDirection: 'column', paddingTop: 15 }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK2')}>
                            {this.state.tankCount > 1 ? <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[1] ? this.state.tankVolumes[1][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            { this.state.tankVolumes[1] ? this.state.tankVolumes[1][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 1 ? <Text>{this.state.tankVolumes[1] ? this.state.tankVolumes[1][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 1 ? <Text>{this.state.tankVolumes[1] ? this.state.tankVolumes[1][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK5')}>
                            {this.state.tankCount > 4 ? <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[4] ? this.state.tankVolumes[4][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            {this.state.tankVolumes[4] ? this.state.tankVolumes[4][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        {this.state.tankCount > 4 ? <Text>{this.state.tankVolumes[4] ? this.state.tankVolumes[4][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 4 ? <Text>{this.state.tankVolumes[4] ? this.state.tankVolumes[4][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                </View >

                < View style={{ flex: 3, flexDirection: 'column', paddingTop: 15 }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK3')}>
                            {this.state.tankCount > 2 ? <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[2] ? this.state.tankVolumes[2][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            { this.state.tankVolumes[2] ? this.state.tankVolumes[2][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        {this.state.tankCount > 2 ? <Text>{this.state.tankVolumes[2] ? this.state.tankVolumes[2][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 2 ? <Text>{this.state.tankVolumes[2] ? this.state.tankVolumes[2][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <TouchableOpacity onPress={() => this.showTankModal('TANK6')}>
                            {this.state.tankCount > 5 ? <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={this.state.tankVolumes[5] ? this.state.tankVolumes[5][1] : 0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                {
                                    (fill) => (
                                        <Text>
                                            {this.state.tankVolumes[5] ? this.state.tankVolumes[5][1] : 0}
                                        </Text>
                                    )
                                }
                            </AnimatedCircularProgress> : <AnimatedCircularProgress
                                margin={10}
                                padding={5}
                                size={50}
                                width={7}
                                fill={0}
                                tintColor="#00e0ff"
                                backgroundColor="#3d5875">
                                    {
                                        (fill) => (
                                            <Text>
                                                { 'N/A'}
                                            </Text>
                                        )
                                    }
                                </AnimatedCircularProgress>
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        {this.state.tankCount > 5 ? <Text>{this.state.tankVolumes[5] ? this.state.tankVolumes[5][3] : 0}</Text> : <Text>{'No product'}</Text>}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {this.state.tankCount > 5 ? <Text>{this.state.tankVolumes[5] ? this.state.tankVolumes[5][11] : 0}</Text> : <Text>{'No date'}</Text>}
                    </View>
                </View >
                <Portal>
                    <Modal visible={this.state.tankModalVisible} onDismiss={() => { this.handleTankDismiss() }} animationType={"slide"}>
                        {this.renderTankModal()}
                    </Modal>
                </Portal>
            </View >
        )

    }
}