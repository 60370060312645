import { ActivityIndicator, FlatList, Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import { Searchbar } from 'react-native-paper'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class AdminExtraFunctionsSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, searchText: '' };
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true, searchText: '' })
            this.getData()
          })
    }

    async getData() {
        var requestBody = {
          context: 'getAllFarms',
            distributorId: this.props.route.params.distributorId
        }
        var data = await getDataAPI(requestBody)
        this.setState({
          isLoading: false,
          data: this.formatData(data)
        })
    }

      calcStyle(row) {
        var r = 127, g = 190, b = 65
        var rgb = 'rgb(' + r + ',' + g + ',' + b + ')'
        var style = {
          backgroundColor: rgb,
          marginLeft: 30,
          marginRight: 30,
          marginTop: 20,
          height: 48,
          borderColor: 'black',
          borderWidth: 1,
          borderRadius: 5,
          alignItems: 'center',
          justifyContent: 'center'
        }
        return style
      }
      formatData(ds) {
        ds = ds.sort((a, b) => a[3].toString().toLowerCase().localeCompare(b[3].toString().toLowerCase()))
        ds.forEach((i) => {
          ds.forEach((j) => {
            if (i[1] === j[1] && i[0] != j[0]) {
              i[1] = i[1] + " (" + i[2] + ")"
              j[1] = j[1] + " (" + j[2] + ")"
              if(i[2] == j[2]){
                i[1] = i[1] + " 1"
                j[1] = j[1] + " 2"
              }
            }
          })
        })
        return ds
      }

      search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
          return item[1].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
      };
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
        }

    render() {
        if (this.state.isLoading) {
            return (
              <View style={{ flex: 1 }}>
                <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
                <ActivityIndicator style={{marginTop: '20%'}} />
              </View>
            );
          }
          else if (this.state.searchText && this.state.searchText.length > 0 && this.state.filteredData.length > 0) {
            return (
              <View style={{ flex: 1 }}>
                <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
                <FlatList
                  data={this.state.filteredData}
                  renderItem={({ item }) => (
                    <TouchableOpacity style={this.calcStyle(item)}
                        onPress={() => this.props.navigation.navigate('Admin Extra Functions', {
                        DeviceId: item[4],
                        user: this.props.route.params.user
                        })}>
                        <Text style={styles.buttonTitle}>{item[1]}</Text>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item, index) => { return item[0].toString() }}
                />
              </View>
            );

          }
          return (
            <View style={{ flex: 1 }}>
              <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
            </View>
          );
    }
}
