import { ActivityIndicator, Dimensions, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'

import { DataTable } from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { Searchbar } from 'react-native-paper'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class AdminEmptyDates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            filteredData: []
        };
    }

    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
            return item[14].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
        console.log("filteredData",filteredData)

    };

    componentDidMount() {
            this.getData()
            this.setState({ searchText: '' });
    }

    showAll(){
        var data = this.state.data
        this.setState({
            filteredData: data,
        })
    }

    empty1Week(){
        console.log("in empty1week")

        var deviceDetails = this.state.data
        var filteredList = []
        var one_week = new Date();
        one_week = one_week.setDate(one_week.getDate() + 7);
        for (let i = 0; i < deviceDetails.length; i++)
        {
            if(deviceDetails[i][10] !== 'Tank capacity 0' && deviceDetails[i][10] !== 'NO DATA FOR 2 DAYS' && deviceDetails[i][10] !== 'NO READINGS' && deviceDetails[i][10] !== 'Tank dosage 0' && deviceDetails[i][10] !== 'Herd LSU 0')
            {
                var dateParts = deviceDetails[i][10].split("/")
                // month is 0-based, that's why we need dataParts[1] - 1
                var rowDateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                if(rowDateObject < new Date(one_week))
                {
                    filteredList.push(deviceDetails[i])
                    console.log("------------------------")
                    console.log("one_week: ", new Date(one_week))
                    console.log("current comparison: ", deviceDetails[i][10])
                    console.log("current comparison new Date: ", new Date(deviceDetails[i][10]))
                    console.log("Date found within 1 week!!")
                    console.log("------------------------")
                }
            }
            else {
                continue
            }
        }

        this.setState({
            filteredData: filteredList,
        })

    }
    empty2Weeks(){
        console.log("in empty2weeks")
        var deviceDetails = this.state.data
        var filteredList = []
        var two_week = new Date();
        two_week = two_week.setDate(two_week.getDate() + 14);
        for (let i = 0; i < deviceDetails.length; i++)
        {
            if(deviceDetails[i][10] !== 'Tank capacity 0' && deviceDetails[i][10] !== 'NO DATA FOR 2 DAYS' && deviceDetails[i][10] !== 'NO READINGS' && deviceDetails[i][10] !== 'Tank dosage 0' && deviceDetails[i][10] !== 'Herd LSU 0')
            {
                var dateParts = deviceDetails[i][10].split("/")
                // month is 0-based, that's why we need dataParts[1] - 1
                var rowDateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                if(rowDateObject < new Date(two_week))
                {
                    filteredList.push(deviceDetails[i])
                    console.log("------------------------")
                    console.log("two_week: ", new Date(two_week))
                    console.log("current comparison: ", deviceDetails[i][10])
                    console.log("current comparison new Date: ", new Date(deviceDetails[i][10]))
                    console.log("Date found within 2 weeks!!")
                    console.log("------------------------")
                }
            }
            else {
                continue
            }
        }

        this.setState({
            filteredData: filteredList,
        })
    }

    async getData() {
        var requestBody = {
            context: 'getEmptyDatesAndRouteCodes',
            distributorId: this.props.route.params.distributorId
        }
        var deviceDetails = await getDataAPI(requestBody)

        console.log("Done getting data")
        this.setState({
            isLoading: false,
            data: deviceDetails,
            filteredData: deviceDetails,
        })
    }

    getBackgroundColour(emptyDate){
        var today = new Date()
        var dateString = emptyDate

        var dateParts = dateString.split("/")

        // month is 0-based, that's why we need dataParts[1] - 1
        var rowDateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
        var nextWeek = new Date()
        nextWeek.setDate(today.getDate() + 7)

        // console.log("EmptyDate: ", emptyDate)
        // console.log("rowDateObject(emptyDate formatted into new date): ", rowDateObject)
        // console.log("nextWeek(nextWeek date to compare to): ", nextWeek)

        if(rowDateObject > nextWeek ){
            return {backgroundColor: 'rgba(0, 255, 0, 0.3)'}
        }
        else if(rowDateObject > today){
            return {backgroundColor: 'rgba(255, 255, 0, 0.5)'}
        }
        else{
            return {backgroundColor: 'rgba(255,0,0,0.5)'}
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1 }}>
                    <Searchbar placeholder="Search empty date by farm..." value={this.state.searchText} />
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search empty date by farm..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                        <View style={{flexDirection: 'row', height: 50, width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <TouchableOpacity style={[styles.button, {marginLeft: 2, marginRight: 2, width: '30%'}]}
                            onPress={() => this.empty1Week()
                            }
                        >
                            <View>
                                <Text style={styles.buttonTitle}>{"Filter 1 week"}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, {marginLeft: 2, marginRight: 2, width: '30%'}]}
                            onPress={() => this.empty2Weeks()
                            }
                        >
                            <View>
                                <Text style={styles.buttonTitle}>{"Filter 2 weeks"}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, {marginLeft: 2, marginRight: 2, width: '30%'}]}
                            onPress={() => this.showAll()
                            }
                        >
                            <View>
                                <Text style={styles.buttonTitle}>{"Show all"}</Text>
                            </View>
                        </TouchableOpacity>
                        </View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Farm</DataTable.Title>
                                    <DataTable.Title>Product</DataTable.Title>
                                    <DataTable.Title>EmptyDate</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData?.map(checklist => {
                                                return (
                                                    <DataTable.Row style={this.getBackgroundColour(checklist[10])} key={checklist[0] + checklist[1]} onPress={() => this.props.navigation.navigate('Assign Routes', {
                                                        routeCode: checklist[16],
                                                        user: this.props.route.params.user
                                                    })}>
                                                        <DataTable.Cell>
                                                            {checklist[15]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[2]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {checklist[10]}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )
    }
}
