import '../../globals.js'

import * as scale from 'd3-scale'
import * as shape from 'd3-shape'

import { ActivityIndicator, Dimensions, FlatList, Text, TouchableOpacity, View } from 'react-native'
import { AreaChart, Grid, LineChart, ProgressCircle, XAxis, YAxis } from 'react-native-svg-charts'

import { AnimatedCircularProgress } from 'react-native-circular-progress';
import React from 'react'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class AdminExtraFunctions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, displayed: 0, renderGraphics: '', currHerd: 'HERD1', currTank: 'TANK1', ScreenHeight: 600 };
  }

  componentDidMount() {
    this.focusSubscription = this.props.navigation.addListener('focus', () => {
      this.setState({ isLoading: true, renderGraphics: '', currHerd: 'HERD1', currTank: 'TANK1', ScreenHeight: 600 })
      this.renderDeviceInfo(this.props.route.params.DeviceId)
      this.getScreenHeight()
    })
  }

  async getScreenHeight() {
    let screenHeight = Math.round(Dimensions.get('window').height);
    let screenWidth = Math.round(Dimensions.get('window').width);
    console.log('screenHeight: ', screenHeight)
    this.setState({
        screenHeight: screenHeight,
        screenWidth: screenWidth
    })
  }
  async getRequestURL() {
    console.log(this.props.route.params.user)
    var requestBody = {
      context: 'getURL',
      UserId: this.props.route.params.user.id
    }
    var data = await getDataAPI(requestBody)

    if(data.length == 0 || data == undefined){
      return 'Not Authorized'
    }
    console.log(data)
    return data
  }
  async getAuthToken() {
    var refresh_token = await this.getRequestURL()
    if(refresh_token == 'Not Authorized'){
      alert("User is not Authorized to access this feature.")
      return "Not Authorized"
    }
    var url = ['https://securetoken.googleapis.com/v1/token?key=', global.Apikey, '&grant_type=refresh_token&refresh_token=', refresh_token]
    url = url.join('')
    console.log(url)
    var data = {}
    await fetch(url, {
      method: 'POST',
      headers: {
        'Connection': 'keep-alive',
        'Accept': '*/*'
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        data = responseJson
      })
      .catch(error => {
        console.error(error);
      });
    this.setState({
      token: data['access_token']
    })
    //console.log(this.state.token)
    return "Authorized"
  }


  async useTerraAPI(command) {

    var authorized = await this.getAuthToken()
    if(authorized == 'Not Authorized'){
      return
    }
    switch (command) {
      case 'RESTART':
        var url = `https://api.terranutritech.com/api/portal/devices/commands?deviceId=${this.state.itemToRender}&command=restart`
        var data = {}
        await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + this.state.token,
            'Connection': 'keep-alive',
            'Accept': '*/*'
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            data = responseJson
            console.log(data)
          })
          .catch(error => {
            console.error(error);
          });

        if (data['status'] == "ok") {
          alert("Device restart successfully sent.")
        }
        else {
          alert("Issue restarting device or connecting to the API.")
        }
        break;
      case 'Set Offline':
        var url = `https://api.terranutritech.com/api/portal/devices/commands?deviceId=${this.state.itemToRender}&command=offline`
        var data = {}
        await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + this.state.token,
            'Connection': 'keep-alive',
            'Accept': '*/*'
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            data = responseJson
            console.log(data)
          })
          .catch(error => {
            console.error(error);
          });

        if (data['status'] == "ok") {
          alert("Device successfully set offline.")
        }
        else {
          alert("Issue setting device to offline or connecting to the API.")
        }
        break;
      case 3:
        break;
      default:
        console.log("Went into default case statement")
      // code block
    }
    this.sleep(5000)
  }


  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  createLSU(data) {
    var HERD1 = 0
    var HERD2 = 0
    var HERD3 = 0
    //get lsu for each herd only most recent
    for (let i = 0; i < data.length; i++) {
      if (data[i][2] == 'HERD1') {
        HERD1 = data[i][1]
      }
      else if (data[i][2] == 'HERD2') {
        HERD2 = data[i][1]
      }
      else if (data[i][2] == 'HERD3') {
        HERD3 = data[i][1]
      }
    }
    var lsuArr = []
    lsuArr.push(HERD1, HERD2, HERD3)
    return lsuArr
  }

  createArea(data) {
    var areaArr = []
    var YArr = []
    var testArr = []
    var contentInset = { top: 5, bottom: 5 }
    for (let i = 0; i < data.length; i++) {
      if (data[i][2] == this.state.currHerd & data[i][4] == this.state.currTank) {
        areaArr.push(data[i][5])
        YArr.push(new Date(data[i][6]))
        testArr.push({ water: data[i][5], time: data[i][6] })
      }
    }
    var XArr = []
    for (let i = 0; i < YArr.length; i++) {
      var element0 = YArr[i].toLocaleTimeString().toString()
      var d = element0;
      element0 = d.replace(":00:00", "")
      XArr.push(element0)
    }
    return (
      <View style={{ height: 260, flexDirection: 'column', flex: 1, width: '100%', paddingBottom: 5 }}>
        <View style={{ flexDirection: 'row', width: '100%', height: '100%', flex: 1 }}>
          <YAxis
            data={areaArr}
            contentInset={contentInset}
            svg={{
              fill: 'grey',
              fontSize: 10,
            }}
            numberOfTicks={8}
            formatLabel={(value) => `${value}Litres`}
          />
          <AreaChart
            style={{ marginLeft: 16, flex: 1, height: '100%', marginRight: 10 }}
            data={areaArr}
            contentInset={{ contentInset }}
            curve={shape.curveNatural}
            svg={{ fill: 'rgba(89, 227, 255, 0.8)' }}
          >
            <Grid />
          </AreaChart>
        </View>
        <View style={{ height: 30 }}>
          <XAxis
            data={XArr}
            style={{ height: 40, padding: 10 }}
            spacing={10}
            contentInset={{ left: 10, right: 10 }}
            scale={scale.scaleBand}
            svg={{ fontSize: 10, rotation: 40, fill: 'black', originY: 25 }}
            formatLabel={(value, index) =>  XArr[index]}
          />
        </View>
      </View>
    )
  }

  async renderDeviceInfo(item) {
    this.setState({
      itemToRender: item,
      isLoading: true
    })
    var requestBody = {
      context: 'extraDetails',
      DeviceId: item
    }
    var data = await getDataAPI(requestBody)
    this.setState({
      allDetails: data
    })

    var count = 0
    console.log("The data returned  ", data)
    for (let i = 0; i < data.length; i++) {
      if (data[i][2] == this.state.currHerd) {
        count += 1
      }
    }

    if (count > 0) {
      //there is tanks for herd1
    }
    else {
      this.setState({
        renderGraphics: '',
        isLoading: false,
        noData: true
      })
      return
    }
    // await this.sleep(4000)
    //Get water data for last x time per herd,
    //average drinking for x time,

    // var line = await this.createLine(data)
    // var pie = await this.createPie(data)
    var lsu = await this.createLSU(data)
    var area = await this.createArea(data)


    this.setState({
      renderGraphics: 'render',
      isLoading: false,
      // line: line,
      lsu: lsu,
      area: area
    })
  }

  changeHerd(i) {
    this.setState({
      isLoading: true
    })

    if (i == 1) {
      this.setState({
        renderGraphics: '',
        currHerd: 'HERD1',
        currTank: 'TANK1',
        noData: false
      })
      this.renderDeviceInfo(this.state.itemToRender)
    }
    else if (i == 2) {
      this.setState({
        renderGraphics: '',
        currHerd: 'HERD2',
        currTank: 'TANK4',
        noData: false
      })
      this.renderDeviceInfo(this.state.itemToRender)
    }
    else if (i == 3) {
      this.setState({
        renderGraphics: '',
        currHerd: 'HERD3',
        currTank: 'TANK5',
        noData: false
      })
      this.renderDeviceInfo(this.state.itemToRender)
    }

    this.setState({
      isLoading: false
    })
  }

  showHerdTanks(k) {
    var data = this.state.allDetails
    var tanksH1 = []
    var tanksH2 = []
    var tanksH3 = []

    for (let i = 0; i < data.length; i++) {
      if (i == data.length - 1) {
        for (let j = 0; j < data.length; j++) {
          if (data[i][6] == data[j][6] & data[j][2] == 'HERD1') {
            tanksH1.push([data[j][4], data[j][3], data[j][7], data[j][8], data[j][9], data[j][10], data[j][11]])
          }
          else if (data[i][6] == data[j][6] & data[j][2] == 'HERD2') {
            tanksH2.push([data[j][4], data[j][3], data[j][7], data[j][8], data[j][9], data[j][10], data[j][11]])
          }
          else if (data[i][6] == data[j][6] & data[j][2] == 'HERD3') {
            tanksH3.push([data[j][4], data[j][3], data[j][7], data[j][8], data[j][9], data[j][10], data[j][11]])
          }

        }

      }
    }
    // --------------------------------------------------------------------------------------------------------
    // HERD1 TANK RENDERING
    // --------------------------------------------------------------------------------------------------------
    var tank1Render = []
    for (let i = 0; i < tanksH1.length; i++) {
      var tempItem = (

        <View style={{ flex: 1, flexDirection: 'row', maxHeight: 65, paddingTop: 10, paddingBottom: 10 }}>
          <View style={{ flexDirection: 'column', width: '70%', height: 55, maxHeight: 65 }}>
            <Text style={{ fontWeight: 'bold' }}>{tanksH1[i][0]} - <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH1[i][5]}</Text> </Text>
            <Text style={{ fontWeight: 'bold' }}>Dosage: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH1[i][3]}g</Text></Text>
            <Text style={{ fontWeight: 'bold' }}>TankSize: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH1[i][2]}kg</Text></Text>
          </View>
          <View style={{ flex: 1, flexDirection: 'column', width: '30%', height: 55, maxHeight: 65, justifyContent: "center", alignItems: 'center' }}>
            <Text style={{ textAlignVertical: "center", textAlign: "center", fontSize: 12 }}>% Dispensed</Text>
            <AnimatedCircularProgress
              size={50}
              width={6}
              fill={tanksH1[i][6]}
              tintColor="#00e0ff"
              backgroundColor="#3d5875">
              {
                (fill) => (
                  <Text>
                    { tanksH1[i][6]}
                  </Text>
                )
              }
            </AnimatedCircularProgress>
          </View>
        </View>
      )
      tank1Render[i] = (tempItem)
    }
    // --------------------------------------------------------------------------------------------------------
    // HERD2 TANK RENDERING
    // --------------------------------------------------------------------------------------------------------
    var tank2Render = []
    for (let i = 0; i < tanksH2.length; i++) {
      var tempItem = (
        <View style={{ paddingTop: 10 }}>
          <View style={{ flex: 1, flexDirection: 'row', maxHeight: 65 }}>
            <View style={{ flexDirection: 'column', width: '75%', height: 55, maxHeight: 65 }}>
              <Text style={{ fontWeight: 'bold' }}>{tanksH2[i][0]} - <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH2[i][5]}</Text></Text>
              <Text style={{ fontWeight: 'bold' }}>Dosage: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH2[i][3]}g</Text></Text>
              <Text style={{ fontWeight: 'bold' }}>TankSize: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH2[i][2]}kg</Text></Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', height: 55, maxHeight: 65, justifyContent: "center", alignItems: 'center' }}>
              <Text style={{ textAlignVertical: "center", textAlign: "center", fontSize: 12 }}>% Dispensed</Text>
              <AnimatedCircularProgress
                size={50}
                width={6}
                fill={tanksH2[i][6]}
                tintColor="#00e0ff"
                backgroundColor="#3d5875">
                {
                  (fill) => (
                    <Text>
                      { tanksH2[i][6]}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
            </View>
          </View>
        </View>
      )
      tank2Render[i] = (tempItem)
    }
    // --------------------------------------------------------------------------------------------------------
    // HERD3 TANK RENDERING
    // --------------------------------------------------------------------------------------------------------
    var tank3Render = []
    for (let i = 0; i < tanksH3.length; i++) {
      var tempItem = (
        <View style={{ paddingTop: 10 }}>
          <View style={{ flex: 1, flexDirection: 'row', maxHeight: 65 }}>
            <View style={{ flexDirection: 'column', width: '75%', maxHeight: 65 }}>
              <Text style={{ fontWeight: 'bold' }}>{tanksH3[i][0]} - <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH3[i][5]}</Text></Text>
              <Text style={{ fontWeight: 'bold' }}>Dosage: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH3[i][3]}g</Text></Text>
              <Text style={{ fontWeight: 'bold' }}>TankSize: <Text style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{tanksH3[i][2]}kg</Text></Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', height: 55, maxHeight: 65, justifyContent: "center", alignItems: 'center' }}>
              <Text style={{ textAlignVertical: "center", textAlign: "center", fontSize: 12 }}>% Dispensed</Text>
              <AnimatedCircularProgress
                size={50}
                width={6}
                fill={tanksH3[i][6]}
                tintColor="#00e0ff"
                backgroundColor="#3d5875">
                {
                  (fill) => (
                    <Text>
                      { tanksH3[i][6]}
                    </Text>
                  )
                }
              </AnimatedCircularProgress>
            </View>
          </View>
        </View>
      )
      tank3Render[i] = (tempItem)
    }
    // tank1Render.reverse()
    // tank2Render.reverse()
    // tank3Render.reverse()
    switch (k) {
      case 1:
        return (
          <View>
            {tank1Render}
          </View>
        )
      case 2:
        return (
          <View>
            {tank2Render}
          </View>
        )

      case 3:
        return (
          <View>
            {tank3Render}
          </View>
        )
      default:
        console.log("Went into default case statement")
      // code block
    }
  }

  isAPI_active(identifier){

    // if (this.state.isPressed){
    //   return(
    //     <ActivityIndicator />
    //   )
    // }
    // else
    if (identifier == 0) {
      return (
        'Restart'
      )
    }
    else if (identifier == 1) {
      return (
        'Set Offline'
      )
    }
    else if (identifier == 2) {
      return (
        '---'
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator style={{marginTop: '20%'}} />
        </View>
      );
    }

    else if (this.state.renderGraphics.length > 0) {
      return (
        <View style={{ flex: 1, flexDirection: 'column', backgroundColor: '#c7ffff' }}>
          <View style={{ flex: 1, flexDirection: 'row', height: 50, maxHeight: 55, backgroundColor: '#c7ffff' }}>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(1) }}>
              <Text style={styles.buttonTitle}>Herd1</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(2) }}>
              <Text style={styles.buttonTitle}>Herd2</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(3) }}>
              <Text style={styles.buttonTitle}>Herd3</Text>
            </TouchableOpacity>
          </View>
          <View style={{eight: this.state.screenHeight - 170}}>
          <View style={{ flex: 1, flexDirection: 'row', backgroundColor: '#c9ffcb', alignItems: 'center', justifyContent: 'center', padding: 5, maxHeight: 25 }}>
            {this.state.currHerd == 'HERD1' && <Text>Herd 1 size: {this.state.lsu[0]}</Text>}
            {this.state.currHerd == 'HERD2' && <Text>Herd 2 size: {this.state.lsu[1]}</Text>}
            {this.state.currHerd == 'HERD3' && <Text>Herd 3 size: {this.state.lsu[2]}</Text>}
          </View>
          {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <View style={{ width: '100%', backgroundColor: '#c7ffff', paddingBottom: 25 }}>
            {/* to show tank info here -> tanks, dispensed, calibration, product */}
            {this.state.currHerd == 'HERD1' && this.showHerdTanks(1)}
            {this.state.currHerd == 'HERD2' && this.showHerdTanks(2)}
            {this.state.currHerd == 'HERD3' && this.showHerdTanks(3)}
          </View>
          {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <View style={{ width: '100%', paddingTop: 25, height: 300, backgroundColor: '#c9ffcb', paddingBottom: 20 }}>
            {this.state.area}
          </View>
          </View>
          <View style={{ flex: 1, width: '100%', flexDirection: 'row', height: 50, backgroundColor: '#c7ffff' }}>
            <TouchableOpacity style={{ width: '33.33%',  backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.useTerraAPI('RESTART') }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(0) }</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%',  backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.useTerraAPI("Set Offline") }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(1) }</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%',  backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { console.log("Other option") }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(2) }</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
    else if (this.state.noData == true) {
      return (
        <View style={{ flex: 1,flexDirection: 'column', backgroundColor: '#c7ffff' }}>
          <View style={{ flex: 1, flexDirection: 'row', height: 50, backgroundColor: '#c7ffff' }}>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(1) }}>
              <Text style={styles.buttonTitle}>Herd1</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(2) }}>
              <Text style={styles.buttonTitle}>Herd2</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, height: '100%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.changeHerd(3) }}>
              <Text style={styles.buttonTitle}>Herd3</Text>
            </TouchableOpacity>
          </View>

          <View style={{ backgroundColor: '#c9ffcb', alignItems: 'center', justifyContent: 'center', height: this.state.screenHeight - 170, width: '100%' }}>
            <Text>There is no data for this herd</Text>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', height: 50, backgroundColor: '#c7ffff' }}>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.useTerraAPI('RESTART') }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(0) }</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { this.useTerraAPI("Set Offline") }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(1) }</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ width: '33.33%', padding: 5, backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}
              onPress={() => { console.log("Other option") }}>
              <Text style={styles.buttonTitle}>{ this.isAPI_active(2) }</Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator style={{marginTop: '20%'}} />
      </View>
    );
  }
}
