import { Image, View } from 'react-native';

import FarmList from '../_shared/FarmList'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import styles from '../../styles';

export default function FarmListScreen(props) {
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <FarmList {...props} extraData={props.route.params.routeCode} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}