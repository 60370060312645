import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import { View } from 'react-native';
import ViewVisits from './ViewVisits'
import styles from '../../styles';

export default function ViewVisitsScreen(props) {
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <ViewVisits {...props} extraData={props.route.params.Id} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}