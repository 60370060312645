import * as Permissions from 'expo-permissions';

import { ActionSheetIOS, ActivityIndicator, Picker, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { Button, DataTable, Modal, Portal, RadioButton, Title } from 'react-native-paper';
import { Constants, ImagePicker } from 'expo';

import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler';
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class TankDeliveryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, displayed: 0, tankNo: props.extraData, productsAssigned: props.productsAssigned, products: props.products,
            batchCodes: [''], batchAmounts: [0], batchProducts: ['No Product', 'No Product', 'No Product', 'No Product', 'No Product'], removeVisible: false, checked: [''],
            containerTypes: [['', ''], ['', ''], ['', ''], ['', ''], ['', '']], levelBefore: props.levelBefore, hasCameraPermission: null,
            scanned: undefined, i: 0, manualInput: false, hiddenSwitch: false, type: Camera.Constants.Type.back, batchScanned: []
        };
    }

    hideModal = () => {
        this.setState({ visible: false })
    }

    openAndSetState = async (i) => {
        await this.getPermissionsAsync()
        this.setState({ visible: true, i: i, scanned: false })
    }

    getPermissionsAsync = async () => {
        const { status } = await Permissions.askAsync(Permissions.CAMERA);
        this.setState({ hasCameraPermission: status === 'granted' });
    }

    handleBarCodeScanned = ({ type, data }) => {
        this.setState({ scanned: true })
        var index = this.state.i
        // alert(`Bar code saved! ${data}`)
        var fields = data.split('-');

        var product = fields[0];
        var batch = fields[1];
        var barrel = fields[2];
        var quantity = fields[3];
        var batchProducts = this.state.batchProducts
        batchProducts[index] = product
        if (fields.length == 4) {
            console.log("it's our new barcode")
            var checked = ['Other', 'Other', 'Other', 'Other', 'Other', 'Other']
            // this.showRadioLabel(index)
            this.setState({
                batchProducts: batchProducts
            })
            this.hideModal()
            this.updateContainerValues(index, barrel, checked)
            this.updateBatchScanned(index, true)
            this.updateBatchNo(index, batch)
            this.updateBatchProduct(index, product)
            this.updateBatchAmt(index, quantity)
            alert(`Barcode scanned - ${data}`)
        }
        else if (fields.length == 1) {
            console.log("it's our old barcode")
            this.updateBatchNo(index, data)
            this.updateBatchProduct(index, data)
            this.updateBatchScanned(index, true)
            this.hideModal()
            alert(`Barcode scanned - ${data}`)

        }
        else {
            alert(`Invalid barcode. Try again.`)
            this.hideModal()
        }



    };

    barcodeModal(i) {
        var scanned = this.state.scanned
        return (
            <View style={[styles.modalView, { width: '80%', height: 400, alignSelf: 'center' }]}>
                <View style={[{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', height: 50 }]}>
                    <TextInput key={"batchAmt" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        style={{ width: '70%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, height: 50, padding: 10 }}
                        placeholder="Batch amount (kg)"
                        placeholderTextColor="#60605e"
                        keyboardType={'numeric'}
                        value={this.state.batchAmounts[i]}
                        onChangeText={batchAmt => this.updateBatchAmt(i, batchAmt)}
                    />
                    <TouchableOpacity style={[styles.button_alt, { width: '30%', marginVertical: '4%', marginLeft: 0, marginRight: 0, marginTop: 15, height: 50, padding: 10 }]} onPress={() => this.hideModal()}>
                        <Text style={styles.buttonTitle}>Submit</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ width: '100%', height: 350, alignItems: 'center' }}>
                    <BarCodeScanner
                        onBarCodeScanned={scanned ? undefined : this.handleBarCodeScanned}
                        style={{ width: 400, height: '100%', overflow: 'hidden' }}
                    />
                </View>
            </View>
        )
    }

    async componentDidMount() {
        this.setState({
            levelAfter: '',
            batchCodes: [''],
            batchAmounts: [0],
            isLoading: true,
            batchProducts: ['No Product'],
            ios_batchExpanded: [false],
            checked: ['', '', '', '', ''],
            containerTypes: [['', ''], ['', ''], ['', ''], ['', ''], ['', '']],
            manualInput: false, hiddenSwitch: false, products: global.products
        })

        if (Platform.OS === 'ios') {
            console.log("ios detected")
            const { status } = await Permissions.askAsync(Permissions.CAMERA);
            this.setState({ hasCameraPermission: status === 'granted' });
        }
        else if (Platform.OS === 'android') {
            console.log("Android detected")
            const { status } = await Permissions.askAsync(Permissions.CAMERA);
            this.setState({ hasCameraPermission: status === 'granted' });
        }

        console.log(this.state.levelBefore)
        if (this.props.tankData != {} && this.props.tankData != undefined && Object.keys(this.props.tankData).length > 0) {
            console.log(this.props.tankData)
            let bc = this.state.batchCodes
            let ba = this.state.batchAmounts
            let bp = this.state.batchProducts
            let ct = this.state.containerTypes
            let cc = this.state.checked

            for (let i = 0; i < this.props.tankData.details.Batch.length; i++) {
                bc[i] = this.props.tankData.details.Batch[i][1]
                ba[i] = this.props.tankData.details.Batch[i][2]
                bp[i] = this.props.tankData.details.Batch[i][3]
                ct[i][0] = this.props.tankData.details.Batch[i][4]
                ct[i][1] = this.props.tankData.details.Batch[i][5]
                cc[i] = this.props.tankData.details.Batch[i][4]
            }
            console.log(cc)
            this.setState({
                batchCodes: bc,
                batchAmounts: ba,
                batchProducts: bp, //something might be wrong here, came back as no product
                containerTypes: ct,
                checked: cc
            })
        }
        this.setState({
            isLoading: false
        })
    }
    async getProductsList() {
        this.setState(
            {
                products: global.products
            })
    }
    handleFormComplete = () => {
        let formatChecked = true;
        for (let i = 0; i < this.state.batchCodes.length; i++) {
            if (this.state.batchCodes[i] == '') {
                formatChecked = false
                alert('No Batch Code entered for batch ' + (i + 1))
            }
            else if (this.state.batchAmounts[i] == 0) {
                formatChecked = false
                alert('No Amount entered for batch ' + (i + 1))
            }
            else if (this.state.batchProducts[i] == 'No Product') {
                formatChecked = false
                alert('No Product selected for batch ' + (i + 1))
            }
            else if (this.state.containerTypes[i] == null || this.state.containerTypes[i] == undefined || this.state.containerTypes[i][0] == '' || this.state.containerTypes[i][0] == 0) {
                formatChecked = false
                alert('No container info for batch ' + (i + 1))
            }
            else if (this.state.containerTypes[i][1] == null || this.state.containerTypes[i][1] == '' || this.state.containerTypes[i][1] == undefined || this.state.containerTypes[i][1] == 0) {
                formatChecked = false
                alert('No container num for batch ' + (i + 1))
            }
            else if (this.state.containerTypes[i][0] == null || this.state.containerTypes[i][0] == '' || this.state.containerTypes[i][0] == undefined || this.state.containerTypes[i][0] == '') {
                formatChecked = false
                alert('No container btn for batch ' + (i + 1))
            }

        }
        if (formatChecked) {
            var bc = this.state.batchCodes
            var ba = this.state.batchAmounts
            var bp = this.state.batchProducts
            var ctc = this.state.containerTypes
            var ctt = this.state.checked
            var scan = this.state.batchScanned
            var bcap = []
            for (let i = 0; i < bc.length; i++) {
                bcap.push([i, bc[i], ba[i], bp[i], ctt[i], ctc[i][1], scan[i]])
            }

            for (let i = 0; i < ctc.length; i++) {
                ctc[i][0] = ctt[i]
            }
            this.setState({
                containerTypes: ctc
            })
            console.log(bcap)
            this.setState({ tankNo: this.props.extraData })

            let amt = this.state.productsAssigned
            if (amt.hasOwnProperty('Amounts')) {
                for (let i = 0; i < amt.Amounts.length; i++) {
                    amt.Amounts[i] = 0;
                }
            }

            var levelBefore = this.state.levelBefore
            levelBefore = levelBefore[this.state.tankNo - 1]
            if (levelBefore == undefined) {
                levelBefore = 'Not added'
            }
            var orderObj = {
                details: {
                    "levelBefore": levelBefore,
                    "levelAfter": '',
                    "Batch": bcap,
                    "tankNo": this.state.tankNo,
                    "containers": this.state.containerTypes
                },
                products: amt
            }
            this.props.onFormComplete(orderObj)
            this.setState({ isLoading: true })
        }
    }
    updateExpanded(i, isExpanded) {
        var x = this.state.ios_batchExpanded
        x[i] = isExpanded
        this.setState({
            ios_batchExpanded: x
        })
    }
    ios_getPickerHeight(i) {
        return this.state.ios_batchExpanded[i] ? { height: 150 } : { height: 50 }
    }
    getDropdownOSDependant(i) {
        if (Platform.OS == 'ios') {
            return (
                <Picker
                    itemStyle={{ height: 128 }}
                    selectedValue={this.state.batchProducts[i]}
                    onValueChange={product => this.updateBatchProduct(i, product)}
                    style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5 }}
                    mode="dropdown">
                    {this.state.products.map((item, index) => {
                        return (<Picker.Item textStyle={{ fontSize: 8 }} label={item[1]} value={item[1]} key={index} />)
                    })}
                </Picker>)
        }
        return (
            <Picker
                selectedValue={this.state.batchProducts[i]}
                onValueChange={product => this.updateBatchProduct(i, product)}
                style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 12 }}
                mode="dropdown">
                { this.state.products.map((item, index) => {
                    return (<Picker.Item label={item[1]} value={item[1]} key={index} />)
                })}
            </Picker>
        )
    }

    goBack = () => {
        this.props.onBackPressed(this.state.tankNo - 1)
    }
    skipTank = () => {

        this.setState({ tankNo: this.props.extraData })
        var orderObj = {
            details: {
                levelBefore: '',
                levelAfter: '',
                Batch: '',
                tankNo: this.state.tankNo,
                containers: ''
            },
            products: this.state.productsAssigned
        }
        this.props.onFormComplete(orderObj)
        this.setState({ isLoading: true })
    }
    addBatch() {
        var bc = this.state.batchCodes
        bc[bc.length] = ''
        var ba = this.state.batchAmounts
        ba[ba.length] = 0
        this.setState({
            batchCodes: bc,
            batchAmounts: ba
        })
    }
    removeBatch() {
        var bc = this.state.batchCodes
        var ba = this.state.batchAmounts
        let newBC = []
        let newBA = []
        if (bc.length > 1) {
            for (let i = 0; i < bc.length - 1; i++) {
                newBC[i] = bc[i]
                newBA[i] = ba[i]
            }
            this.setState({
                batchCodes: newBC,
                batchAmounts: newBA
            })
        }
    }
    updateBatchNo(index, batchCode) {
        var bc = this.state.batchCodes
        bc[index] = batchCode
        this.setState({
            batchCodes: bc
        })
    }
    updateBatchAmt(index, batchAmount) {
        var ba = this.state.batchAmounts
        ba[index] = batchAmount
        this.setState({
            batchAmounts: ba
        })
    }
    updateBatchAmtManual(index, batchAmount) {
        var ba = this.state.batchAmounts
        ba[index] = batchAmount
        this.updateBatchScanned(index, false)
        this.setState({
            batchAmounts: ba
        })
    }
    updateBatchProduct(index, batchProduct) {
        var bp = this.state.batchProducts
        bp[index] = batchProduct
        this.setState({
            batchProducts: bp
        })
    }
    updateBatchScanned(index, scanned) {
        var bs = this.state.batchScanned
        bs[index] = scanned
        this.setState({
            batchScanned: bs
        })
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    async updateContainerValues(i, containerNo, containerArr) {
        var container = containerArr[i]
        var containerTypes = this.state.containerTypes
        containerTypes[i] = [container, containerNo]

        await this.setStateAsync({
            containerTypes: containerTypes
        })
    }

    showConfirmRemove = () => {
        let formatChecked = false;
        for (let i = 0; i < this.state.batchCodes.length; i++) {
            if (this.state.batchCodes[i] != '') {
                formatChecked = true
            }
            else if (this.state.batchAmounts[i] != 0) {
                formatChecked = true
            }
            else if (this.state.batchProducts[i] != 'No Product') {
                formatChecked = true
            }

        }
        console.log(formatChecked)
        if (formatChecked) {
            this.setState({ removeVisible: true })
        } else {
            this.skipTank()
        }
    }
    hideConfirmRemove = () => {
        this.setState({ removeVisible: false })
    }

    renderConfirmModal() {
        return (
            <View style={[styles.modalView, { flexDirection: 'column', justifyContent: 'center' }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>Skip Tank? (form contains data)</Title>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10 }}>
                    <Button color={'#f0f0f0'} mode='contained' onPress={this.hideConfirmRemove} > Cancel </Button>
                    <Button color={'#be4141'} mode='contained' onPress={this.skipTank}> Okay </Button>
                </View >
            </View>
        )
    }
    getRadioGroup(i) {
        var currentContainer = []
        currentContainer = this.state.checked
        return (
            <View style={{ flex: 1, flexDirection: 'row', height: 50 }}>
                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: '#CCCCCC', borderRadius: 5 }}>
                    <RadioButton
                        value="Barrel"
                        uncheckedColor='#CCCCCC'
                        color='#7fbe41'
                        status={currentContainer[i] === 'Barrel' ? 'checked' : 'unchecked'}
                        onPress={() => {
                            currentContainer[i] = 'Barrel'
                            this.setState({ checked: currentContainer })
                        }}
                    />
                    <Text onPress={() => {
                        currentContainer[i] = 'Barrel'
                        this.setState({ checked: currentContainer })
                    }}>Barrel</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: '#CCCCCC', borderRadius: 5 }}>
                    <RadioButton
                        value="IBC"
                        uncheckedColor='#CCCCCC'
                        color='#7fbe41'
                        status={currentContainer[i] === 'IBC' ? 'checked' : 'unchecked'}
                        onPress={() => {
                            currentContainer[i] = 'IBC'
                            this.setState({ checked: currentContainer })
                        }}
                    />
                    <Text onPress={() => {
                        currentContainer[i] = 'IBC'
                        this.setState({ checked: currentContainer })
                    }}>IBC Bulk</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: '#CCCCCC', borderRadius: 5 }}>

                    <RadioButton
                        value="Bowser"
                        uncheckedColor='#CCCCCC'
                        color='#7fbe41'
                        status={currentContainer[i] === 'Bowser' ? 'checked' : 'unchecked'}
                        onPress={() => {
                            currentContainer[i] = 'Bowser'
                            this.setState({ checked: currentContainer })
                        }}
                    />
                    <Text onPress={() => {
                        currentContainer[i] = 'Bowser'
                        this.setState({ checked: currentContainer })
                    }}>Bowser</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: '#CCCCCC', borderRadius: 5 }}>
                    <RadioButton
                        value="Other"
                        uncheckedColor='#CCCCCC'
                        color='#7fbe41'
                        status={currentContainer[i] === 'Other' ? 'checked' : 'unchecked'}
                        onPress={() => {
                            currentContainer[i] = 'Other'
                            this.setState({ checked: currentContainer })
                        }}
                    />
                    <Text onPress={() => {
                        currentContainer[i] = 'Other'
                        this.setState({ checked: currentContainer })
                    }}>Other</Text>
                </View>
            </View>
        )
    }
    showRadioLabel(i) {
        var fs = this.state.checked
        if (fs[i] != '') {
            switch (fs[i]) {
                case ('Barrel'):
                    return (
                        <TextInput key={"containerText" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: 100, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, padding: 14, marginRight: 15, marginLeft: 15, marginVertical: 2 }}
                            placeholder="Barrel code"
                            placeholderTextColor="#60605e"
                            onChangeText={containerNo => this.updateContainerValues(i, containerNo, this.state.checked)}
                            value={this.state.containerTypes[i][1]}
                        />
                    )
                case ('IBC'):
                    return (
                        <TextInput key={"containerText" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: 100, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, padding: 14, marginRight: 15, marginLeft: 15, marginVertical: 2 }}
                            placeholder="IBC code"
                            placeholderTextColor="#60605e"
                            onChangeText={containerNo => this.updateContainerValues(i, containerNo, this.state.checked)}
                            value={this.state.containerTypes[i][1]}
                        />
                    )
                case ('Bowser'):
                    return (
                        <TextInput key={"containerText" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: 100, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, padding: 14, marginRight: 15, marginLeft: 15, marginVertical: 2 }}
                            placeholder="Bowser code"
                            placeholderTextColor="#60605e"
                            onChangeText={containerNo => this.updateContainerValues(i, containerNo, this.state.checked)}
                            value={this.state.containerTypes[i][1]}
                        />
                    )
                case ('Other'):
                    return (
                        <TextInput key={"containerText" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: 100, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, padding: 14, marginRight: 15, marginLeft: 15, marginVertical: 2 }}
                            placeholder="Other code"
                            placeholderTextColor="#60605e"
                            onChangeText={containerNo => this.updateContainerValues(i, containerNo, this.state.checked)}
                            value={this.state.containerTypes[i][1]}
                        />
                    )
            }
        }
    }
    ios_getHeight(i) {
        if (Platform.OS == 'ios') {
            return (i > 0 ? { marginTop: 50 } : {})
        }
    }
    getBatchButtons(i) {
        console.log(this.state.batchCodes.length)
        console.log(i, (this.state.batchCodes.length - 1 == i && i != 0))
        if (this.state.batchCodes.length - 1 == i && i != 0) {
            return (
                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                    <TouchableOpacity style={[styles.button_alt, { width: '40%', marginVertical: '4%', marginLeft: '5%' }]} onPress={() => this.removeBatch()}>
                        <Text style={styles.buttonTitle}>Remove batch</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button_alt, { width: '40%', marginVertical: '4%', marginLeft: 0 }]} onPress={() => this.addBatch()}>
                        <Text style={styles.buttonTitle}>+ new batch</Text>
                    </TouchableOpacity>
                </View>)
        }
        return (
            <TouchableOpacity style={[styles.button_alt, { width: '40%', marginVertical: '4%', marginLeft: '30%' }]} onPress={() => this.addBatch()}>
                <Text style={styles.buttonTitle}>+ new batch</Text>
            </TouchableOpacity>
        )
    }
    render() {
        const { hasCameraPermission, scanned } = this.state;
        if (hasCameraPermission == false) {
            this.setState({ manualInput: true })
        }
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }

        else if (this.state.manualInput === true) {
            return (
                <View style={[styles.container]}>
                    <Portal>
                        <Modal visible={this.state.removeVisible} onDismiss={this.hideConfirmRemove}>
                            {this.renderConfirmModal()}
                        </Modal>
                    </Portal>
                    <View style={{ flex: 1, flexDirection: 'row', marginVertical: 15, alignContent: 'space-between' }}>
                        <Text style={[{ fontSize: 28, marginRight: 50 }]}>{this.state.productsAssigned.MainProduct != 'empty' ? this.state.productsAssigned.MainProduct : ''}</Text>
                        <Text style={[{ fontSize: 24, marginRight: 50 }]}>Tank #{this.state.tankNo}</Text>
                        <Button onPress={this.showConfirmRemove} mode='contained' color='#345DAE' style={{}}>Skip</Button>
                    </View>
                    <ScrollView style={{ flex: 1, width: '100%' }}>
                        <View style={{ flex: 1, flexDirection: 'column' }}>
                            {this.state.productsAssigned.hasOwnProperty('Products') &&
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>Expected Product</DataTable.Title>
                                        <DataTable.Title>Amount (kg)</DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        this.state.productsAssigned.Products.map((item, index) => {
                                            if (item != 'No Product' && this.state.productsAssigned.Amounts[index] > 0) {
                                                return (
                                                    <DataTable.Row key={index}>
                                                        <DataTable.Cell>
                                                            {item}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {this.state.productsAssigned.Amounts[index]} Kg
                                                    </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            }
                                        })
                                    }
                                </DataTable>
                            }
                            <View>

                                {
                                    this.state.batchCodes.map((item, i) => {
                                        return (

                                            <View key={"batchView" + i} style={[{ flex: 1, flexDirection: 'column', width: '100%', marginTop: 20, borderColor: '#000000', borderWidth: 3 }, this.ios_getHeight(i)]}>
                                                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                    {
                                                        !this.state.hiddenSwitch &&
                                                        <TouchableOpacity style={[styles.button, { width: '40%', marginVertical: '4%', marginLeft: '30%' }]} onPress={() => this.setState({ manualInput: false })}>
                                                            <Text style={styles.buttonTitle}>Scan batch</Text>
                                                        </TouchableOpacity>
                                                    }
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', height: 50, marginBottom: 50 }]}>
                                                    <View style={[{ flex: 1, flexDirection: 'column', width: '30%', alignContent: 'center', alignItems: 'center' }]}>
                                                        {this.getDropdownOSDependant(i)}
                                                    </View>
                                                    <View style={[{ flex: 1, flexDirection: 'column', width: '30%', alignContent: 'center', alignItems: 'center' }]}>
                                                        <TextInput key={"batchText" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                                            style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 14 }}
                                                            placeholder="Batch Code"
                                                            placeholderTextColor="#60605e"
                                                            value={this.state.batchCodes[i]}
                                                            onChangeText={batchNo => this.updateBatchNo(i, batchNo)}
                                                        />
                                                    </View>
                                                    <View style={[{ flex: 1, flexDirection: 'column', width: '30%', alignContent: 'center', alignItems: 'center' }]}>
                                                        <TextInput key={"batchAmt" + i} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                                            style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 14 }}
                                                            placeholder="Kg"
                                                            placeholderTextColor="#60605e"
                                                            keyboardType={'numeric'}
                                                            value={this.state.batchAmounts[i]}
                                                            onChangeText={batchAmt => this.updateBatchAmtManual(i, batchAmt)}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 80, paddingTop: 20, }]}>
                                                    {
                                                        this.getRadioGroup(i)
                                                    }
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 80, paddingTop: 10, paddingBottom: 15 }]}>
                                                    {
                                                        this.showRadioLabel(i)
                                                    }
                                                </View>
                                                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                    {this.getBatchButtons(i)}
                                                </View>
                                            </View>)
                                    })
                                }
                            </View>
                            <View style={{ width: '100%', flex: 1, flexDirection: 'row', marginTop: 15 }}>
                                {
                                    this.state.tankNo != 1 &&
                                    <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                        <TouchableOpacity style={styles.button} onPress={this.goBack}>
                                            <Text style={styles.buttonTitle}>Back</Text>
                                        </TouchableOpacity>
                                    </View>
                                }
                                {
                                    this.state.tankNo == 1 &&
                                    <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                        <TouchableOpacity style={[styles.button, { backgroundColor: '#9b9e9c' }]} onPress={this.handleBackPress}>
                                            <Text style={styles.buttonTitle}>Back</Text>
                                        </TouchableOpacity>
                                    </View>
                                }

                                <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                    <TouchableOpacity style={styles.button} onPress={this.handleFormComplete}>
                                        <Text style={styles.buttonTitle}>Next</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            )
        }
        return (
            <View style={[styles.container]}>
                <Portal>
                    <Modal visible={this.state.removeVisible} onDismiss={this.hideConfirmRemove}>
                        {this.renderConfirmModal()}
                    </Modal>
                </Portal>
                <View style={{ flex: 1, flexDirection: 'row', marginVertical: 15, alignContent: 'space-between' }}>
                    <Text style={[{ fontSize: 28, marginRight: 50 }]}>{this.state.productsAssigned.MainProduct != 'empty' ? this.state.productsAssigned.MainProduct : ''}</Text>
                    <Text style={[{ fontSize: 24, marginRight: 50 }]}>Tank #{this.state.tankNo}</Text>
                    <Button onPress={this.showConfirmRemove} mode='contained' color='#345DAE' style={{}}>Skip</Button>
                </View>
                <ScrollView style={{ flex: 1, width: '100%' }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        {this.state.productsAssigned.hasOwnProperty('Products') &&
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Expected Product</DataTable.Title>
                                    <DataTable.Title>Amount (kg)</DataTable.Title>
                                </DataTable.Header>
                                {
                                    this.state.productsAssigned.Products.map((item, index) => {
                                        if (item != 'No Product' && this.state.productsAssigned.Amounts[index] > 0) {
                                            return (
                                                <DataTable.Row key={index}>
                                                    <DataTable.Cell>
                                                        {item}
                                                    </DataTable.Cell>
                                                    <DataTable.Cell>
                                                        {this.state.productsAssigned.Amounts[index]} Kg
                                                </DataTable.Cell>
                                                </DataTable.Row>
                                            )
                                        }
                                    })
                                }
                            </DataTable>
                        }
                        <View>

                            {
                                this.state.batchCodes.map((item, i) => {
                                    if (item != undefined) {
                                        return (
                                            <View key={"batchView" + i} style={[{ flex: 1, flexDirection: 'column', width: '100%', marginTop: 20, borderColor: '#000000', borderWidth: 3 }, this.ios_getHeight(i)]}>
                                                <Portal>
                                                    <Modal visible={this.state.visible} onDismiss={() => { this.hideModal() }}>
                                                        {this.barcodeModal(i)}
                                                    </Modal>
                                                </Portal>

                                                <View style={[{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', height: 50, marginBottom: 10, marginTop: 10 }]}>
                                                    <TouchableOpacity style={[styles.button, { width: '40%', marginVertical: '4%', marginLeft: 20, marginRight: 20 }]} onPress={() => this.openAndSetState(i)}>
                                                        <Text style={styles.buttonTitle}>Scan batchcode</Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity style={[styles.button, { width: '40%', marginVertical: '4%', marginLeft: 20, marginRight: 20 }]} onPress={() => this.setState({ manualInput: true })}>
                                                        <Text style={styles.buttonTitle}>Manual input</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', alignItems: 'center', height: 50, marginBottom: 10 }]}>
                                                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                                        style={{ width: '30%', marginVertical: '4%', marginLeft: 20, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, height: 50, padding: 10 }}
                                                        mode='flat'
                                                        value={this.state.batchProducts[i] ? this.state.batchProducts[i] : ''}
                                                        placeholder="Batch product"
                                                        placeholderTextColor="#60605e"
                                                    />
                                                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                                        style={{ width: '30%', marginVertical: '4%', marginLeft: 10, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, height: 50, padding: 10 }}
                                                        mode='flat'
                                                        value={this.state.batchCodes[i] ? this.state.batchCodes[i] : ''}
                                                        placeholder="Batch code"
                                                        placeholderTextColor="#60605e"
                                                    />
                                                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                                        style={{ width: '25%', marginVertical: '4%', marginLeft: 10, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, height: 50, padding: 10 }}
                                                        mode='flat'
                                                        value={this.state.batchAmounts[i] ? this.state.batchAmounts[i] : ''}
                                                        onChangeText={batchAmt => this.updateBatchAmt(i, batchAmt)}
                                                        placeholder="Kg"
                                                        keyboardType={'numeric'}
                                                        placeholderTextColor="#60605e"
                                                    />
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 80, paddingTop: 20, }]}>
                                                    {
                                                        this.getRadioGroup(i)
                                                    }
                                                </View>
                                                <View style={[{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 80, paddingTop: 10, paddingBottom: 10 }]}>
                                                    {
                                                        this.showRadioLabel(i)
                                                    }
                                                </View>
                                                <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                    {this.getBatchButtons(i)}
                                                </View>

                                            </View>)
                                    }
                                }
                                )
                            }
                        </View>
                        <View style={{ width: '100%', flex: 1, flexDirection: 'row', marginTop: 15 }}>
                            {
                                this.state.tankNo != 1 &&
                                <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                    <TouchableOpacity style={styles.button} onPress={this.goBack}>
                                        <Text style={styles.buttonTitle}>Back</Text>
                                    </TouchableOpacity>
                                </View>
                            }
                            {
                                this.state.tankNo == 1 &&
                                <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                    <TouchableOpacity style={[styles.button, { backgroundColor: '#9b9e9c' }]} onPress={this.handleBackPress}>
                                        <Text style={styles.buttonTitle}>Back</Text>
                                    </TouchableOpacity>
                                </View>
                            }

                            <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                <TouchableOpacity style={styles.button} onPress={this.handleFormComplete}>
                                    <Text style={styles.buttonTitle}>Next</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}