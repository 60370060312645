import 'react-native-gesture-handler';

import {
  AddDeliveryScreen,
  AddReadingScreen,
  AdminChecklistScreen,
  AdminDeliveriesScreen,
  AdminEmptyDatesScreen,
  AdminExtraFunctionsScreen,
  AdminExtraFunctionsSearchScreen,
  AdminManagementHomeScreen,
  AdminManagementScreen,
  AdminUserManagementScreen,
  AssignRoutesScreen,
  CustomQueriesScreen,
  DeliveryDetailsScreen,
  DeviceChecklistScreen,
  DosageCalcScreen,
  FarmDetailsScreen,
  FarmListScreen,
  HomeScreenScreen,
  LoginScreen,
  RegistrationScreen,
  RouteManagementScreen,
  SearchFarmsScreen,
  SearchViewIssuesScreen,
  ViewDeliveryScreen,
  ViewVisitsScreen
} from './src/screens'
import React, {useEffect, useState} from 'react'
import { decode, encode } from 'base-64'

import ActionBarLogo from './src/screens/_shared/ActionBarLogo';
import ActionBarLogoNoLink from './src/screens/_shared/ActionBarLogoNoLink'
import { NavigationContainer } from '@react-navigation/native'
import { Provider as PaperProvider } from 'react-native-paper'
import { createStackNavigator } from '@react-navigation/stack'
import timeoutFix from './src/firebase/timeoutFix'
import { Button } from 'react-native';

if (!global.btoa) { global.btoa = encode }
if (!global.atob) { global.atob = decode }

const Stack = createStackNavigator();

export default function App() {

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)


  return (
      <NavigationContainer>
      <PaperProvider>
        <Stack.Navigator>
          {user ? (
            <Stack.Screen name="Home">
              {props => <HomeScreen {...props} extraData={user} />}
            </Stack.Screen>
          ) : (
              <>
                <Stack.Screen name="Login"
                  component={LoginScreen}
                  options={{
                    headerTitle: props => <ActionBarLogoNoLink {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Registration" component={RegistrationScreen}
                  options={{
                    headerTitle: props => <ActionBarLogoNoLink {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Home" component={HomeScreenScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Search Farms" component={SearchFarmsScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                    // headerRight: () => (
                    //         <Button
                    //           onPress={() => alert('This is a button!')}
                    //           title={partner}
                    //           color="#000"
                    //         /> ),
                  }}
                />
                <Stack.Screen name="Farms" component={FarmListScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Farm Details" component={FarmDetailsScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Delivery Details" component={DeliveryDetailsScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="View Delivery" component={ViewDeliveryScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Add Delivery" component={AddDeliveryScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Admin Management Home" component={AdminManagementHomeScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Admin Management" component={AdminManagementScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="View Issues" component={SearchViewIssuesScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Add Reading" component={AddReadingScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Route Management" component={RouteManagementScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="Assign Routes" component={AssignRoutesScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Admin Extra Functions" component={AdminExtraFunctionsScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Admin Extra Functions Search" component={AdminExtraFunctionsSearchScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Dosage Calc" component={DosageCalcScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Device checklist" component={DeviceChecklistScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Admin Checklist" component={AdminChecklistScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="View Visits" component={ViewVisitsScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Admin deliveries" component={AdminDeliveriesScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Empty dates" component={AdminEmptyDatesScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                  <Stack.Screen name="Custom queries" component={CustomQueriesScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
                <Stack.Screen name="User Management" component={AdminUserManagementScreen}
                  options={{
                    headerTitle: props => <ActionBarLogo {...props} />,
                    headerTitleAlign: 'center',
                  }} />
              </>
            )}
        </Stack.Navigator>
    </PaperProvider>
      </NavigationContainer>
  );
}
