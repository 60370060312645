import '../../globals.js'

import * as Location from 'expo-location';

import { ActivityIndicator, Platform, TouchableOpacity, View } from 'react-native'
import { Caption, DataTable, Modal, Portal, Snackbar, Text, TextInput, Title } from 'react-native-paper';
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'
import { getDataLocal, setDataLocal } from '../_shared/ERP_Local'

import { Entypo } from '@expo/vector-icons';
import ExtraDeliveryComponent from './ExtraDeliveryComponent'
import FarmIssues from '../_shared/FarmIssues.js';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import TankDeliveryComponent from './TankDeliveryComponent'
import { captureScreen } from 'react-native-view-shot';
import styles from '../../styles';

export default class AddDelivery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, displayed: 0, tankNo: 0, tank1: {}, tank2: {}, tank3: {}, tank4: {}, tank5: {}, tank6: {},
            tank1Details: {}, tank2Details: {}, tank3Details: {}, tank4Details: {}, tank5Details: {}, tank6Details: {},
            tank1ProductsAssigned: {}, tank2ProductsAssigned: {}, tank3ProductsAssigned: {}, tank4ProductsAssigned: {}, tank5ProductsAssigned: {}, tank6ProductsAssigned: {},
            userFeedback: '', farmIssueTextArea: '', miscDelivery: '', feedbackFocused: false, miscFocused: false, levelBefore: [], levelAfter: [-1, -1, -1, -1, -1, -1], tanksEnabled: [true, false, false, false, false, false, false, true],
            extraProduct: 'Not available', tankProductType: [], toastVisible: false, screenshot: {}
        };
    }
    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({
                isLoading: true, displayed: 0, tankNo: 0, tank1: {}, tank2: {}, tank3: {}, tank4: {}, tank5: {}, tank6: {},
                tank1Details: {}, tank2Details: {}, tank3Details: {}, tank4Details: {}, tank5Details: {}, tank6Details: {},
                tank1ProductsAssigned: {}, tank2ProductsAssigned: {}, tank3ProductsAssigned: {}, tank4ProductsAssigned: {}, tank5ProductsAssigned: {}, tank6ProductsAssigned: {},
                userFeedback: '', farmIssueTextArea: '', miscDelivery: '', feedbackFocused: false, miscFocused: false, levelBefore: [], levelAfter: [-1, -1, -1, -1, -1, -1], tanksEnabled: [true, false, false, false, false, false, false, true],
                tanks: this.props.route.params.tanks, extraProduct: 'Not available', tankProductType: [], toastVisible: false, screenshot: {}
            })
            this.getData()
        })
    }
    async getData() {
        this.getIssues()
        this.getOfflineTankCapacity()
        this.checkIfOnlineDevice()
        await this.getTankProducts()
        await this.getProductsAssigned()
        await this.getProductsList()

        this.setState(
            {
                isLoading: false
            })
    }
    async getProductsList() {
        var productRequestBody = {
            context: 'products',
            Id: this.props.route.params.Id
        }
        var productsList = await getDataAPI(productRequestBody)
        if (productsList != null && productsList != undefined) {
            console.log(productsList)
            this.setState(
                {
                    productList: productsList
                })
        }
    }
    async getProductsAssigned() {
        var requestBody = {
            context: 'getProductsAssigned',
            FarmId: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        if (data.length > 0 & data[0][0] != null) {
            data = JSON.parse(data)
        }


        var empty = { MainProduct: 'empty', Products: [], Amounts: [] }
        if (data.hasOwnProperty('TANK1')) {
            this.setState({
                farmProductsAssigned: data,
                tank1ProductsAssigned: data['TANK1'],
                tank2ProductsAssigned: data['TANK2'],
                tank3ProductsAssigned: data['TANK3'],
                tank4ProductsAssigned: data['TANK4'],
                tank5ProductsAssigned: data['TANK5'],
                tank6ProductsAssigned: data['TANK6']
            })
        }
        else if (data[0].hasOwnProperty('MainProduct')) {
            this.setState({
                farmProductsAssigned: data,
                tank1ProductsAssigned: data[0],
                tank2ProductsAssigned: data[1],
                tank3ProductsAssigned: data[2],
                tank4ProductsAssigned: data[3],
                tank5ProductsAssigned: data[4],
                tank6ProductsAssigned: data[5]
            })
        }
        else {
            this.setState({
                farmProductsAssigned: { TANK1: empty, TANK2: empty, TANK3: empty, TANK4: empty, TANK5: empty, TANK6: empty },
                tank1ProductsAssigned: empty,
                tank2ProductsAssigned: empty,
                tank3ProductsAssigned: empty,
                tank4ProductsAssigned: empty,
                tank5ProductsAssigned: empty,
                tank6ProductsAssigned: empty
            })
        }
    }
    async getIssues() {
        var requestBody = {
            context: 'farmIssues',
            Id: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            farmIssues: data
        })
    }

    getTankNumberNav(tankNumTO, dir) {
        let originalT = tankNumTO
        let t = tankNumTO
        let tEnabled = this.state.tanksEnabled
        if (dir == 'next') {
            while (!tEnabled[t]) {
                if (t > 0 && t < 7) {
                    console.log("Skipping tank: " + t)
                    var orderObj = {
                        details: {
                            levelBefore: '',
                            levelAfter: '',
                            Batch: '',
                            tankNo: t,
                            containers: ''
                        },
                        products: this.getProductsAssignedToTank(t),
                        antiRecursion: true
                    }
                    this.setTankDetails(orderObj)
                }

                t++
            }
        }
        else if (dir == 'prev') {
            while (!tEnabled[t]) {
                t--
                if (t < 0) {
                    t = originalT + 1
                    break;
                }
            }
        }
        return t
    }
    previousTank = (value) => {
        this.setState({ tankNo: this.getTankNumberNav(value, 'prev') })
    }
    setTankDetails = (value) => {
        switch (value.details.tankNo) {
            case 1:
                this.setState({
                    tank1Details: value.details,
                    tank1ProductsAssigned: value.products,
                    tank1: value,
                    tankNo: value.antiRecursion == true ? this.state.tankNo : this.getTankNumberNav(2, 'next')
                })
                break;
            case 2:
                this.setState({
                    tank2Details: value.details,
                    tank2ProductsAssigned: value.products,
                    tank2: value,
                    tankNo: value.antiRecursion == true ? this.state.tankNo : this.getTankNumberNav(3, 'next')
                })
                break;
            case 3:
                this.setState({
                    tank3Details: value.details,
                    tank3ProductsAssigned: value.products,
                    tank3: value,
                    tankNo: value.antiRecursion == true ? this.state.tankNo : this.getTankNumberNav(4, 'next')
                })
                break;
            case 4:
                this.setState({
                    tank4Details: value.details,
                    tank4ProductsAssigned: value.products,
                    tank4: value,
                    tankNo: value.antiRecursion == true ? this.state.tankNo : this.getTankNumberNav(5, 'next')
                })
                break;
            case 5:
                this.setState({
                    tank5Details: value.details,
                    tank5ProductsAssigned: value.products,
                    tank5: value,
                    tankNo: value.antiRecursion == true ? this.state.tankNo : this.getTankNumberNav(6, 'next')
                })
                break;
            case 6:
                this.setState({
                    tank6Details: value.details,
                    tank6ProductsAssigned: value.products,
                    tankNo: 7,
                    tank6: value
                })
                break;
            case 8:
                this.setState({
                    extraDetails: value.details,
                    tankNo: 9,
                    extra: value,
                    isLoading: false
                })
                break;
        }
    }


    async saveIssue() {
        await this.setState({ farmIssueFlag: (this.state.farmIssueTextArea.length > 0 ? true : false) })
        var requestBody = {
            context: 'farmIssues',
            IssueText: this.state.farmIssueTextArea,
            CreatedBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName),
            FarmId: this.props.route.params.Id
        }
        var data = await setDataAPI(requestBody)
        this.setState({
            isLoading: false,
            returnMsg: data
        })
    }
    async saveProductsAssigned() {
        var allProductsAssigned = {
            TANK1: this.state.tank1ProductsAssigned,
            TANK2: this.state.tank2ProductsAssigned,
            TANK3: this.state.tank3ProductsAssigned,
            TANK4: this.state.tank4ProductsAssigned,
            TANK5: this.state.tank5ProductsAssigned,
            TANK6: this.state.tank6ProductsAssigned,
        }
        var requestBody = {
            context: 'setProductsAssigned',
            FarmId: this.props.route.params.Id,
            Data: JSON.stringify(allProductsAssigned)
        }
        var data = await setDataAPI(requestBody)
    }

    async addReading() {
        var requestBody = {
            context: 'addReading',
            DeviceId: this.props.route.params.DeviceId,
            DeliveredBy: ('Auto reading.'),
            Tank1: this.state.tank1Details.levelAfter,
            Tank2: this.state.tank2Details.levelAfter,
            Tank3: this.state.tank3Details.levelAfter,
            Tank4: this.state.tank4Details.levelAfter,
            Tank5: this.state.tank5Details.levelAfter,
            Tank6: this.state.tank6Details.levelAfter,
        }
        var data = await setDataAPI(requestBody)
    }

    async checkIssues() {
        if (this.state.farmIssueTextArea == '') {
            return
        }
        else {
            await this.saveIssue()
        }
    }
    async logAttempt() {

        var requestBody = {
            context: 'logDelivery',
            user: (this.props.route.params.user.firstName + " " + this.props.route.params.user.secondName),
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await setDataAPI(requestBody)
    }
    async addLevelAfterToObj() {
        let tank1Obj = this.state.tank1Details
        tank1Obj.levelAfter = this.state.levelAfter[0]

        let tank2Obj = this.state.tank2Details
        tank2Obj.levelAfter = this.state.levelAfter[1]

        let tank3Obj = this.state.tank3Details
        tank3Obj.levelAfter = this.state.levelAfter[2]

        let tank4Obj = this.state.tank4Details
        tank4Obj.levelAfter = this.state.levelAfter[3]

        let tank5Obj = this.state.tank5Details
        tank5Obj.levelAfter = this.state.levelAfter[4]

        let tank6Obj = this.state.tank6Details
        tank6Obj.levelAfter = this.state.levelAfter[5]

        console.log("##################### TANK1DETAILS: ", tank1Obj)

        this.setState({
            tank1Details: tank1Obj,
            tank2Details: tank2Obj,
            tank3Details: tank3Obj,
            tank4Details: tank4Obj,
            tank5Details: tank5Obj,
            tank6Details: tank6Obj
        })
    }
    async saveDelivery() {
        this.setState(
            {
                isPressed: true
            })
        await this.logAttempt()
        var requestBody = {
            context: 'deliveryDetails',
            UserFeedback: this.state.userFeedback != undefined ? this.state.userFeedback.replace(/'/g, "''") : 'No Feedback',
            DeliveredBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName),
            Tank1: this.state.tank1Details != undefined ? JSON.stringify(this.state.tank1Details) : 'No Data',
            Tank2: this.state.tank2Details != undefined ? JSON.stringify(this.state.tank2Details) : 'No Data',
            Tank3: this.state.tank3Details != undefined ? JSON.stringify(this.state.tank3Details) : 'No Data',
            Tank4: this.state.tank4Details != undefined ? JSON.stringify(this.state.tank4Details) : 'No Data',
            Tank5: this.state.tank5Details != undefined ? JSON.stringify(this.state.tank5Details) : 'No Data',
            Tank6: this.state.tank6Details != undefined ? JSON.stringify(this.state.tank6Details) : 'No Data',
            IssueFlag: this.state.farmIssueFlag != undefined ? this.state.farmIssueFlag : 'No Issue',
            FarmId: this.props.route.params.Id,
            miscDelivery: this.state.miscDelivery != undefined ? JSON.stringify(this.state.miscDelivery) : '',
            submissionTime: new Date().toLocaleString(),
            extraProduct: this.state.extraDetails != undefined ? JSON.stringify(this.state.extraDetails) : 'No Data'
        }

        await this.checkIssues()
        // await this.getLocationAndMap()
        // await this.sendEmail()

        var data = await setDataAPI(requestBody)
        if (data[0] != 'success') {
            let farmId = this.props.route.params.Id
            let retryIdList = await getDataLocal("retryIdList")
            console.log(retryIdList)
            if (retryIdList == undefined || retryIdList == null) {
                let body = {
                    "list": [
                        String(farmId)
                    ]
                }
                let r = await setDataLocal("retryIdList", body)
                if (r != "success") {
                    console.log("error saving new id list")
                }
            } else {
                let match = false
                for (let i = 0; i < retryIdList["list"].length; i++) {
                    if (retryIdList["list"][i] == String(farmId)) {
                        match = true
                        console.log(String(farmId) + " already waiting to push delivery")
                    }
                }
                if (!match) {
                    retryIdList["list"][retryIdList["list"].length] = String(farmId)
                    let r = await setDataLocal("retryIdList", retryIdList)
                    if (r != "success") {
                        console.log("error saving to existing id list")
                    }
                }
            }
            let check = await getDataLocal("failedDelivery" + String(farmId))
            if (check == undefined || check == null) {
                let result = await setDataLocal("failedDelivery" + String(farmId), requestBody)
                console.log(result)
                this.props.navigation.navigate('Farm Details', {
                    Id: this.props.route.params.Id,
                    user: this.props.route.params.user,
                    DeliveryMessage: 'error'
                })
            }
        }

        if (data[0] == 'success') {

            await this.addReading()
            this.setState(
                {
                    isPressed: false,
                    returnMsg: data
                })
            this.props.navigation.navigate('Farm Details', {
                Id: this.props.route.params.Id,
                user: this.props.route.params.user,
                DeliveryMessage: this.state.returnMsg == 'success' ? this.state.returnMsg : ''
            })
        }
        else {
            this.setState({ isPressed: false })
            alert('Delivery failed, please retry with internet connection.')
        }
    }

    async getLocationAndMap() {
        let { status } = await Location.requestPermissionsAsync();
        if (status !== 'granted') {
            setErrorMsg('Permission to access location was denied');
            alert("Permission for location denied.")
            var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found
            this.setState({ imageURL: url })
        }
        else {
            try {
                var currLocation = await Location.getCurrentPositionAsync({ enableHighAccuracy: true, accuracy: Location.Accuracy.Highest });
                if (currLocation.coords.latitude != null && currLocation.coords.longitude != null || currLocation == undefined) {
                    var latitude = currLocation.coords.latitude
                    var longitude = currLocation.coords.longitude
                    var url = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=400x400&markers=color:blue%7Clabel:Delivery%7C${latitude},${longitude}&key=${global.Apikey}`

                    this.setState({ imageURL: url })
                }
                else {
                    var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                    this.setState({ imageURL: url })
                }
            } catch (error) {
                console.log(error)
                var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                this.setState({ imageURL: url })
            }
        }
    }

    async checkDeliveryNumber() {
        var requestBody = {
            context: 'checkDeliveryNumber'
        }
        var data = await getDataAPI(requestBody)
        return data
    }

    async emailQuantities() {
        var emailBody = ``
        for (let i = 0; i < 6; i++) {
            switch (i) {
                case 0:
                    if (this.state.tank1Details != undefined) {
                        console.log("1", this.state.tank1Details)
                        for (let j = 0; j < this.state.tank1Details.Batch.length; j++) {
                            if (this.state.tank1Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank1Details['tankNo']}<br>
                                                                        Product: ${this.state.tank1Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank1Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank1Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank1Details.containers[j][0]}<br>
                                                                        ${this.state.tank1Details.containers[j][0]} #: ${this.state.tank1Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 1:
                    if (this.state.tank2Details != undefined) {
                        console.log("2", this.state.tank2Details)
                        for (let j = 0; j < this.state.tank2Details.Batch.length; j++) {
                            if (this.state.tank2Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank2Details['tankNo']}<br>
                                                                        Product: ${this.state.tank2Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank2Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank2Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank2Details.containers[j][0]}<br>
                                                                        ${this.state.tank2Details.containers[j][0]} #: ${this.state.tank2Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 2:
                    if (this.state.tank3Details != undefined) {
                        console.log("3", this.state.tank3Details)
                        for (let j = 0; j < this.state.tank3Details.Batch.length; j++) {
                            if (this.state.tank3Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank3Details['tankNo']}<br>
                                                                        Product: ${this.state.tank3Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank3Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank3Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank3Details.containers[j][0]}<br>
                                                                        ${this.state.tank3Details.containers[j][0]} #: ${this.state.tank3Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 3:
                    if (this.state.tank4Details != undefined) {
                        console.log("4", this.state.tank4Details.Batch)
                        for (let j = 0; j < this.state.tank4Details.Batch.length; j++) {
                            if (this.state.tank4Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank4Details['tankNo']}<br>
                                                                        Product: ${this.state.tank4Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank4Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank4Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank4Details.containers[j][0]}<br>
                                                                        ${this.state.tank4Details.containers[j][0]} #: ${this.state.tank4Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 4:
                    if (this.state.tank5Details != undefined) {
                        console.log("5", this.state.tank5Details.Batch)
                        for (let j = 0; j < this.state.tank5Details.Batch.length; j++) {
                            if (this.state.tank5Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank5Details['tankNo']}<br>
                                                                        Product: ${this.state.tank5Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank5Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank5Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank5Details.containers[j][0]}<br>
                                                                        ${this.state.tank5Details.containers[j][0]} #: ${this.state.tank5Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 5:
                    if (this.state.tank6Details != undefined) {
                        console.log("6", this.state.tank6Details.Batch)
                        for (let j = 0; j < this.state.tank6Details.Batch.length; j++) {
                            if (this.state.tank6Details['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${this.state.tank6Details['tankNo']}<br>
                                                                        Product: ${this.state.tank6Details.Batch[j][3]}<br>
                                                                        Batch: ${this.state.tank6Details.Batch[j][1]}<br>
                                                                        Quantity: ${this.state.tank6Details.Batch[j][2]}kg<br>
                                                                        Delivery method: ${this.state.tank6Details.containers[j][0]}<br>
                                                                        ${this.state.tank6Details.containers[j][0]} #: ${this.state.tank6Details.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
            }
        }
        console.log(emailBody)
        return emailBody
    }

    async sendEmail() {
        var deliveryNumberString = await this.checkDeliveryNumber()

        var formDate = new Date().toLocaleString()

        var deliveryNumber = parseInt(deliveryNumberString)
        deliveryNumber++

        var requestBody = {
            context: 'emailAccounts',
            Body: `<html>
            <head>
                <title></title>
                <link href="https://svc.webspellchecker.net/spellcheck31/lf/scayt3/ckscayt/css/wsc.css" rel="stylesheet" type="text/css" />
            </head>
            <body>Delivery Docket #${deliveryNumber}<br />
            Date: ${formDate}<br />
            <br />
            Customer name: ${this.props.route.params.customer}<br />
            &nbsp;
            
            &nbsp;</div>
            
            ${await this.emailQuantities()}
            
            
            <div>&nbsp;</div>
            
    
            <div>Delivered by: ${this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName}</div>
            <div>Delivery location</div>
            <img src="${this.state.imageURL}" width="450" height="300"></img>
            </body>
            </html>
            `,
            Subject: `Delivery #${deliveryNumber}`
        }
        var data = await setDataAPI(requestBody)
        return data[0]
    }
    accordian(tankNum) {
        var tanksCollapsed = this.state.isCollapsed
        tanksCollapsed[tankNum - 1] = !tanksCollapsed[tankNum - 1]
        this.setState({
            isCollapsed: tanksCollapsed
        })
    }
    getTankDisplay(tankData) {
        console.log(tankData)
        var batchData = tankData['Batch'] ? tankData['Batch'] : null
        if (batchData != null) {
            if (batchData[0]) {
                if (this.state.isCollapsed[tankData['tankNo'] - 1]) {
                    return (
                        <DataTable>
                            <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                                <DataTable.Title onPress={() => (this.accordian(tankData['tankNo']))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankData['tankNo']}</Title></DataTable.Title>
                            </DataTable.Header>
                        </DataTable>
                    )
                }
                return (
                    <DataTable>
                        <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                            <DataTable.Title onPress={() => (this.accordian(tankData['tankNo']))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankData['tankNo']}</Title></DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row key={"levelBefore" + tankData['tankNo']}>
                            <DataTable.Cell>
                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Level (before): </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{tankData['levelBefore'] > 0 ? tankData['levelBefore'] : 0}%</Text> {/*if they dont add a levelbefore on first screen and then do a delivery into the tank it records no levelBefore in the object but this shows 0%. is this ok?*/}
                            </DataTable.Cell>
                            <DataTable.Row key={"levelAfter" + tankData['tankNo']}></DataTable.Row>
                            <DataTable.Cell>
                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Level (after): </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{tankData['levelAfter'] > 0 ? tankData['levelAfter'] : 0}%</Text>
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            batchData.map((item, index) => {
                                return (
                                    <View>
                                        <DataTable.Row key={"Batch" + tankData['tankNo'] + "-" + index}>
                                            <DataTable.Cell>
                                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Product: </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{item[3]}</Text>
                                            </DataTable.Cell>
                                            <DataTable.Cell>
                                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Batch: </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{item[1]}</Text>
                                            </DataTable.Cell>
                                            <DataTable.Cell>
                                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Amount: </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{item[2] > 0 ? item[2] : 0} kg</Text>
                                            </DataTable.Cell>
                                        </DataTable.Row>
                                        <DataTable.Row key={"Container" + tankData['tankNo'] + "-" + index}>
                                            <DataTable.Cell>
                                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Container Type: </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{item[4]}</Text>
                                            </DataTable.Cell>
                                            <DataTable.Cell>
                                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>ID: </Caption><Text styles={styles.largeText} theme={{ colors: { text: 'rgb(43,43,43)' } }} >{item[5]}</Text>
                                            </DataTable.Cell>
                                        </DataTable.Row>
                                    </View>
                                )
                            })
                        }
                    </DataTable>
                )
            }
        }
    }

    getDeliverySummary() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <View>
                        <Title style={{ marginTop: 22 }} theme={{ colors: { text: 'rgb(200,43,43)' } }}>  Please check this summary for errors.</Title>
                        <DataTable style={{ marginTop: 10 }}>
                            <DataTable.Header style={{ marginTop: 0, backgroundColor: 'rgba(52,93,174,0.3)' }}>
                                <DataTable.Title style={{ marginTop: 0 }}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank Details</Title></DataTable.Title>
                            </DataTable.Header>
                        </DataTable>
                        <DataTable.Row key={"DeliveredBy"}>
                            <DataTable.Cell>
                                <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Delivered By: </Caption>{this.state.deliveredBy}
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            this.state.tanks.map(item => <View key={"tank" + item[0]}>{this.getTankDisplay(item)}</View>)
                        }
                    </View>
                </KeyboardAwareScrollView>
            </View>
        )
    }
    getProductsAssignedToTank(t) {
        switch (t) {
            case 1:
                return this.state.tank1ProductsAssigned
            case 2:
                return this.state.tank2ProductsAssigned
            case 3:
                return this.state.tank3ProductsAssigned
            case 4:
                return this.state.tank4ProductsAssigned
            case 5:
                return this.state.tank5ProductsAssigned
            case 6:
                return this.state.tank6ProductsAssigned
            default:
                return {}
                break;
        }
    }
    setLevelBefore(level, tank) {
        console.log("in setLevelBefore: ", this.state.levelBefore)
        if (tank == 0) {
            let tkEnabled = this.state.tanksEnabled
            for (let i = 0; i < this.state.levelBefore.length; i++) {
                if (this.state.levelBefore[i] >= 0) {
                    tkEnabled[i + 1] = true
                }
            }
            this.setState({ tanksEnabled: tkEnabled })
            let next = this.getTankNumberNav(1, 'next')
            this.setState({
                tankNo: next > 6 ? 0 : next
            })
            return
        }
        let allPreFillLevels = this.state.levelBefore
        let tEnabled = this.state.tanksEnabled
        tEnabled[tank] = level >= 0 ? true : false
        allPreFillLevels[tank - 1] = level
        this.setState({
            levelBefore: allPreFillLevels,
            tanksEnabled: tEnabled
        })
    }
    async setLevelAfter(level, tank) {
        if (tank == 0) {
            await this.setupSummary()
            await this.addLevelAfterToObj()
            this.setState({
                tankNo: 8
            })
            console.log("Finished doing levels after fill.")
            return
        }
        let allPostFillLevels = this.state.levelAfter
        allPostFillLevels[tank - 1] = level
        this.setState({
            levelAfter: allPostFillLevels
        })

        console.log("PostFill Volumes!!!!!", this.state.levelAfter)
    }
    getStyle() {
        if (Platform.OS == 'ios') {
            return [
                styles.subTitle_ios
            ]
        }
        return styles.subTitle
    }
    async checkIfOnlineDevice() {
        var requestBody = {
            context: 'checkIfOnline',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        console.log(data)
        if (data == 'False' || data.length == 0) {
            this.setState({
                isOnline: false
            })
            return
        }

        this.setState({
            isOnline: true
        })

    }
    async getOfflineTankCapacity() {
        var requestBody = {
            context: 'offlineTankCapacity',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        if (data === undefined || data.length == 0) {
            this.setState({
                tankCapacities: []
            });
        }
        else {
            this.setState({
                tankCapacities: data
            });
        }
    }
    getSubmitButton() {
        if (this.state.isEmptyDelivery) {
            return (
                <TouchableOpacity style={[styles.button, { backgroundColor: '#888888' }]} onPress={() => { }}>
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>{
                        'Save Delivery'
                    }</Text>
                </TouchableOpacity>)
        }
        return (
            <TouchableOpacity style={styles.button} onPress={() => { this.saveDelivery() }}>
                {
                    this.state.isPressed &&
                    <View style={{ flex: 1, padding: 20 }}>
                        <ActivityIndicator />
                    </View>
                }
                {
                    !this.state.isPressed &&
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>{
                        'Save Delivery'
                    }</Text>
                }
            </TouchableOpacity>
        )
    }
    getExpected(t) {
        //for each batch amount add them and return
        let tankExpected
        switch (t) {
            case 0:
                tankExpected = this.state.tank1.details == undefined ? { details: { Batch: [] } } : this.state.tank1
                break;
            case 1:
                tankExpected = this.state.tank2.details == undefined ? { details: { Batch: [] } } : this.state.tank2
                break;
            case 2:
                tankExpected = this.state.tank3.details == undefined ? { details: { Batch: [] } } : this.state.tank3
                break;
            case 3:
                tankExpected = this.state.tank4.details == undefined ? { details: { Batch: [] } } : this.state.tank4
                break;
            case 4:
                tankExpected = this.state.tank5.details == undefined ? { details: { Batch: [] } } : this.state.tank5
                break;
            case 5:
                tankExpected = this.state.tank6.details == undefined ? { details: { Batch: [] } } : this.state.tank6
                break;
            default:
                tankExpected = this.state.tank1.details == undefined ? { details: { Batch: [] } } : this.state.tank1
                break;
        }
        let result = 0

        for (let i = 0; i < tankExpected.details.Batch.length; i++) {
            let amts = parseInt(tankExpected.details.Batch[i][2]);
            result = result + amts
        }
        return result
    }
    getActual(t) { //offlineTankCapacity
        let capacity = this.state.tankCapacities
        if (capacity === undefined) {
            console.log("ERROR: No capacity for tank " + t)
            return -1
        }
        else if (capacity[t] !== undefined) {
            if (this.state.isOnline) {
                if (capacity[t][0] > 0) {
                    let lb = Number(this.state.levelBefore[t])
                    let la = Number(this.state.levelAfter[t])
                    let calc = capacity[t][0] * ((la - lb) / 100)
                    if (la < lb) {
                        calc = -1
                    }
                    return calc >= 0 ? calc : -1
                }
                return -1
            }
            return this.getOfflineActual(t)
        }
        return -1
    }
    getOfflineActual(t) {
        //For actual check mineral  + tankSize and use multiplier
        //tankDifferences * multiplier
        let multiplier = 1
        if (this.state.tankCapacities[t][2] == 'Minerals') {
            if (this.state.tankCapacities[t][0] == 200) {
                multiplier = 1.08
            }
            else if (this.state.tankCapacities[t][0] == 400 || this.state.tankCapacities[t][0] == 440) {
                multiplier = 2.53
            }
        }
        else if (this.state.tankCapacities[t][2] == 'Magnesium') {
            if (this.state.tankCapacities[t][0] == 200) {
                multiplier = 1.2
            }
            else if (this.state.tankCapacities[t][0] == 400 || this.state.tankCapacities[t][0] == 440 || this.state.tankCapacities[t][0] == 450) {
                multiplier = 2.75
            }
            else if (this.state.tankCapacities[t][0] == 1000) {
                multiplier = 5.3
            }
        }
        else if (this.state.tankCapacities[t][2] == 'Phosphorus') {
            if (this.state.tankCapacities[t][0] == 200) {
                multiplier = 1.09
            }
        }
        else if (this.state.tankCapacities[t][2] == 'Bloat') {
            if (this.state.tankCapacities[t][0] == 200) {
                multiplier = 1
            }
            else if (this.state.tankCapacities[t][0] == 400 || this.state.tankCapacities[t][0] == 440 || this.state.tankCapacities[t][0] == 450) {
                multiplier = 2.34
            }
        }
        else if (this.state.tankCapacities[t][2] == 'Mag plus') {
            if (this.state.tankCapacities[t][0] == 200) {
                multiplier = 1.2
            }
            else if (this.state.tankCapacities[t][0] == 400 || this.state.tankCapacities[t][0] == 440 || this.state.tankCapacities[t][0] == 450) {
                multiplier = 2.75
            }
            else if (this.state.tankCapacities[t][0] == 1000) {
                multiplier = 5.3
            }
        }

        return (this.state.levelAfter[t] - this.state.levelBefore[t]) * multiplier
    }
    async setupSummary() {

        this.setState({
            isLoading: false,
            tanks: [this.state.tank1Details, this.state.tank2Details, this.state.tank3Details,
            this.state.tank4Details, this.state.tank5Details, this.state.tank6Details],
            cached: [this.state.tank1, this.state.tank2, this.state.tank3, this.state.tank4, this.state.tank5, this.state.tank6],
            isCollapsed: [false, false, false, false, false, false],
            deliveredBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName)
        })

        let isEmpty = true
        let tempTanks = [this.state.tank1Details, this.state.tank2Details, this.state.tank3Details,
        this.state.tank4Details, this.state.tank5Details, this.state.tank6Details]
        if (tempTanks != undefined) {
            for (let i = 0; i < tempTanks.length; i++) {
                if (tempTanks[i] != undefined) {
                    if (tempTanks[i]['Batch'] != undefined) {
                        if (tempTanks[i]['Batch'].length > 0) {
                            isEmpty = false
                        }
                    }
                }
            }
        }
        this.setState({ isEmptyDelivery: isEmpty })
    }

    async getTankProducts() {
        var requestBody = {
            context: 'tankCapacity',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        console.log("getTankProducts response object: ", data)
        this.setState({ tankProductType: data })
    }

    useTankProps() {
        console.log(this.state.tanks)
        var tanks = this.state.tanks
        if (!tanks || tanks.length == 0) {
            //return the levels before stuff here
            return (
                <View style={[styles.container, { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }]}>
                    <Text style={[styles.title, { marginVertical: '2%' }]}>Levels on arrival</Text>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 1 <Text>- {this.state.tankProductType[0] ? this.state.tankProductType[0][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ flex: 1, width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 1)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 2 <Text>- {this.state.tankProductType[1] ? this.state.tankProductType[1][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 2)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 3 <Text>- {this.state.tankProductType[2] ? this.state.tankProductType[2][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 3)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 4 <Text>- {this.state.tankProductType[3] ? this.state.tankProductType[3][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 4)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 5 <Text>- {this.state.tankProductType[4] ? this.state.tankProductType[4][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 5)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                        <Text style={[this.getStyle(), { textAlign: 'left', width: '50%', marginLeft: 10 }]}>&nbsp;Tank 6 <Text>- {this.state.tankProductType[5] ? this.state.tankProductType[5][3] : 'Unknown'}:</Text>&nbsp; &nbsp;</Text>
                        <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                            style={{ width: '50%', marginRight: 50, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', flex: 1, height: 50 }}
                            placeholder="Volume %"
                            placeholderTextColor="#60605e"
                            keyboardType={'numeric'}
                            onChangeText={levelBefore => this.setLevelBefore(levelBefore, 6)}
                        />
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', justifyContent: 'center', height: 50, marginTop: 20 }}>
                        <TouchableOpacity style={{ width: '40%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center', height: 50 }}
                            onPress={() => { this.setLevelBefore(0, 0) }}>
                            <Text style={styles.buttonTitle}>{
                                'Ready!'
                            }</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }
        else {
            for (var i = 0; i < tanks.length; i++) {
                if (tanks[i] !== '') {
                    this.setLevelBefore(tanks[i], i + 1)
                }

            }
            this.setLevelBefore(0, 0)
        }

        console.log("#################################################", tanks, this.state.levelBefore)

    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    _saveScreenshotAsync = async () => {
        try {
        await captureScreen({
            format: "png",
            quality: 0.8
          }).then(
            uri => this.setState({ screenshot: uri, isLoading: true }),
            error => console.error("Oops, snapshot failed", error)
          )
          
        } catch (e) {
          console.log("ERROR: ",e)
        }

        
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        switch (this.state.tankNo) {
            case 0:
                if (this.state.tanks == undefined) {
                    return (
                        <View>
                            {this.useTankProps()}

                            <Snackbar
                                visible={this.state.toastVisible}
                                onDismiss={() => this.setState({ toastVisible: false })}
                                action={{
                                    label: 'Close',
                                    onPress: () => {
                                    },
                                }}
                                style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                            >
                                <Text>{this.state.toastBody}</Text>
                            </Snackbar>
                        </View>
                    )
                }
                //function to set levels before from the props: tanks
                { this.useTankProps() }
                return (
                    <View style={[styles.container, { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }]}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[styles.title, { marginVertical: '2%', marginTop: '40%' }]}>Adding current levels to delivery...</Text>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 1:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">
                            <TankDeliveryComponent key='Tank1' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank1ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank1} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 2:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">

                            <TankDeliveryComponent key='Tank2' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank2ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank2} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 3:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">

                            <TankDeliveryComponent key='Tank3' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank3ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank3} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 4:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">

                            <TankDeliveryComponent key='Tank4' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank4ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank4} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 5:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">

                            <TankDeliveryComponent key='Tank5' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank5ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank5} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 6:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">
                            <TankDeliveryComponent key='Tank6' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={this.state.tank6ProductsAssigned} levelBefore={this.state.levelBefore} tankData={this.state.tank6} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 7:
                return (
                    <View style={styles.container}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.title}>Levels</Text>
                        <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '5%' }]}>Tank</Text>
                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '0%' }]}>Before</Text>
                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginHorizontal: '2%' }]}>Current</Text>
                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginHorizontal: '2%' }]}>Expected</Text>
                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginHorizontal: '2%' }]}>Actual</Text>

                        </View>
                        {this.state.tanksEnabled[1] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>1</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[0] ? this.state.levelBefore[0] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 1)}
                                />
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(0).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(0) == -1 ? 'N/A' : this.getActual(0).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        {this.state.tanksEnabled[2] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>2</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[1] ? this.state.levelBefore[1] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 2)}
                                />
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(1).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(1) == -1 ? 'N/A' : this.getActual(1).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        {this.state.tanksEnabled[3] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>3</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[2] ? this.state.levelBefore[2] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 3)}
                                />
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(2).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(2) == -1 ? 'N/A' : this.getActual(2).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        {this.state.tanksEnabled[4] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>4</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[3] ? this.state.levelBefore[3] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 4)}
                                />

                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(3).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(3) == -1 ? 'N/A' : this.getActual(3).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        {this.state.tanksEnabled[5] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>5</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[4] ? this.state.levelBefore[4] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 5)}
                                />
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(4).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(4) == -1 ? 'N/A' : this.getActual(4).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        {this.state.tanksEnabled[6] &&
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignContent: 'center', height: 50, marginVertical: 5 }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, paddingTop: 0, marginLeft: '8%', marginRight: 0 }]}>6</Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '2%', marginRight: '0%' }]}>
                                    {this.state.levelBefore[5] ? this.state.levelBefore[5] + '%' : 'N/A'}
                                </Text>
                                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                    style={{ fontSize: 10, marginRight: '2%', marginLeft: '0%', width: '20%', flex: 1, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, alignSelf: 'center', height: 50 }}
                                    placeholder="%"
                                    placeholderTextColor="#60605e"
                                    keyboardType={'numeric'}
                                    onChangeText={levelAfter => this.setLevelAfter(levelAfter, 6)}
                                />
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getExpected(5).toFixed(1) + 'Kg'}
                                </Text>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[this.getStyle(), { fontSize: 15, marginLeft: '4%', marginRight: '0%' }]}>
                                    {this.getActual(5) == -1 ? 'N/A' : this.getActual(5).toFixed(1) + 'Kg'}
                                </Text>
                            </View>
                        }
                        <View style={{ flex: 1, flexDirection: 'row', width: '100%', alignContent: 'center', justifyContent: 'center', height: 50, marginTop: 20 }}>
                            <TouchableOpacity style={{ width: '40%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center', height: 50 }}
                                onPress={() => { this.setLevelAfter(0, 0) }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>{
                                    'Ready!'
                                }</Text>
                            </TouchableOpacity>
                        </View>
   
                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 8:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">
                            <ExtraDeliveryComponent key='extra' {...this.props} onFormComplete={this.setTankDetails} onBackPressed={this.previousTank} products={this.state.productList} extraData={this.state.tankNo} productsAssigned={null} levelBefore={this.state.levelBefore} tankData={this.state.extra} />
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View>
                )
            case 9:
                return (
                    <View style={styles.container}>
                        <KeyboardAwareScrollView style={{ flex: 1, width: '100%' }} keyboardShouldPersistTaps="always">
                            <View>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={{ marginTop: 15, alignSelf: 'center', marginBottom: 15, fontSize: 24 }}>View Delivery Summary</Text>
                                <FarmIssues  {...this.props} extraData={this.state.farmIssues} />
                                <TextInput
                                    style={[styles.inputContainerStyle, styles.textArea, { height: this.state.feedbackFocused ? 128 : 48 }]}
                                    label="User feedback"
                                    multiline
                                    placeholder="Any user feedback..."
                                    onFocus={() => { this.setState({ feedbackFocused: true }) }}
                                    onBlur={() => { this.setState({ feedbackFocused: false }) }}
                                    theme={{
                                        colors: {
                                            placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                            underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                                        }
                                    }}
                                    value={this.state.userFeedback}
                                    onChangeText={userFeedback => this.setState({ userFeedback })
                                    }
                                />

                                <TextInput
                                    style={[styles.inputContainerStyle, styles.textArea, { height: this.state.miscFocused ? 128 : 48 }]}
                                    label="Misc Delivery"
                                    multiline
                                    placeholder="100m 35mm pipe..."
                                    onFocus={() => { this.setState({ miscFocused: true }) }}
                                    onBlur={() => { this.setState({ miscFocused: false }) }}
                                    theme={{
                                        colors: {
                                            placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                            underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                                        }
                                    }}
                                    value={this.state.miscDelivery}
                                    onChangeText={miscDelivery => this.setState({ miscDelivery })
                                    }
                                />
                                <View>
                                    {this.getDeliverySummary()}
                                </View>
                                {this.state.isEmptyDelivery &&
                                    <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
                                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[styles.title, { backgroundColor: '#ff5555' }]}>No delivery data entered for tanks!</Text>
                                    </View>
                                }
                                <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                        <TouchableOpacity style={styles.button} onPress={() => { this.previousTank(6) }}>
                                            <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Back</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
                                        {this.getSubmitButton()}
                                    </View>
                                </View>
                            </View>
                        </KeyboardAwareScrollView>

                        <Snackbar
                            visible={this.state.toastVisible}
                            onDismiss={() => this.setState({ toastVisible: false })}
                            action={{
                                label: 'Close',
                                onPress: () => {
                                },
                            }}
                            style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                        >
                            <Text>{this.state.toastBody}</Text>
                        </Snackbar>
                    </View >
                )
        }
    }
}