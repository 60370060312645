import { Image, View } from 'react-native';

import React from 'react'

export default function ActionBarLogoNoLink()  {
      return (
        <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
          <Image
            source={require('../../../assets/splash.png')}
            style={{ width: 50, height: 50 }}
          />
        </View>
      ); 
  }