import '../../globals.js'

import { ActivityIndicator, FlatList, Text, TouchableOpacity, View } from 'react-native';

import React from 'react';
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class MyRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this.focusSubscription = this.props.navigation.addListener('focus', () => {
      this.setState({ isLoading: true })
      this.getData()
    })
  }
  async getData() {
    var requestBody = {
      context: 'routeCodes',
      user: {
        name: this.props.route.params.user.id
      }
    }
    var data = await getDataAPI(requestBody)
    if(data == 'error'){
      console.log('error occured MyRoutes')
    }else{
      this.setState({
        isLoading: false,
        dataSource: data,
      })
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <View style={{ flex: 1, padding: 20 }}>
          <ActivityIndicator style={{marginTop: '20%'}} />
        </View>
      );
    }
    return (
      <View style={{ flex: 1, paddingTop: 10 }}>
        <FlatList
          data={this.state.dataSource.sort((a, b) => a[1].toString().localeCompare(b[1].toString()))}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('Farms', {
                routeCode: item[0],
                user: this.props.route.params.user
              })}
              style={styles.button}>
              <Text style={styles.buttonTitle}>{"Route " + item[0]}</Text>
            </TouchableOpacity>
          )}
          keyExtractor={(item, index) => { return item[0].toString() }}
        />
      </View>
    );
  }
}