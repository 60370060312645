import { ActivityIndicator, Platform, TouchableOpacity, View } from 'react-native'
import { Caption, DataTable, Modal, Portal, Snackbar, Text, Title } from 'react-native-paper'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import { Entypo } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { TextInput } from 'react-native-paper';
import { captureScreen } from 'react-native-view-shot';
import styles from '../../styles';

export default class AddReading extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, toastVisible: false, screenshot: {} };
    }
    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true, tanks: ['', '', '', '', '', ''], toastVisible: false, screenshot: {} })
            this.getData()
        })
    }
    async getData() {
        var requestBody = {
            context: 'tankCount',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState(
            {
                isLoading: false,
                tankCount: data
            })
    }

    async saveReading() {
        var requestBody = {
            context: 'addReading',
            DeviceId: this.props.route.params.DeviceId,
            DeliveredBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName),
            Tank1: this.state.tanks[0],
            Tank2: this.state.tanks[1],
            Tank3: this.state.tanks[2],
            Tank4: this.state.tanks[3],
            Tank5: this.state.tanks[4],
            Tank6: this.state.tanks[5],
        }
        var data = await setDataAPI(requestBody)
        await this.setState(
            {
                returnMsg: data
            })
        this.props.navigation.navigate('Dosage Calc', {
            Id: this.props.route.params.Id,
            user: this.props.route.params.user,
            DeliveryMessage: this.state.returnMsg,
            DeviceId: this.props.route.params.DeviceId,
            tankCount: this.state.tankCount,
            customer: this.props.route.params.customer,
            tanks: this.state.tanks
        })
    }

    writeToArray(tankMessage, tankNo) {
        var tankArr = this.state.tanks
        tankArr[tankNo - 1] = tankMessage
        this.setState({ tanks: tankArr })
    }

    renderTanks(tankNo) {
        if (tankNo <= this.state.tankCount) {
            //check herd amounts and render herd box input

            return (
                <DataTable.Row style={[{ flexDirection: "row" }, { flex: 1 }]}>
                    <DataTable.Cell style={{ flex: 2 }}>
                        <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNo}</Caption>
                    </DataTable.Cell>
                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }} style={[styles.inputContainerStyle, { marginBottom: 20 }, { flex: 4 }, { backgroundColor: 'white' }]}
                        keyboardType={'numeric'} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        onChangeText={tankMessage => this.writeToArray(tankMessage, tankNo)}>
                    </TextInput>
                </DataTable.Row>
            )
        }
    }

    renderHerds(tankNo) {

    }

    renderDosages(tankNo) {

    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    _saveScreenshotAsync = async () => {
        try {
            await captureScreen({
                format: "png",
                quality: 0.8
            }).then(
                uri => this.setState({ screenshot: uri, isLoading: true }),
                error => console.error("Oops, snapshot failed", error)
            )

        } catch (e) {
            console.log("ERROR: ", e)
        }


    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        return (
            <View style={{ flex: 1, padding: 20 }}>
                <Text style={{ textAlign: 'center', color: 'red' }}>Please only submit one reading per visit</Text>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank #</Text></DataTable.Title>
                    </DataTable.Header>
                    {this.renderTanks(1)}
                    {this.renderTanks(2)}
                    {this.renderTanks(3)}
                    {this.renderTanks(4)}
                    {this.renderTanks(5)}
                    {this.renderTanks(6)}
                </DataTable>
                <TouchableOpacity style={styles.button}
                    onPress={() => { this.saveReading() }}>
                    <Text style={styles.buttonTitle}>Submit</Text>
                </TouchableOpacity>

                <Snackbar
                    visible={this.state.toastVisible}
                    onDismiss={() => this.setState({ toastVisible: false })}
                    action={{
                        label: 'Close',
                        onPress: () => {
                        },
                    }}
                    style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                >
                    <Text>{this.state.toastBody}</Text>
                </Snackbar>
            </View>
        );


    }
}
