import { Image, View } from 'react-native';

import AdminEmptyDates from './AdminEmptyDates'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import styles from '../../styles';

export default function AdminEmptyDatesScreen(props) {
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <AdminEmptyDates {...props} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}