import { ActivityIndicator, AsyncStorage, Image, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { firebase } from '../../firebase/config';
import styles from '../../styles';

export default function LoginScreen({ navigation }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // const [token, setToken] = useState('')

    const [isLoading, setLoading] = useState(false)
    const [signedIn, setSignedIn] = useState(false)
    const count = []

    const onFooterLinkPress = () => {
        navigation.navigate('Registration')
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
              if (user) {
                user.getIdToken(true)
                .then(
                    latestToken => setTokenInAsyncStorage(latestToken).then(r => console.log("token set in async storage")
                )
                .catch(err => console.log(err)))
              }
            })

        async function fetchData() {
            // You can await here
            setLoading(true)
            if (!signedIn && count.length < 5) {
                try {
                    //console.log("#################### in try catch")
                    var username = await AsyncStorage.getItem('email')
                    var pw = await AsyncStorage.getItem('password')

                    if(username!== null){
                        username = username.replace(/^"(.*)"$/, '$1')
                        pw = pw.replace(/^"(.*)"$/, '$1')
                        count.push('*')
                    }

                    if (username !== null) {
                        console.log("--------------- Attempting re login -------------------")
                        setEmail(username)
                        setPassword(pw)
                        setSignedIn(true)
                        sleep(1000)
                        try {
                            firebase
                                .auth()
                                .signInWithEmailAndPassword(username, pw)
                                .then(async response => {
                                    const uid = response.user.uid
                                    // const jwtToken = await response.user?.getIdToken();
                                    // console.log(jwtToken);
                                    const usersRef = firebase.firestore().collection('users')
                                    usersRef
                                        .doc(uid)
                                        .get()
                                        .then(firestoreDocument => {
                                            if (!firestoreDocument.exists) {
                                                alert("User does not exist anymore.")
                                                setLoading(false)
                                                return;
                                            }
                                            const user = firestoreDocument.data()
                                            async function _storeData() {
                                                try {
                                                    await AsyncStorage.setItem(
                                                        'email',
                                                        JSON.stringify(username)
                                                    );
                                                    //console.log("Item set in storage!")
                                                } catch (error) {
                                                    console.log(error)
                                                    // Error saving data
                                                }
                                                try {
                                                    await AsyncStorage.setItem(
                                                        'password',
                                                        JSON.stringify(pw)
                                                    );
                                                } catch (error) {
                                                    console.log(error)
                                                    // Error saving data
                                                }
                                            };
                                            _storeData();
                                            navigation.navigate('Home', { user })
                                            setLoading(false)
                                        })
                                        .catch(error => {
                                            alert(error)
                                            setLoading(false)
                                        });
                                })
                                .catch(error => {
                                    alert(error)
                                    setLoading(false)
                                })
                        } catch (error) {
                            console.log(error)
                            navigation.navigate('Login')
                            setLoading(false)
                        }


                    }
                    else {
                        console.log("username is equal to null ", username)
                        count.push('*')
                        return

                    }
                } catch (error) {
                    // Error retrieving data
                    console.log("There was an error finding the key", error)
                    count.push('*')
                    return
                }
            }


        }
        //console.log("count: ", count)
        fetchData()
        setLoading(false)
    }, []);

    const setTokenInAsyncStorage = async (token) => {
        await AsyncStorage.setItem(
             'token',
             JSON.stringify(token)
        );
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const onLoginPress = () => {
        setLoading(true)
        try {
            firebase
                .auth()
                .signInWithEmailAndPassword(email.trim(), password)
                .then((response) => {
                    const uid = response.user.uid
                    const usersRef = firebase.firestore().collection('users')
                    usersRef
                        .doc(uid)
                        .get()
                        .then(firestoreDocument => {
                            if (!firestoreDocument.exists) {
                                alert("User does not exist anymore.")
                                setLoading(false)
                                return;
                            }
                            const user = firestoreDocument.data()
                            async function _storeData() {
                                try {
                                    await AsyncStorage.setItem(
                                        'email',
                                        JSON.stringify(email)
                                    );
                                    //console.log("Item set in storage!")
                                } catch (error) {
                                    console.log(error)
                                    // Error saving data
                                }
                                try {
                                    await AsyncStorage.setItem(
                                        'password',
                                        JSON.stringify(password)
                                    );
                                } catch (error) {
                                    console.log(error)
                                    // Error saving data
                                }
                            };
                            _storeData();
                            navigation.navigate('Home', { user })
                            setLoading(false)
                        })
                        .catch(error => {
                            alert(error)
                            setLoading(false)
                        });
                })
                .catch(error => {
                    alert(error)
                    setLoading(false)
                })
        } catch (error) {
            console.log(error)
            navigation.navigate('Login')
            setLoading(false)
        }
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin: '50%' }}>
                <ActivityIndicator style={{marginTop: '20%'}} />
            </View>
        );
    }
    // else if(check){

    // }
    else {
        return (
            <View style={styles.container}>
                <KeyboardAwareScrollView
                    style={{ flex: 1, width: '100%' }}
                    keyboardShouldPersistTaps="always">
                    <Image
                        style={styles.logo}
                        source={require('../../../assets/splash-android.png')}
                    />
                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        style={styles.input}
                        placeholder='E-mail'
                        placeholderTextColor="#aaaaaa"
                        onChangeText={(text) => setEmail(text)}
                        value={email}
                        underlineColorAndroid="transparent"
                        autoCapitalize="none"
                    />
                    <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        style={styles.input}
                        placeholderTextColor="#aaaaaa"
                        secureTextEntry
                        placeholder='Password'
                        onChangeText={(text) => setPassword(text)}
                        value={password}
                        underlineColorAndroid="transparent"
                        autoCapitalize="none"
                    />
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => onLoginPress()}>
                        <Text style={styles.buttonTitle}>Log in</Text>
                    </TouchableOpacity>
                    <View style={styles.footerView}>
                        <Text style={styles.footerText}>Don't have an account? <Text onPress={onFooterLinkPress} style={styles.footerLink}>Sign up</Text></Text>
                    </View>
                </KeyboardAwareScrollView>
            </View>
        )
    }
}
