import { ActivityIndicator, Dimensions, FlatList, Picker, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { DataTable, Searchbar, TextInput } from 'react-native-paper'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'
import { CSVLink, CSVDownload } from "react-csv";

import React from 'react'
import styles from '../../styles';

export default class CustomQueriesScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, screenHeight: 600, SelectedFarm: '', table: ['WaterData', 'MineralData'],
            selectionTanks: ['TANK1', 'TANK2', 'TANK3', 'TANK4', 'TANK5', 'TANK6', 'HERD1', 'HERD2', 'HERD3'],
            tankOrHerd: '', days: '7', selected: 'HERD1', selectedTable: 'WaterData', daysChoice: ['1', '4', '5', '7', '10', '14', '21', '30', '60', '100', '400'],
            mineralQueryResults: [], waterQueryResults: [], showUserFarm: '', usableHeight: 1000
        };
    }
    componentDidMount() {
        //requestBody['Table'], requestBody['TankOrHerd'], requestBody['Selection'], requestBody['Days']
        this.setState({
            isLoading: true, screenHeight: 600, SelectedFarm: '', table: ['WaterData', 'MineralData'],
            selection: ['TANK1', 'TANK2', 'TANK3', 'TANK4', 'TANK5', 'TANK6', 'HERD1', 'HERD2', 'HERD3'],
            tankOrHerd: '', days: '7', selected: 'HERD1', selectedTable: 'WaterData',
            mineralQueryResults: [], waterQueryResults: [], showUserFarm: ''
        })
        this.getData()
    }

    async getData() {
        var requestBody = {
            context: 'getAllFarms',
            distributorId: this.props.route.params.distributorId
        }
        var data = await getDataAPI(requestBody)
        await this.getScreenHeight()
        console.log(data)
        this.setState({
            isLoading: false,
            data: this.formatData(data),
            filteredData: this.formatData(data),
            usableHeight: this.state.screenHeight - 80
        })
    }

    async executeQuery() {
        console.log("This is in executQuery")
        console.log("table: ", this.state.selectedTable)
        console.log("Selection: : ", this.state.selected)
        console.log("Days: ", this.state.days)
        console.log("SelectedFarm: ", this.state.SelectedFarm)
        if (this.state.selectedTable == 'MineralData') {
            this.setState({
                waterQueryResults: [],
                isLoading: true
            })
            var requestBody = {
                context: 'getCustomQuery',
                Table: this.state.selectedTable,
                Selection: this.state.selected,
                Days: this.state.days,
                SelectedFarm: this.state.SelectedFarm
            }

            var data = await getDataAPI(requestBody)
            if (data.length == 0) {
                this.setState({
                    mineralQueryResults: [['No data', 'No data', 'No data', 'No data', 'No data',]],
                    isLoading: false
                })
            }
            else {
                this.setState({
                    mineralQueryResults: data,
                    isLoading: false
                })
            }

        }
        else if (this.state.selectedTable == 'WaterData') {
            this.setState({
                mineralQueryResults: [],
                isLoading: true
            })
            var requestBody = {
                context: 'getCustomQuery',
                Table: this.state.selectedTable,
                Selection: this.state.selected,
                Days: this.state.days,
                SelectedFarm: this.state.SelectedFarm
            }
            var data = await getDataAPI(requestBody)
            if (data.length == 0) {
                this.setState({
                    waterQueryResults: [['No data', 'No data', 'No data', 'No data', 'No data', 'No data',]],
                    isLoading: false
                })
            }
            else {
                this.setState({
                    waterQueryResults: data,
                    isLoading: false
                })
            }


            data.map(row => {
                console.log("Row data: ", row)
            })
        }
    }

    async getScreenHeight() {
        let screenHeight = Math.round(Dimensions.get('window').height);
        let screenWidth = Math.round(Dimensions.get('window').width);
        console.log('screenHeight: ', screenHeight)
        this.setState({
            screenHeight: screenHeight,
            screenWidth: screenWidth
        })
    }

    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
            return item[1].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
    };

    calcStyle(row) {
        var r = 127, g = 190, b = 65
        var rgb = 'rgb(' + r + ',' + g + ',' + b + ')'
        var style = {
            backgroundColor: rgb,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 20,
            height: 48,
            borderColor: 'black',
            borderWidth: 1,
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center'
        }
        return style
    }
    formatData(ds) {
        ds = ds.sort((a, b) => a[3].toString().toLowerCase().localeCompare(b[3].toString().toLowerCase()))
        ds.forEach((i) => {
            ds.forEach((j) => {
                if (i[1] === j[1] && i[0] != j[0]) {
                    i[1] = i[1] + " (" + i[2] + ")"
                    j[1] = j[1] + " (" + j[2] + ")"
                    if (i[2] == j[2]) {
                        i[1] = i[1] + " 1"
                        j[1] = j[1] + " 2"
                    }
                }
            })
        })
        return ds
    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        else {
            return (
                <View style={{ flexDirection: 'column', flex: 1, height: this.state.usableHeight }}>
                    <View style={{ flexDirection: 'row', flex: 1, height: this.state.usableHeight / 2}}>
                        <View style={{ flexDirection: 'column', height: '100%', flex: 2 }}>
                            <Searchbar placeholder="Search Farms..." onChangeText={this.search} value={this.state.searchText} />
                            <SafeAreaView >
                                <ScrollView style={{ height: this.state.screenHeight / 3 }}>
                                    <FlatList
                                        data={this.state.filteredData}
                                        renderItem={({ item }) => (
                                            <TouchableOpacity style={this.calcStyle(item)}
                                                onPress={() => this.setState({ SelectedFarm: item[4], showUserFarm: item[1] })}>
                                                <Text style={styles.buttonTitle}>{item[1]}</Text>
                                            </TouchableOpacity>
                                        )}
                                        keyExtractor={(item, index) => { return item[0].toString() }}
                                    />
                                </ScrollView>
                            </SafeAreaView>
                        </View>
                        <View style={{ flexDirection: 'column', height: '100%', flex: 2 }}>
                            <Text style={styles.title}>Selected farm</Text>
                            <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                                style={{ fontSize: 15, backgroundColor: '#fff', borderWidth: 1, borderRadius: 5 }}
                                placeholder=""
                                placeholderTextColor="#60605e"
                                keyboardType={'default'}
                                value={this.state.showUserFarm}
                            />
                            {/* ------------------------------------------------------------------------------- */}
                            <Text style={styles.title}>Table</Text>
                            <Picker
                                selectedValue={this.state.selectedTable}
                                onValueChange={selectedTable => this.setState({ selectedTable })}
                                style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 12 }}
                                mode="dropdown">
                                {this.state.table.map((item, index) => {
                                    return (<Picker.Item label={item} value={item} key={index} />)
                                })}
                            </Picker>
                            {/* ------------------------------------------------------------------------------- */}
                            <Text style={styles.title}>Tank/Herd</Text>
                            <Picker
                                selectedValue={this.state.selected}
                                onValueChange={selected => this.setState({ selected })}
                                style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 12 }}
                                mode="dropdown">
                                {this.state.selection.map((item, index) => {
                                    return (<Picker.Item label={item} value={item} key={index} />)
                                })}
                            </Picker>
                            {/* ------------------------------------------------------------------------------- */}
                            <Text style={styles.title}>Days</Text>
                            <Picker
                                selectedValue={this.state.days}
                                onValueChange={days => this.setState({ days })}
                                style={{ width: '100%', backgroundColor: '#fff', borderWidth: 1, borderRadius: 5, padding: 12 }}
                                mode="dropdown">
                                {this.state.daysChoice.map((item, index) => {
                                    return (<Picker.Item label={item} value={item} key={index} />)
                                })}
                            </Picker>
                            <TouchableOpacity style={[styles.button_alt]} onPress={() => { this.executeQuery() }}>
                                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>{
                                    'Submit query'
                                }</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', height: this.state.usableHeight / 2, alignItems: 'center', flex: 1 }}>
                        {this.state.waterQueryResults.length > 0 ?
                            <View style={{ marginLeft: 15, marginRight: 15, width: '100%' }}>
                                <CSVLink data={this.state.waterQueryResults} filename={"waterResults.csv"}>Download</CSVLink>
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>HerdId</DataTable.Title>
                                        <DataTable.Title>Type</DataTable.Title>
                                        <DataTable.Title>LSU</DataTable.Title>
                                        <DataTable.Title>HourW</DataTable.Title>
                                        <DataTable.Title>CreationDate</DataTable.Title>
                                    </DataTable.Header>
                                    <SafeAreaView style={{ height: (this.state.screenHeight / 2) - 70 }}>
                                        <ScrollView style={{ height: '100%' }}>
                                            {
                                                this.state.waterQueryResults.map(data => {
                                                    return (
                                                        <DataTable.Row>
                                                            <DataTable.Cell>
                                                                {data[0]}
                                                            </DataTable.Cell>
                                                            <DataTable.Cell>
                                                                {data[1]}
                                                            </DataTable.Cell>
                                                            <DataTable.Cell>
                                                                {data[2]}
                                                            </DataTable.Cell>
                                                            <DataTable.Cell>
                                                                <Text>{data[3]}</Text>
                                                            </DataTable.Cell>
                                                            <DataTable.Cell>
                                                                <Text>{data[4]}</Text>
                                                            </DataTable.Cell>
                                                        </DataTable.Row>
                                                    )
                                                })
                                            }
                                        </ScrollView>
                                    </SafeAreaView>
                                </DataTable>
                            </View>
                            : this.state.mineralQueryResults.length > 0 ?
                                <View style={{ marginLeft: 15, marginRight: 15, width: '100%' }}>
                                    <CSVLink data={this.state.mineralQueryResults} filename={"mineralResults.csv"}>Download</CSVLink>
                                    <DataTable>
                                        <DataTable.Header>
                                            <DataTable.Title>TankId</DataTable.Title>
                                            <DataTable.Title>Volume</DataTable.Title>
                                            <DataTable.Title>Dispensed</DataTable.Title>
                                            <DataTable.Title>Pulses</DataTable.Title>
                                            <DataTable.Title>CreationDate</DataTable.Title>
                                        </DataTable.Header>
                                        <SafeAreaView style={{ height: (this.state.screenHeight / 2) - 70 }}>
                                            <ScrollView style={{ height: '100%' }}>
                                                {
                                                    this.state.mineralQueryResults.map(data => {
                                                        return (
                                                            <DataTable.Row>
                                                                <DataTable.Cell>
                                                                    {data[0]}
                                                                </DataTable.Cell>
                                                                <DataTable.Cell>
                                                                    {data[1]}
                                                                </DataTable.Cell>
                                                                <DataTable.Cell>
                                                                    {data[2]}
                                                                </DataTable.Cell>
                                                                <DataTable.Cell>
                                                                    <Text>{data[3]}</Text>
                                                                </DataTable.Cell>
                                                                <DataTable.Cell>
                                                                    <Text>{data[4]}</Text>
                                                                </DataTable.Cell>
                                                            </DataTable.Row>
                                                        )
                                                    })
                                                }
                                            </ScrollView>
                                        </SafeAreaView>
                                    </DataTable>
                                </View>
                                :
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>Waiting..</DataTable.Title>
                                        <DataTable.Title>Waiting..</DataTable.Title>
                                        <DataTable.Title>Waiting..</DataTable.Title>
                                        <DataTable.Title>Waiting..</DataTable.Title>
                                        <DataTable.Title>Waiting..</DataTable.Title>

                                    </DataTable.Header>
                                    <SafeAreaView style={{ height: (this.state.screenHeight / 2) - 70 }}>
                                        <ScrollView style={{ height: '100%' }}>
                                        </ScrollView>
                                    </SafeAreaView>
                                </DataTable>
                        }
                    </View>
                </View>

            )
        }

    }
}
