import { ActivityIndicator, Platform, TouchableOpacity, View } from 'react-native'
import { Caption, DataTable, Text, Title } from 'react-native-paper'
import { Modal, Portal, Snackbar, TextInput } from 'react-native-paper';
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import { Entypo } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { captureScreen } from 'react-native-view-shot';
import { diff } from 'react-native-reanimated';
import styles from '../../styles';

export default class DosageCalc extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, toastVisible: false, screenshot: {} };
    }
    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({
                isLoading: true, tankDosage: [], showPerformance: false, notEnoughData: false, LSU: [],
                loadingPageText: 'Loading...', visible: false, rateChangeVisible: false, toastVisible: false, screenshot: {}
            })
            this.getCapacities()
            this.getTanksToHerds()
        })
    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    _saveScreenshotAsync = async () => {
        try {
            await captureScreen({
                format: "png",
                quality: 0.8
            }).then(
                uri => this.setState({ screenshot: uri, isLoading: true }),
                error => console.error("Oops, snapshot failed", error)
            )

        } catch (e) {
            console.log("ERROR: ", e)
        }


    }

    async getTanksToHerds() {
        var herd1 = []
        var herd2 = []
        var herd3 = []
        var requestBody = {
            context: 'tanksToHerds',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)

        for (var i = 0; i < data.length; i++) {
            if (data[i][1] == 'HERD1') {
                herd1.push(data[i][2])
            }
            else if (data[i][1] == 'HERD2') {
                herd2.push(data[i][2])
            }
            else if (data[i][1] == 'HERD3') {
                herd3.push(data[i][2])
            }
        }

        console.log("Herd1 tanks: ", herd1)
        console.log("Herd2 tanks: ", herd2)
        console.log("Herd3 tanks: ", herd3)
        this.setState
            ({
                tanksAndHerds: data,
                herd1: herd1,
                herd2: herd2,
                herd3: herd3
            })

    }

    async getCapacities() {
        var requestBody = {
            context: 'offlineTankCapacity',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState(
            {
                tankCapacities: data
            })

        await this.getReadings()
    }

    async getReadings() {
        var requestBody = {
            context: 'getReadings',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState(
            {
                readings: data,
            })

        if (data.length < 2) {//checks if there is another reading on top of the one just sent
            this.setState(
                {
                    notEnoughData: true,
                    isLoading: false
                })
            return
        }

        console.log("Tank Capacities")
        console.log(this.state.tankCapacities)
        console.log("Tank readings")
        console.log(this.state.readings)
        this.daysBetweenReadings()
        this.tankDifference()
    }

    daysBetweenReadings() {
        var _MS_PER_DAY = 1000 * 60 * 60 * 24;

        function dateDiffInDays(a, b) {
            // Discard the time and time-zone information.
            var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
            var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        }

        var b = new Date(this.state.readings[0][1])
        var a = new Date(this.state.readings[1][1])
        var difference = dateDiffInDays(a, b);
        console.log("Difference in days: ", difference)
        this.setState({ readingDiff: difference })
    }

    tankDifference() {
        let tankDifferences = []
        console.log("tankCount: ", this.props.route.params.tankCount[0][0])
        for (let i = 0; i < this.props.route.params.tankCount[0][0]; i++) {
            var a = this.state.readings[1][2 + i]
            var b = this.state.readings[0][2 + i]

            var difference = a - b
            tankDifferences.push(difference)
        }
        this.setState(
            {
                tankDifferences: tankDifferences,
                isLoading: false
            })
        console.log("tankDifferences: ", tankDifferences)
    }

    insertDosages(tankNo) {
        if (tankNo < this.props.route.params.tankCount[0][0]) {
            return (
                <DataTable.Row style={[{ flexDirection: "row" }, { flex: 1 }]}>
                    <DataTable.Cell style={{ width: '25%' }}>
                        <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNo + 1}</Caption>
                    </DataTable.Cell>
                    <TextInput style={[styles.inputContainerStyle, { marginBottom: 20 }, { width: '20%' }, { backgroundColor: 'white' }]}
                        keyboardType={'numeric'} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        onChangeText={(text) => this.dosageToTank(text, tankNo)}>
                    </TextInput>
                    <DataTable.Cell style={{ width: '25%' }}>
                        <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>LSU</Caption>
                    </DataTable.Cell>
                    <TextInput style={[styles.inputContainerStyle, { marginBottom: 20 }, { width: '20%' }, { backgroundColor: 'white' }]}
                        keyboardType={'numeric'} theme={{ colors: { text: 'rgb(43,43,43)' } }}
                        onChangeText={(text) => this.LSUToTank(text, tankNo)}>
                    </TextInput>
                </DataTable.Row>
            )
        }
    }

    showPredictedEmptyDate(tankNo) {
        var multiplier = 1
        if (tankNo < this.props.route.params.tankCount[0][0]) {
            if (this.state.tankCapacities[tankNo][2] == 'Minerals') {
                if (this.state.tankCapacities[tankNo][0] == 200) {
                    multiplier = 1.08
                }
                else if (this.state.tankCapacities[tankNo][0] == 400 || this.state.tankCapacities[tankNo][0] == 440) {
                    multiplier = 2.53
                }
            }
            else if (this.state.tankCapacities[tankNo][2] == 'Magnesium') {
                if (this.state.tankCapacities[tankNo][0] == 200) {
                    multiplier = 1.2
                }
                else if (this.state.tankCapacities[tankNo][0] == 400 || this.state.tankCapacities[tankNo][0] == 440 || this.state.tankCapacities[tankNo][0] == 450) {
                    multiplier = 2.75
                }
                else if (this.state.tankCapacities[tankNo][0] == 1000) {
                    multiplier = 5.3
                }
            }
            else if (this.state.tankCapacities[tankNo][2] == 'Mag plus') {
                if (this.state.tankCapacities[tankNo][0] == 200) {
                    multiplier = 1.2
                }
                else if (this.state.tankCapacities[tankNo][0] == 400 || this.state.tankCapacities[tankNo][0] == 440 || this.state.tankCapacities[tankNo][0] == 450) {
                    multiplier = 2.75
                }
                else if (this.state.tankCapacities[tankNo][0] == 1000) {
                    multiplier = 5.3
                }
            }
            else if (this.state.tankCapacities[tankNo][2] == 'Phosphorus') {
                if (this.state.tankCapacities[tankNo][0] == 200) {
                    multiplier = 1.09
                }
                else if (this.state.tankCapacities[tankNo][0] == 400 || this.state.tankCapacities[t][0] == 440 || this.state.tankCapacities[t][0] == 450) {
                    multiplier = 2.34
                }
            }
            else if (this.state.tankCapacities[tankNo][2] == 'Bloat') {
                if (this.state.tankCapacities[tankNo][0] == 200) {
                    multiplier = 1
                }
            }
            return (
                <DataTable.Row style={[{ flexDirection: "row" }, { flex: 1 }]}>
                    <DataTable.Cell style={{ width: '50%' }}>
                        <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNo + 1} days to empty:</Caption>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ width: '50%' }}>
                        <Text>{Math.round((this.state.readings[0][2 + tankNo] * multiplier) / ((this.state.tankDosage[tankNo] * this.state.LSU[tankNo]) / 1000))}</Text>
                    </DataTable.Cell>
                </DataTable.Row>
            )
        }
    }

    showPerformance(tankNo) {
        if (tankNo < this.props.route.params.tankCount[0][0]) {
            return (
                <DataTable.Row style={[{ flexDirection: "row" }, { flex: 1 }]}>
                    <DataTable.Cell style={{ flex: 2 }}>
                        <Caption theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNo + 1}</Caption>
                    </DataTable.Cell>
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }}> {this.state.doseRating[tankNo]}/{this.state.tankDosage[tankNo]} </Text>
                </DataTable.Row>
            )
        }
    }

    dosageToTank(dosage, tankNo) {
        var dosages = this.state.tankDosage
        dosages[tankNo] = dosage

        this.setState({ tankDosage: dosages })
        console.log("dosage to tank: ", dosages)
    }
    LSUToTank(LSU, tankNo) {
        var animalNo = this.state.LSU
        animalNo[tankNo] = LSU

        this.setState({ LSU: animalNo })
        console.log("LSU to tank: ", animalNo)
    }

    async getRequestURL() {
        console.log(this.props.route.params.user)
        var requestBody = {
            context: 'getURL',
            UserId: this.props.route.params.user.id
        }
        var data = await getDataAPI(requestBody)

        if (data.length == 0 || data == undefined) {
            return 'Not Authorized'
        }
        console.log(data)
        return data
    }

    async getAuthToken() {
        var refresh_token = await this.getRequestURL()
        if (refresh_token == 'Not Authorized') {
            alert("User is not Authorized to access this feature.")
            return "Not Authorized"
        }
        var url = ['https://securetoken.googleapis.com/v1/token?key=', global.Apikey, '&grant_type=refresh_token&refresh_token=', refresh_token]
        url = url.join('')
        console.log(url)
        var data = {}
        await fetch(url, {
            method: 'POST',
            headers: {
                'Connection': 'keep-alive',
                'Accept': '*/*'
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                data = responseJson
            })
            .catch(error => {
                console.error(error);
            });
        this.setState({
            token: data['access_token']
        })
        console.log(this.state.token)
        return "Authorized"
    }


    async useTerraAPI(command, herd, herdNo, dosage, tank) {

        console.log("In the terraAPI !!!")
        //in here make request to update dosages + herd numbers on the portal
        //https://api.terranutritech.com/api/devices/F2CA50FA-F169-4BAD-A18C-FA1257A97EC4/herds/HERD1/livestock
        //get previous implementation on adminExtraFunctions
        var authorized = await this.getAuthToken()
        if (authorized == 'Not Authorized') {
            return
        }
        switch (command) {
            case 'dosage':
                var dosageRequestBody = {
                    dosingAlgorithm: "LSU",
                    dosage: dosage,
                    dosingTimed: []
                }
                var url = `https://api.terranutritech.com/api/devices/${this.props.route.params.DeviceId}/herds/${herd}/minerals/${tank}/level`
                var data = {}
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Connection': 'keep-alive',
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(dosageRequestBody)
                }).catch(error => {
                    console.error(error);
                });
                break;
            case 'herd':
                var herdRequestBody = {
                    "00-06": 0,
                    "07-12": 0,
                    "13-18": 0,
                    "19-24": 0,
                    "25+": parseInt(herdNo)
                }
                var url = `https://api.terranutritech.com/api/devices/${this.props.route.params.DeviceId}/herds/${herd}/livestock`
                var data = {}
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Connection': 'keep-alive',
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(herdRequestBody)
                }).catch(error => {
                    console.error(error);
                });
                break;
            default:
                console.log("Went into default case statement")
        }
    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    async changeDeviceValues() {
        console.log("In changeDeviceValues")
        var herd1 = this.state.herd1
        var herd2 = this.state.herd2
        var herd3 = this.state.herd3
        var tankDosage = this.state.tankDosage
        var tankLSU = this.state.LSU
        console.log("tankDosages", tankDosage)
        console.log("tankLSU", tankLSU)

        //check if user has entered dosages and lsu then match tanks to herds and call portal api to change details
        if (tankLSU.length > 0) {
            for (var i = 0; i < tankLSU.length; i++) {
                if (tankLSU[i]) {
                    switch (i) {
                        case 0://tank1
                            if (herd1[0] == 'TANK1') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            else if (herd1[1] == 'TANK1') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            else if (herd1[2] == 'TANK1') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            break;
                        case 1://tank2
                            if (herd1[0] == 'TANK2') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            else if (herd1[1] == 'TANK2') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            else if (herd1[2] == 'TANK2') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            break;
                        case 2:
                            if (herd1[0] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd1[1] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd1[2] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[0] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[1] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[2] == 'TANK3') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            break;
                        case 3:
                            if (herd1[3] == 'TANK4') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[0] == 'TANK4') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[1] == 'TANK4') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[2] == 'TANK4') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            break;
                        case 4:
                            if (herd1[4] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[0] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[1] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[2] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[0] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[1] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD3", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[2] == 'TANK5') {
                                await this.useTerraAPI("herd", "HERD3", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            break;
                        case 5:
                            if (herd1[2] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD1", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[0] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[1] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[2] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[3] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[0] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[1] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[2] == 'TANK6') {
                                await this.useTerraAPI("herd", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            break;

                    }

                }
            }
        }
        if (tankDosage.length > 0) {
            for (var i = 0; i < tankDosage.length; i++) {
                if (tankDosage[i] || tankDosage[i] == 'empty' || tankDosage[i] == 'undefined') {
                    console.log("checked dosage exists! ", tankDosage[i])
                    console.log("checked LSU exists! ", tankLSU[i])
                    switch (i) {
                        case 0://tank1
                            console.log("Have entered values to tank 1")

                            if (herd1[0] == 'TANK1') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            else if (herd1[1] == 'TANK1') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            else if (herd1[2] == 'TANK1') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK1')
                            }
                            break;
                        case 1://tank2
                            if (herd1[0] == 'TANK2') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            else if (herd1[1] == 'TANK2') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            else if (herd1[2] == 'TANK2') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK2')
                            }
                            break;
                        case 2:
                            if (herd1[0] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd1[1] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd1[2] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[0] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[1] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            else if (herd2[2] == 'TANK3') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK3')
                            }
                            break;
                        case 3:
                            if (herd1[3] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[0] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[1] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd2[2] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd3[0] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            else if (herd3[1] == 'TANK4') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK4')
                            }
                            break;
                        case 4:
                            if (herd1[4] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[0] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[1] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd2[2] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[0] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[1] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            else if (herd3[2] == 'TANK5') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK5')
                            }
                            break;
                        case 5:
                            if (herd1[2] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD1", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[0] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[1] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[2] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd2[3] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD2", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[0] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[1] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            else if (herd3[2] == 'TANK6') {
                                await this.useTerraAPI("dosage", "HERD3", tankLSU[i], tankDosage[i], 'TANK6')
                            }
                            break;

                    }

                }
            }
        }


    }

    async calcDosing() {

        this.setState(
            {
                isLoading: true,
                loadingPageText: 'Calculating accuracy and saving LSU + Dosages to portal.'
            })

        //make request to save new data to prod
        await this.changeDeviceValues()
        let predictedKG = []
        let actual = []
        let calc = []
        let multiplier = 1
        //i = 0 is tank1 
        for (let i = 0; i < this.props.route.params.tankCount[0][0]; i++) {
            predictedKG.push(((this.state.LSU[i] * this.state.tankDosage[i]) / 1000) * this.state.readingDiff)
            //For actual check mineral  + tankSize and use multiplier
            //tankDifferences * multiplier
            if (this.state.tankCapacities[i][2] == 'Minerals') {
                if (this.state.tankCapacities[i][0] == 200) {
                    multiplier = 1.08
                }
                else if (this.state.tankCapacities[i][0] == 400 || this.state.tankCapacities[i][0] == 440) {
                    multiplier = 2.53
                }
            }
            else if (this.state.tankCapacities[i][2] == 'Magnesium') {
                if (this.state.tankCapacities[i][0] == 200) {
                    multiplier = 1.2
                }
                else if (this.state.tankCapacities[i][0] == 400 || this.state.tankCapacities[i][0] == 440 || this.state.tankCapacities[i][0] == 450) {
                    multiplier = 2.75
                }
                else if (this.state.tankCapacities[i][0] == 1000) {
                    multiplier = 5.3
                }
            }
            else if (this.state.tankCapacities[i][2] == 'Mag plus') {
                if (this.state.tankCapacities[i][0] == 200) {
                    multiplier = 1.2
                }
                else if (this.state.tankCapacities[i][0] == 400 || this.state.tankCapacities[i][0] == 440 || this.state.tankCapacities[i][0] == 450) {
                    multiplier = 2.75
                }
                else if (this.state.tankCapacities[i][0] == 1000) {
                    multiplier = 5.3
                }
            }
            else if (this.state.tankCapacities[i][2] == 'Phosphorus') {
                if (this.state.tankCapacities[i][0] == 200) {
                    multiplier = 1.09
                }
            }
            else if (this.state.tankCapacities[i][2] == 'Bloat') {
                if (this.state.tankCapacities[i][0] == 200) {
                    multiplier = 1
                }
                else if (this.state.tankCapacities[i][0] == 400 || this.state.tankCapacities[i][0] == 440 || this.state.tankCapacities[i][0] == 450) {
                    multiplier = 2.34
                }
            }
            actual.push((this.state.tankDifferences[i] * multiplier))
        }

        for (let i = 0; i < this.props.route.params.tankCount[0][0]; i++) {
            var temp = actual[i] / predictedKG[i]
            temp = Math.round(temp * this.state.tankDosage[i])
            calc.push(temp)
        }

        this.setState(
            {
                doseRating: calc,
                showPerformance: true,
                isLoading: false
            })
    }

    finishDosageCalc() {
        this.props.navigation.navigate('Farm Details', {
            Id: this.props.route.params.Id,
            user: this.props.route.params.user,
            DeviceId: this.props.route.params.DeviceId
        })
    }

    async updateRates() {
        this.setState({
            visible: false
        })
        await this.changeDeviceValues()
        this.setState({ rateChangeVisible: true, toastBody: 'Rates changed successfully' })
        this.sleepToast(3500)

    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ rateChangeVisible: false })
    }

    hideModal = () => {
        this.setState({ visible: false })
    }

    modalItems() {
        if (this.state.isLoading) {
            return (
                <View style={[styles.modalView, { margin: '5%', width: '90%', height: 200, alignItems: 'center', justifyContent: 'center' }]}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            )
        }
        return (
            <View style={[styles.modalView, { margin: '5%', width: '90%', height: 600, alignItems: 'center', justifyContent: 'center' }]}>
                <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={{ textAlign: 'center', marginTop: 5, fontSize: 20 }}>Adjust rates</Text>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={{ alignItems: 'center', justifyContent: 'center' }}><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank Dosages + LSU</Text></DataTable.Title>
                    </DataTable.Header>
                    <KeyboardAwareScrollView
                        style={{ height: 300, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        {this.insertDosages(0)}
                        {this.showPredictedEmptyDate(0)}
                        {this.insertDosages(1)}
                        {this.showPredictedEmptyDate(1)}
                        {this.insertDosages(2)}
                        {this.showPredictedEmptyDate(2)}
                        {this.insertDosages(3)}
                        {this.showPredictedEmptyDate(3)}
                        {this.insertDosages(4)}
                        {this.showPredictedEmptyDate(4)}
                        {this.insertDosages(5)}
                        {this.showPredictedEmptyDate(5)}

                    </KeyboardAwareScrollView>
                </DataTable>
                <TouchableOpacity style={[styles.button, { width: '60%' }]}
                    onPress={() => { this.updateRates() }}>
                    <Text style={styles.buttonTitle}>Submit</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button_red, { width: '60%', marginBottom: 5 }]}
                    onPress={() => { this.hideModal() }}>
                    <Text style={styles.buttonTitle}>Exit</Text>
                </TouchableOpacity>

            </View>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={{ textAlign: 'center', fontSize: 20 }}>{this.state.loadingPageText}</Text>
                </View>
            );
        }
        if (this.state.notEnoughData) {
            return (
                <View style={{ flex: 1, padding: 20, alignItems: 'center' }}>
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={{ textAlign: 'center', marginTop: '40%', fontSize: 20 }}>There is not enough readings to show how the pump is dosing</Text>
                    <TouchableOpacity style={[styles.button_red, { width: '60%' }]}
                        onPress={() => { this.finishDosageCalc() }}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Finish</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, { width: '60%' }]}
                        onPress={() => { this.setState({ visible: true }) }}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Change rates</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, { width: '60%' }]}
                        onPress={() => this.props.navigation.navigate('Add Delivery', {
                            Id: this.props.route.params.Id,
                            user: this.props.route.params.user,
                            customer: this.props.route.params.customer,
                            DeviceId: this.props.route.params.DeviceId,
                            tanks: this.props.route.params.tanks
                        })}
                    >
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Add Delivery</Text>
                    </TouchableOpacity>
                    <Portal>
                        <Modal visible={this.state.visible} onDismiss={() => { this.hideModal() }}>
                            {this.modalItems()}
                        </Modal>
                    </Portal>
                    <Snackbar
                        visible={this.state.rateChangeVisible}
                        onDismiss={() => this.setState({ rateChangeVisible: false })}
                        action={{
                            label: 'Close',
                            onPress: () => {
                            },
                        }}
                        style={{ backgroundColor: "#345DAE", bottom: 20, alignSelf: 'center', marginLeft: 30, marginRight: 30 }}
                    >
                        <Text>{this.state.toastBody}</Text>
                    </Snackbar>


                    <Snackbar
                        visible={this.state.toastVisible}
                        onDismiss={() => this.setState({ toastVisible: false })}
                        action={{
                            label: 'Close',
                            onPress: () => {
                            },
                        }}
                        style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                    >
                        <Text>{this.state.toastBody}</Text>
                    </Snackbar>
                </View>
            );
        }
        if (this.state.showPerformance) {
            return (
                <View style={{ flex: 1, padding: 20, minHeight: 550, alignItems: 'center' }}>
                    <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={{ textAlign: 'center', fontSize: 20 }}>In here we show the performance of the pumps</Text>
                    <DataTable style={{ paddingTop: 20 }}>
                        <KeyboardAwareScrollView
                            style={{ height: 400, width: '100%' }}
                            keyboardShouldPersistTaps="always">
                            {this.showPerformance(0)}
                            {this.showPredictedEmptyDate(0)}
                            {this.showPerformance(1)}
                            {this.showPredictedEmptyDate(1)}
                            {this.showPerformance(2)}
                            {this.showPredictedEmptyDate(2)}
                            {this.showPerformance(3)}
                            {this.showPredictedEmptyDate(3)}
                            {this.showPerformance(4)}
                            {this.showPredictedEmptyDate(4)}
                            {this.showPerformance(5)}
                            {this.showPredictedEmptyDate(5)}
                        </KeyboardAwareScrollView>
                    </DataTable>
                    <TouchableOpacity style={[styles.button_red, { width: '60%' }]}
                        onPress={() => { this.finishDosageCalc() }}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Finish</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, { width: '60%' }]}
                        onPress={() => { this.setState({ visible: true }) }}>
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Change rates</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, { width: '60%' }]}
                        onPress={() => this.props.navigation.navigate('Add Delivery', {
                            Id: this.props.route.params.Id,
                            user: this.props.route.params.user,
                            customer: this.props.route.params.customer,
                            DeviceId: this.props.route.params.DeviceId,
                            tanks: this.props.route.params.tanks

                        })}
                    >
                        <Text theme={{ colors: { text: 'rgb(43,43,43)' } }} style={styles.buttonTitle}>Add Delivery</Text>
                    </TouchableOpacity>
                    <Portal>
                        <Modal visible={this.state.visible} onDismiss={() => { this.hideModal() }}>
                            {this.modalItems()}
                        </Modal>
                    </Portal>
                    <Snackbar
                        visible={this.state.rateChangeVisible}
                        onDismiss={() => this.setState({ rateChangeVisible: false })}
                        action={{
                            label: 'Close',
                            onPress: () => {
                            },
                        }}
                        style={{ backgroundColor: "#345DAE", bottom: 20, alignSelf: 'center', marginLeft: 30, marginRight: 30 }}
                    >
                        <Text>{this.state.toastBody}</Text>
                    </Snackbar>
 

                    <Snackbar
                        visible={this.state.toastVisible}
                        onDismiss={() => this.setState({ toastVisible: false })}
                        action={{
                            label: 'Close',
                            onPress: () => {
                            },
                        }}
                        style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                    >
                        <Text>{this.state.toastBody}</Text>
                    </Snackbar>
                </View>
            );
        }

        return (
            <View style={{ flex: 1, padding: 20 }}>
                <DataTable>
                    <DataTable.Row style={[{ flexDirection: "row" }, { flex: 1 }]}>
                    </DataTable.Row>
                    <DataTable.Header>
                        <DataTable.Title style={{ alignItems: 'center', justifyContent: 'center' }}><Text theme={{ colors: { text: 'rgb(43,43,43)' } }}>Current tank dosages + LSU</Text></DataTable.Title>
                    </DataTable.Header>
                    {this.insertDosages(0)}
                    {this.insertDosages(1)}
                    {this.insertDosages(2)}
                    {this.insertDosages(3)}
                    {this.insertDosages(4)}
                    {this.insertDosages(5)}

                </DataTable>

                <TouchableOpacity style={styles.button}
                    onPress={() => { this.calcDosing() }}>
                    <Text style={styles.buttonTitle}>Submit</Text>
                </TouchableOpacity>


                <Snackbar
                    visible={this.state.toastVisible}
                    onDismiss={() => this.setState({ toastVisible: false })}
                    action={{
                        label: 'Close',
                        onPress: () => {
                        },
                    }}
                    style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                >
                    <Text>{this.state.toastBody}</Text>
                </Snackbar>
            </View>
        );
    }
}