import '../../globals.js'

import { ActivityIndicator, Dimensions, SafeAreaView, ScrollView, View } from 'react-native';
import { Button, DataTable, IconButton, List, Modal, Portal, Text, Title } from 'react-native-paper'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import Moment from 'moment'
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styles from '../../styles';

export default class FarmList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, routeProducts: [], prod: [], farmsOffline: [], accordian: false, screenHeight: 300 };
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true, routeProducts: [], prod: [], farmsOffline: [], accordian: false })
            this.getData()
        })
    }
    async getData() {
        await this.getScreenHeight()
        var requestBody = {
            context: 'farmList',
            routeCode: this.props.route.params.routeCode
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            dataSource: data,
            prod: []
        })
        await this.getRouteNote()
        await this.getDeliveredToday()
        await this.getProductData()
        await this.getFarmsSwitchedOff()
    }
    async getFarmsSwitchedOff() {
        for (let i = 0; i < this.state.dataSource.length; i++) {
            await this.getisFarmOff(this.state.dataSource[i][0])
        }
        console.log(this.state.farmsOffline)
    }
    async getisFarmOff(FarmId) {
        let fo = this.state.farmsOffline
        var requestBody = {
            context: 'calendar',
            FarmId: FarmId
        }
        var data = await getDataAPI(requestBody)
        if (data != [] && data != null && data != undefined) {
            if (data[0] != undefined) {
                fo.push(data[0])
            }
        }
        this.setState({
            farmsOffline: fo
        })
    }
    async getRouteNote() {
        var requestBody = {
            context: 'getRouteNote',
            RouteCode: this.props.route.params.routeCode
        }
        var data = await getDataAPI(requestBody)
        let r = data[0]
        if (r == undefined || r == "" || r == null) {
            r = ""
        }
        this.setState({
            routeNote: r
        })
    }
    async getDeliveredToday() {
        let deliveryToday = []
        for (let i = 0; i < this.state.dataSource.length; i++) {
            console.log(this.state.dataSource[i][0])
            let requestBody = {
                context: 'getDeliveryToday',
                FarmId: this.state.dataSource[i][0]
            }
            let data = await getDataAPI(requestBody)
            console.log(data)
            deliveryToday.push([this.state.dataSource[i][0], data[0] > 0 ? true : false])
        }
        console.log(deliveryToday)
        this.setState({
            deliveriesToday: deliveryToday
        })
    }

    async getProductData() {
        var requestBody = {
            context: 'routeProducts',
            RouteCode: this.props.route.params.routeCode
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            isLoading: false,
            routeProducts: data,
        })
        console.log(data)
        let cleanProducts = []
        for (let i = 0; i < data.length; i++) {
            if (typeof data[i][0] === 'string') {
                if (data[i][0].length > 0) {
                    cleanProducts.push(JSON.parse(data[i][0]));
                }
            }
        }
        let productsList = []
        let amountsList = []
        for (let i = 0; i < cleanProducts.length; i++) {
            for (const key in cleanProducts[i]) {
                if (cleanProducts[i].hasOwnProperty(key)) {
                    if (typeof cleanProducts[i][key]['Products'] != 'undefined') {
                        console.log(cleanProducts[i][key]['Products'])
                        for (let k = 0; k < cleanProducts[i][key]['Products'].length; k++) {
                            if (productsList.includes(cleanProducts[i][key]['Products'][k])) {
                                for (let j = 0; j < productsList.length; j++) {
                                    if (cleanProducts[i][key]['Products'][k] == productsList[j]) {
                                        amountsList[j] = amountsList[j] + parseInt(cleanProducts[i][key]['Amounts'][k], 10)
                                    }
                                }
                            }
                            else {
                                if (cleanProducts[i][key]['Products'][k] != 'No Product' && parseInt(cleanProducts[i][key]['Amounts'][k]) != 0) {
                                    productsList.push(cleanProducts[i][key]['Products'][k])
                                    amountsList.push(parseInt(cleanProducts[i][key]['Amounts'][k], 10))
                                }
                            }

                        }
                    }
                }
            }
        }

        let r = amountsList.reduce(function (result, field, index) {
            result[productsList[index]] = field;
            return result
        }, {})

        this.setState({
            prod: r
        })
        return 'success'

    }

    formatData(ds) {
        ds = ds.sort((a, b) => a[3].toString().localeCompare(b[3].toString()))
        ds.forEach((i) => {
            ds.forEach((j) => {
                if (i[1] === j[1] && i[0] != j[0]) {
                    i[1] = i[1] + " - " + i[2]
                    j[1] = j[1] + " - " + j[2]
                }
            })
        })
        return ds
    }


    calcStyle(row) {
        var r = 127, g = 190, b = 65
        if (row[4] == true) {
            r = 255
            g = 30
            b = 30
        }
        var rgb = 'rgb(' + r + ',' + g + ',' + b + ')'
        var style = {
            backgroundColor: rgb,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 20,
            height: 48,
            borderColor: 'black',
            borderWidth: 1,
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center'
        }
        return style
    }

    getIcon() {
        if (this.state.accordian) {
            return "chevron-down"
        }
        return "chevron-left"
    }

    getTotalProductsNeeded() {
        return (
            <View>
                {
                    <DataTable style={{ marginBottom: 5, backgroundColor: 'rgb(233,233,233)', borderWidth: 1, borderColor: 'rgba(255,255,255,0)', borderTopColor: '#000', borderBottomColor: '#000' }}>
                        <DataTable.Header>
                            <DataTable.Title onPress={() => { this.setState({ accordian: !this.state.accordian }) }}>
                                Product
                            </DataTable.Title>
                            <DataTable.Title onPress={() => { this.setState({ accordian: !this.state.accordian }) }}>
                                Amount
                            </DataTable.Title>
                            <IconButton icon={this.getIcon()} onPress={() => { this.setState({ accordian: !this.state.accordian }) }} />
                        </DataTable.Header>
                        {
                            this.state.accordian &&
                            Object.keys(this.state.prod).map((key, i) => {
                                return (
                                    <DataTable.Row key={i} onPress={() => { }}>
                                        <DataTable.Cell>
                                            {key}
                                        </DataTable.Cell>
                                        <DataTable.Cell>
                                            {this.state.prod[key]} kg
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                )
                            })
                        }
                    </DataTable>
                }
            </View>
        )
    }
    async getScreenHeight() {
        let screenHeight = Math.round(Dimensions.get('window').height);
        console.log('screenHeight: ', screenHeight)
        this.setState({
            screenHeight: screenHeight
        })
    }
    getFarmBtnIcon(id) {
        for (let i = 0; i < this.state.deliveriesToday.length; i++) {
            if (this.state.deliveriesToday[i][0] == id) {
                if (this.state.deliveriesToday[i][1] === true) {
                    return 'checkbox-marked-circle'
                } else {
                    return 'checkbox-blank-circle-outline'
                }
            }
        }
    }
    getFarmBtnColor(id) {
        for (let i = 0; i < this.state.farmsOffline.length; i++) {
            if (id == this.state.farmsOffline[i][1]) {
                return { backgroundColor: 'rgba(155, 0, 0, 0.6)' }
            }

        }
        for (let i = 0; i < this.state.deliveriesToday.length; i++) {
            if (this.state.deliveriesToday[i][0] == id) {
                if (this.state.deliveriesToday[i][1] === true) {
                    return { backgroundColor: 'rgba(155, 155, 155, 0.6)' } //grey
                } else {
                    return { backgroundColor: 'rgba(127, 190, 65, 0.6)' } //fgreen
                }
            }
        }

    }
    async finishRoute() {

        ///////////////////////////////Clear assigned products for each farm
        let allUnassigned = { "TANK1": { "MainProduct": "None", "Products": ["No Product"], "Amounts": [] }, "TANK2": { "MainProduct": "None", "Products": ["No Product"], "Amounts": [] }, "TANK3": { "MainProduct": "None", "Products": ["No Product"], "Amounts": [] }, "TANK4": { "Products": ["No Product"], "Amounts": [] }, "TANK5": { "Products": ["No Product"], "Amounts": [] }, "TANK6": { "Products": ["No Product"], "Amounts": [] } }
        for (let i = 0; i < this.state.dataSource.length; i++) {
            var requestBody = {
                context: 'updateFarmProducts',
                FarmId: this.state.dataSource[i][0],
                ProductInfo: JSON.stringify(allUnassigned)
            }
            let r = await setDataAPI(requestBody)
        }

        //////////////////////////////

        console.log(this.props.route.params.user)
        var requestBody = {
            context: 'finishRoute',
            routeCode: this.props.route.params.routeCode,
            user: this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName
        }
        var data = await setDataAPI(requestBody)



        this.props.navigation.navigate('Home')
    }

    renderConfirmModal() {
        return (
            <View style={[styles.modalView, { flexDirection: 'column', justifyContent: 'center' }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>This will unassign you from this route!</Title>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10 }}>
                    <Button color={'#f0f0f0'} mode='contained' onPress={this.hideConfirm} > Cancel </Button>
                    <Button color={'#be4141'} mode='contained' onPress={() => { this.finishRoute() }}> Okay </Button>
                </View >
            </View>
        )
    }
    showConfirm = () => {
        this.setState({ confirmVisible: true })
    }
    hideConfirm = () => {
        this.setState({ confirmVisible: false })
    }

    getRenderCompleteRoute() {
        return (
            <View>
                <TouchableOpacity
                    onPress={this.showConfirm}
                    style={styles.button_alt}>
                    <View>
                        <Text style={styles.buttonTitle}>{"Finish Route"}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
    totalProductsForFarm(pl) {
        let result = []
        console.log(pl)
        if (pl.TANK1.Products != "No Product") {
            for (let i = 0; i < pl.TANK1.Products.length; i++) {
                result.push([pl.TANK1.Products[i], Number(pl.TANK1.Amounts[i])])
            }
        }
        if (pl.TANK2.Products != "No Product") {
            for (let i = 0; i < pl.TANK2.Products.length; i++) {
                let exists = false
                for (let j = 0; j < result.length; j++) {
                    if (result[j][0] == pl.TANK2.Products[i]) {
                        exists = true
                        result[j][1] = result[j][1] + Number(pl.TANK2.Amounts[i])
                    }
                }
                if (!exists) {
                    result.push([pl.TANK2.Products[i], Number(pl.TANK2.Amounts[i])])
                }
            }
        }
        if (pl.TANK3.Products != "No Product") {
            for (let i = 0; i < pl.TANK3.Products.length; i++) {
                let exists = false
                for (let j = 0; j < result.length; j++) {
                    if (result[j][0] == pl.TANK3.Products[i]) {
                        exists = true
                        result[j][1] = result[j][1] + Number(pl.TANK3.Amounts[i])
                    }
                }
                if (!exists) {
                    result.push([pl.TANK3.Products[i], Number(pl.TANK3.Amounts[i])])
                }
            }
        }
        if (pl.TANK4.Products != "No Product") {
            for (let i = 0; i < pl.TANK4.Products.length; i++) {
                let exists = false
                for (let j = 0; j < result.length; j++) {
                    if (result[j][0] == pl.TANK4.Products[i]) {
                        exists = true
                        result[j][1] = result[j][1] + Number(pl.TANK4.Amounts[i])
                    }
                }
                if (!exists) {
                    result.push([pl.TANK4.Products[i], Number(pl.TANK4.Amounts[i])])
                }
            }
        }
        if (pl.TANK5.Products != "No Product") {
            for (let i = 0; i < pl.TANK5.Products.length; i++) {
                let exists = false
                for (let j = 0; j < result.length; j++) {
                    if (result[j][0] == pl.TANK5.Products[i]) {
                        exists = true
                        result[j][1] = result[j][1] + Number(pl.TANK5.Amounts[i])
                    }
                }
                if (!exists) {
                    result.push([pl.TANK5.Products[i], Number(pl.TANK5.Amounts[i])])
                }
            }
        }
        if (pl.TANK6.Products != "No Product") {
            for (let i = 0; i < pl.TANK6.Products.length; i++) {
                let exists = false
                for (let j = 0; j < result.length; j++) {
                    if (result[j][0] == pl.TANK6.Products[i]) {
                        exists = true
                        result[j][1] = result[j][1] + Number(pl.TANK6.Amounts[i])
                    }
                }
                if (!exists) {
                    result.push([pl.TANK6.Products[i], Number(pl.TANK6.Amounts[i])])
                }
            }
        }
        console.log(result)
        return result
    }
    getRequiredProducts(id) {
        let result = ""
        for (let i = 0; i < this.state.routeProducts.length; i++) {
            if (this.state.routeProducts[i][1] == id) {
                let json = JSON.parse(this.state.routeProducts[i][0])
                if (json != null && json != undefined) {
                    let tpff = this.totalProductsForFarm(json)
                    for (let j = 0; j < tpff.length; j++) {
                        result = result + tpff[j][0] + " - " + tpff[j][1] + "kg "
                    }
                    console.log(json)
                }
            }
        }
        return result == "" ? "" : "\n : " + result
    }
    getOfflineDateText(id) {
        for (let i = 0; i < this.state.farmsOffline.length; i++) {
            if (id == this.state.farmsOffline[i][1]) {
                return ' - Off ' + Moment(this.state.farmsOffline[i][2]).format('DD/MM/YY') + ' until ' + Moment(this.state.farmsOffline[i][3]).format('DD/MM/YY')
            }
        }
        return ''
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <Text style={styles.title}>Route {this.props.route.params.routeCode}</Text>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }

        return (
            <View style={{ flex: 1, paddingTop: 20 }}>
                <View style={{ justifyContent: 'center', alignContent: 'center' }}>
                    <Text style={[styles.title, { marginBottom: 10 }]}>Route {this.props.route.params.routeCode}</Text>
                    <Text style={[styles.subTitle, { marginBottom: 15, textAlign: 'center' }]}> {this.state.routeNote == "" ? "" : "Note : " + this.state.routeNote}</Text>
                </View>
                {this.getTotalProductsNeeded()}
                <SafeAreaView style={{ borderWidth: 1, borderColor: 'rgba(255,255,255,0)', borderTopColor: '#000', borderBottomColor: '#000', backgroundColor: 'rgb(233,233,233)', height: this.state.screenHeight - 400 }}>
                    <ScrollView style={{ flex: 3 }}>
                        <List.Section style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
                            {this.formatData(this.state.dataSource).map((item, index) => {
                                return (
                                    <List.Item style={[{ borderWidth: 1, borderColor: 'rgb(100,100,100)', borderRadius: 10, marginVertical: 5, marginHorizontal: 15 }, this.getFarmBtnColor(item[0])]}
                                        onPress={() => this.props.navigation.navigate('Farm Details', {
                                            Id: item[0],
                                            CompanyName: item[1],
                                            BoxName: item[2],
                                            DeviceId: item[4],
                                            user: this.props.route.params.user
                                        })}
                                        title={item[1]}
                                        description={item[2] + this.getOfflineDateText(item[0]) + this.getRequiredProducts(item[0])}
                                        left={props => <List.Icon {...props} icon={this.getFarmBtnIcon(item[0])} />}
                                        key={index}
                                    />)
                            })}
                        </List.Section>
                        <Portal>
                            <Modal visible={this.state.confirmVisible} onDismiss={() => { this.hideConfirm }}>
                                {this.renderConfirmModal()}
                            </Modal>
                        </Portal>
                    </ScrollView>
                </SafeAreaView>
                {this.getRenderCompleteRoute()}
            </View >
        );
    }
}