import '@firebase/auth';
import '@firebase/firestore';

import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCwtu3hxknIsjE-udu3JxqJVf0iG-hBGTM",
    authDomain: "erp-dev-c0923.firebaseapp.com",
    databaseURL: "https://erp-dev-c0923.firebaseio.com",
    projectId: "erp-dev-c0923",
    storageBucket: "erp-dev-c0923.appspot.com",
    messagingSenderId: "453877252771",
    appId: "1:453877252771:web:c9f6b266c64c0d0ae1b516",
    measurementId: "G-1J5XLKFGP8"
};

firebase.default.initializeApp(firebaseConfig);


export { firebase };