import '../../globals.js'

import * as React from 'react';

import { ActivityIndicator, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { Button, Caption, DataTable, Modal, Portal, Snackbar, TextInput, Title } from 'react-native-paper';
import { Linking, Platform } from 'react-native'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import CalendarPicker from 'react-native-calendar-picker';
import { Entypo } from '@expo/vector-icons';
import FarmIssues from '../_shared/FarmIssues.js';
import Moment from 'moment'
import TankLevelsDisplay from '../_shared/TankLevelsDisplay.js';
import { captureScreen } from 'react-native-view-shot';
import { showMessage } from 'react-native-flash-message'
import styles from '../../styles';

export default class FarmDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { toastVisible: false, calendarVisible: false, screenshot: {}, isLoading: true, mineralNotesFocused: false, farmNotesFocused: false, tankCount: 0, productVisible: false, prod: [], farmNotesTextArea: '', mineralNotesTextArea: '', accordian: false, notesExpanded: false, tankVolumes: [[], [], [], [], [], []] };
        this.setState({
            selectedStartDate: null, selectedEndDate: null, offlineDatesExisted: false
        })
        this.onDateChange = this.onDateChange.bind(this);
    }

    callManager() {
        let phoneNumber = '';
        if (Platform.OS === 'android') { phoneNumber = `tel:${this.state.phoneNo}`; }
        else { phoneNumber = `tel://${this.state.phoneNo}`; }
        Linking.openURL(phoneNumber);
    }

    getDirections() {
        var url = "https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=" + this.state.lat + "," + this.state.long;
        //var iosURL = "http://maps.apple.com/?daddr=" + this.state.lat + "," + this.state.long;
        if (Platform.OS === 'ios') {
            if (this.state.lat == null || this.state.long == null) {
                console.log('Can\'t handle url: ' + url);
                alert("There is not latitude or longitude: ", this.state.lat, this.state.long)
            }
            else {
                return Linking.openURL(url);
            }
        }
        else if (Platform.OS === 'android') {
            Linking.canOpenURL(url).then(supported => {
                if (!supported) {
                    console.log('Can\'t handle url: ' + url);
                    alert("The redirect url is not working: ", url)
                }
                else {
                    if (this.state.lat == null || this.state.long == null) {
                        console.log('Can\'t handle url: ' + url);
                        alert("There is not latitude or longitude: ", this.state.lat, this.state.long)
                    }
                    else {
                        return Linking.openURL(url);
                    }
                }
            }).catch(err => alert('An error occurred', err));
        }
        else {
            try {
                Linking.canOpenURL(url).then(supported => {
                    if (!supported) {
                        console.log('Can\'t handle url: ' + url);
                        alert("The redirect url is not working: ", url)
                    }
                    else {
                        if (this.state.lat == null || this.state.long == null) {
                            console.log('Can\'t handle url: ' + url);
                            alert("There is not latitude or longitude: ", this.state.lat, this.state.long)
                        }
                        else {
                            return Linking.openURL(url);
                        }
                    }
                }).catch(err => alert('An error occurred', err));
            }
            catch {
                Linking.canOpenURL(iosURL).then(supported => {
                    if (!supported) {
                        console.log('Can\'t handle url: ' + iosURL);
                        alert("The redirect url is not working: ", iosURL)
                    }
                    else {
                        if (this.state.lat == null || this.state.long == null) {
                            console.log('Can\'t handle url: ' + iosURL);
                            alert("There is not latitude or longitude: ", this.state.lat, this.state.long)
                        }
                        else {
                            return Linking.openURL(iosURL);
                        }
                    }
                }).catch(err => alert('An error occurred', err));
            }
        }
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ toastVisible: false, calendarVisible: false, screenshot: {}, isLoading: true, tankCount: 0, mineralNotesFocused: false, farmNotesFocused: false, productVisible: false, prod: [], farmNotesTextArea: '', mineralNotesTextArea: '', accordian: false, notesExpanded: false, tankVolumes: [[], [], [], [], [], []] })
            this.deliveryResult()
            this.getTankCount()
            this.getData()
        })
    }

    async getOfflineTankVolumes() {
        var requestBody = {
            context: 'getOfflineTankVolumes',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState({ offlineVolumeData: data })
    }

    showProductModal = () => {
        if (this.state.prod != null && this.state.prod != undefined) {
            if (this.state.prod.length > 0) {
                this.setState({ productVisible: true })
            }
        }
    }
    hideProductModal = () => {
        this.setState({ productVisible: false })
    }
    getProductRows(t) {
        if (this.state.prod != undefined) {
            if (this.state.prod[t] != undefined) {
                let tank = this.state.prod[t]
                let products = tank.Products
                let amounts = tank.Amounts
                return products.map((item, index) => {
                    console.log(item)
                    if (item != 'No Product') {
                        return (
                            <DataTable.Row onPress={() => { }}>
                                <DataTable.Cell>
                                    {item}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {amounts[index]} kg
                </DataTable.Cell>
                            </DataTable.Row>
                        )
                    }
                })
            }
        }
    }
    getTotalProductsNeeded() {
        console.log(this.state.prod)
        return (
            <SafeAreaView style={[styles.modalView, { margin: '5%', width: '90%', height: this.state.screenHeight - 80 }]}>
                <ScrollView>
                    {
                        <DataTable style={{ marginBottom: 5, backgroundColor: 'rgb(233,233,233)', borderWidth: 1, borderColor: 'rgba(255,255,255,0)', borderTopColor: '#000', borderBottomColor: '#000' }}>
                            <DataTable.Header>
                                <DataTable.Title>
                                    Product
              </DataTable.Title>
                                <DataTable.Title>
                                    Amount
              </DataTable.Title>
                            </DataTable.Header>
                            {this.getProductRows(0)}
                            {this.getProductRows(1)}
                            {this.getProductRows(2)}
                            {this.getProductRows(3)}
                            {this.getProductRows(4)}
                            {this.getProductRows(5)}
                        </DataTable>
                    }
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity style={[styles.button, { width: '30%', marginVertical: '4%' }]}
                            onPress={() => { this.hideProductModal() }}>
                            <Text style={styles.buttonTitle}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </SafeAreaView>
        )
    }
    deliveryResult() {
        if (!this.state.MessageReceived) {
            if (this.props.route.params.DeliveryMessage && this.props.route.params.DeliveryMessage == 'success') {
                this.setState({
                    MessageReceived: true
                })
                showMessage({
                    message: "Delivery Recorded Successfully!",
                    type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
                })
            } else if (this.props.route.params.DeliveryMessage && this.props.route.params.DeliveryMessage[0] == 'error') {
                this.setState({
                    MessageReceived: true
                })
                showMessage({
                    message: "An error occurred while saving delivery. We will try again in the background.",
                    type: "danger"
                })
            }
            else {
                this.setState({
                    MessageReceived: false
                })
            }

            this.setState({
                DeliveryMessage: ['']
            })
        }
    }
    async getData() {
        if (this.props.route.params.user.userAccess == 'Normal') {
            await this.getIssues(this.props.route.params.Id)
        } else {
            await this.getAdminIssues(this.props.route.params.Id)
        }
        await this.getFarmCalendar()
        await this.getProductData()
        var requestBody = {
            context: 'farmDetails',
            Id: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            dataSource: data,
            farmNotesTextArea: data[0][7],
            mineralNotesTextArea: data[0][8],
            phoneNo: data[0][6],
            manager: data[0][5],
            lat: data[0][3],
            long: data[0][4],
            farmName: data[0][2],
            boxStatus: data[0][10]
        })

    }

    async getProductData() {
        let requestBody = {
            context: 'getProductsAssigned',
            FarmId: this.props.route.params.Id
        }
        let data = await getDataAPI(requestBody)
        let cleanProducts = []
        for (let i = 0; i < data.length; i++) {
            if (typeof data[i][0] === 'string') {
                if (data[i][0].length > 0) {
                    cleanProducts.push(JSON.parse(data[i][0]));
                }
            }
        }
        if (cleanProducts[0] != undefined && cleanProducts[0] != null) {
            this.setState({
                prod: Object.values(cleanProducts[0]),
            })
            //console.log(Object.values(cleanProducts[0]))
        }
    }
    async getIssues() {
        var requestBody = {
            context: 'farmIssues',
            Id: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            farmIssues: data
        })
    }

    async getAdminIssues() {
        var requestBody = {
            context: 'farmIssuesAdmin',
            Id: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            farmIssues: data
        })
    }

    async updateIssues(withId, userPrivs) {
        var ctx = 'farmIssues'
        if (userPrivs == 'Admin') {
            ctx = 'farmIssuesAdmin'
        }
        var requestBody = {
            context: ctx,
            Id: withId
        }
        var data = await getDataAPI(requestBody)
        return data
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async getTankCount() {
        console.log(this.props.route.params)
        var requestBody = {
            context: 'tankCount',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState({
            tankCount: data[0][0] != undefined ? data[0][0] : 0
        })

        await this.getOfflineTankVolumes()
        await this.getTankVolume()
        // await this.setStateAsync({ tankCount: data, });
    }

    async getTankVolume() {
        var requestBody = {
            context: 'tankVolumes',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        if (data === undefined) {
            this.setState({
                tankCount: 0,
                isLoading: false
            });
        }
        else if (data.length == 0) { //offline device here, no current levels so need data to pass to component
            var offlineVolumeData = this.state.offlineVolumeData
            if (offlineVolumeData) {
                if (offlineVolumeData.length > 0) {
                    this.setState({
                        tankVolumes: offlineVolumeData, /*New data goes in here for offline boxes*/
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        tankCount: 0,
                        isLoading: false
                    });
                }
            }
            else {
                console.log("This is a strange place to be - no data from online or offline!")
                this.setState({
                    tankCount: 0,
                    isLoading: false
                });
            }

        }
        else {
            console.log("THIS IS THE tankVolumes data format: ", data)
            await this.getCheckList()
            this.setState({
                tankVolumes: data,
                isLoading: false
            });

        }
    }

    async saveNotes() {
        var requestBody = {
            context: 'farmDetails',
            FarmNotes: this.state.farmNotesTextArea == null ? '' : this.state.farmNotesTextArea.replace(/'/g, "''"),
            MineralNotes: this.state.mineralNotesTextArea == null ? '' : this.state.mineralNotesTextArea.replace(/'/g, "''"),
            Id: this.props.route.params.Id
        }
        var data = await setDataAPI(requestBody)
        this.setState({
            isLoading: false,
            dataSourceSaveNotes: data,
            notesExpanded: false
        })
        if (data[0] == 'success') {
            showMessage({
                message: "Notes Updated",
                type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
            })
            data[0] = ''
        } else if (data[0] == 'error occurred' || !data) {
            showMessage({
                message: "Error updating notes. Please try again.",
                type: "danger"
            })
            data[0] = ''
        }

    }

    getButtonStyle() {
        if (this.state.lat == null || this.state.long == null) {
            return styles.greyButtonNav
        }
        else { return styles.button }
    }
    getProductsButtonStyle() {
        if (this.state.prod != null && this.state.prod != undefined) {
            if (this.state.prod[0] != undefined) {
                if (typeof this.state.prod[0].Products[0] === 'string') {
                    return styles.button
                }
            }
        }
        return styles.greyButtonNav
    }

    getNotesSection() {
        if (!this.state.notesExpanded) {
            return (
                <View style={styles.viewContainer} onStartShouldSetResponder={() => this.setState({ notesExpanded: true })} >
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={[{ margin: 10, marginBottom: 15, marginLeft: 15, flex: 7 }, styles.title]}>Notes</Text><Caption style={{ flex: 3 }}> (click to open)</Caption>
                    </View>
                </View>
            )
        }
        else {
            return (
                <View style={styles.viewContainer}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={[{ margin: 5, marginBottom: 15, marginLeft: 15, flex: 7 }, styles.title]}>Notes</Text>
                        <TouchableOpacity style={[styles.button, { flex: 3, height: 32, borderRadius: 16, marginBottom: 16 }]}
                            onPress={() => { this.setState({ notesExpanded: false }) }}>
                            <Text style={styles.buttonTitle}>Close</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            )
        }
    }

    getBoxStatus() {
            const status = this.state.boxStatus

            if (status == 20) {// Orderes
                return 'Orderes'
            }
            else if (status == 30) {// Configured
                return 'Configured'
            }
            else if (status == 50) {// Operating
                return 'Operating'
            }
            else if (status == 60) {// Offline
                return 'Offline'
            }
            else if (status == 70) {// Out of Service
                return 'Out of Service'
            }
            else if (status == 90) {// Deleted
                return 'Deleted'
            }

        return 'N/A'

    }

    getBoxStatusColour() {
            const status = this.state.boxStatus

            if (status == 20) {// Orderes
                return '#1d2fcb'
            }
            else if (status == 30) {// Configured
                return '#c9b150'
            }
            else if (status == 50) {// Operating
                return '#7fbe41'
            }
            else if (status == 60) {// Offline
                return '#FF0000'
            }
            else if (status == 70) {// Out of Service
                return '#504c4c'
            }
            else if (status == 90) {// Deleted
                return '#0c0c0c'
            }

        return '#7fbe41'

    }

    getChecklistColour() {
        //console.log("Checklist", this.state.checkList)
        // return '#7fbe41'
        let checkerArr = this.state.checkList == null ? [] : this.state.checkList
        if (checkerArr.length == 0) { return '#FF0000' }
        for (let i = 0; i < checkerArr.length; i++) {
            if (checkerArr[i][2] == 0) {//checks pulses per active pump --returns red if no pulses
                return '#FF0000'
            }
            else if (checkerArr[i][4] == 0) {//checks herd# per active pump --returns red if no lsu
                return '#FF0000'
            }
            else if (checkerArr[i][6] == 'FALSE') {//checks if device pwr alarm --returns red if false
                return '#FF0000'
            }
            else if (checkerArr[i][7] == 'TRUE') {//checks if device was communicating in last 2 hours --returns red if false
                return '#FF0000'
            }
            else if (checkerArr[i][9] == 'No Water') {//checks if device has waterflow --returns red if false
                return '#FF0000'
            }
            else if (checkerArr[i][10] < 1) {//checks if device sensor has moved --returns red if false
                return '#FF0000'
            }
        }
        return '#7fbe41'

    }

    async getCheckList() {
        var requestBody = {
            context: 'getChecklist',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        this.setState(
            {
                checkList: data
            })

        return
    }

    getMoreDetailsButtons() {
        return (
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 50, marginTop: 20 }}>
                <TouchableOpacity style={{ width: '40%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center', height: 50, marginLeft: '7%', marginRight: '3%' }}
                    onPress={() => this.props.navigation.navigate('Admin Extra Functions', {
                        DeviceId: this.props.route.params.DeviceId,
                        user: this.props.route.params.user
                    })}>
                    <Text style={styles.buttonTitle}>More details</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ width: '40%', backgroundColor: this.getChecklistColour(), borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center', height: 50, marginLeft: '3%', marginRight: '7%' }}
                    onPress={() => this.props.navigation.navigate('Device checklist', {
                        DeviceId: this.props.route.params.DeviceId,
                        user: this.props.route.params.user,
                        checkList: this.state.checkList ? this.state.checkList : 'No checklist',
                        Id: this.props.route.params.Id
                    })}>
                    <Text style={styles.buttonTitle}>Device Checklist</Text>
                </TouchableOpacity>
            </View>
        )
    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    _saveScreenshotAsync = async () => {
        try {
            await captureScreen({
                format: "png",
                quality: 0.8
            }).then(
                uri => this.setState({ screenshot: uri, isLoading: true }),
                error => console.error("Oops, snapshot failed", error)
            )

        } catch (e) {
            console.log("ERROR: ", e)
        }


    }

    showCalendar = () => {
        this.setState({ calendarVisible: true })
    }
    hideCalendar = () => {
        this.setState({ calendarVisible: false })
    }
    onDateChange(date, type) {
        if (type === 'END_DATE') {
            this.setState({
                selectedEndDate: date,
            });
        } else {
            this.setState({
                selectedStartDate: date,
                selectedEndDate: null,
            });
        }
    }
    async markFarmOffline(FarmId, StartDate, EndDate, CreatedBy) {
        var requestBody = {
            context: 'markFarmOffline',
            FarmId: FarmId,
            StartDate: StartDate,
            EndDate: EndDate,
            CreatedBy: CreatedBy
        }
        var data = await setDataAPI(requestBody)
        if (data[0] == 'success') {
            showMessage({
                message: "Calendar Updated",
                type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
            })
            data[0] = ''
        } else if (data[0] == 'error' || !data) {
            showMessage({
                message: "Error updating calendar. Is farm already switched off?",
                type: "danger"
            })
            data[0] = ''
        }
    }
    async unmarkFarmOffline(FarmId) {
        var requestBody = {
            context: 'unmarkFarmOffline',
            FarmId: FarmId
        }
        var data = await setDataAPI(requestBody)
        if (data[0] == 'success') {
            showMessage({
                message: "Calendar Updated",
                type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
            })
            data[0] = ''
        }
    }
    setOffline() {
        const { selectedStartDate, selectedEndDate } = this.state;
        if (selectedStartDate == null) {
            alert('No start date selected')
            return
        }
        else if (selectedEndDate == null) {
            alert('No end date selected')
            return
        }
        else {
            this.markFarmOffline(this.props.route.params.Id, selectedStartDate, selectedEndDate,
                (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName))
            this.hideCalendar()
            return
        }
    }
    clearOffline() {
        this.unmarkFarmOffline(this.props.route.params.Id)
        this.setState({
            selectedStartDate: null,
            selectedEndDate: null,
            offlineDatesExisted: false
        })
        this.hideCalendar()
        return
    }
    async getFarmCalendar() {
        var requestBody = {
            context: 'calendar',
            FarmId: this.props.route.params.Id
        }
        var data = await getDataAPI(requestBody)
        console.log(data)
        if (data != [] && data != null && data != undefined) {
            if (data[0] != undefined) {
                console.log(data)
                this.onDateChange(data[0][2], 'START_DATE')
                this.onDateChange(data[0][3], 'END_DATE')
                this.setState({
                    offlineDatesExisted: true
                })
            }
        }
    }
    renderCalendarModal() {
        const { selectedStartDate, selectedEndDate } = this.state;
        const minDate = new Date(); // Today
        const maxDate = new Date(2026, 12, 31);
        const startDate = selectedStartDate ? Moment(selectedStartDate).format(' DD / MM / YY') : ' Not Selected';
        const endDate = selectedEndDate ? Moment(selectedEndDate).format(' DD / MM / YY') : ' Not Selected';

        return (
            <View style={[styles.modalView, { flexDirection: 'column', justifyContent: 'center', height: '80%', marginLeft: 0, marginRight: 0 }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>Switch Farm Off</Title>
                <CalendarPicker
                    startFromMonday={true}
                    allowRangeSelection={true}
                    minDate={minDate}
                    maxDate={maxDate}
                    todayBackgroundColor="#f2e6ff"
                    selectedDayColor="#7fbe41"
                    selectedDayTextColor="#FFFFFF"
                    selectedStartDate={this.state.selectedStartDate ? this.state.selectedStartDate : null}
                    selectedEndDate={this.state.selectedEndDate ? this.state.selectedEndDate : null}
                    onDateChange={this.onDateChange}
                />
                <View style={{ flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text>Start Date:{startDate}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text>End Date:{endDate}</Text>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginBottom: '2%', marginTop: '3%' }}>
                    <Button style={{ height: 48 }} color={'#7fbe41'} mode='contained' onPress={() => { this.setOffline() }} > Switch farm off for date range </Button>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <Button style={{ marginRight: '1%', height: 48 }} color={'#b20000'} mode='contained' onPress={() => { this.clearOffline() }} > Clear current setting </Button>
                    <Button style={{ marginLeft: '1%', height: 48 }} color={'#d0d0d0'} mode='contained' onPress={this.hideCalendar} > Close </Button>
                </View>
            </View >
        )
    }
    renderOffline() {
        const { selectedStartDate, selectedEndDate } = this.state;
        const startDate = selectedStartDate ? Moment(selectedStartDate).format(' DD/MM/YY') : ' Not Selected';
        const endDate = selectedEndDate ? Moment(selectedEndDate).format(' DD/MM/YY') : ' Not Selected';

        if (endDate != ' Not Selected') {
            return (
                <View style={{ flex: 1, padding: 5, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={[styles.title, { color: 'red', textAlignVertical: "center", textAlign: "center" }]}>Farm is switched off from {startDate} until {endDate}</Text>
                </View>
            )
        } else {
            return
        }
    }
    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 5 }}>
                    <Text style={styles.title}>Farm name: <Text style={styles.label}>{this.props.route.params.CompanyName}</Text></Text>
                    <Text style={styles.title}>Unit: <Text style={styles.label}>{this.state.farmName}</Text></Text>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }

        return (
            <View style={{ flex: 1, paddingTop: 5, height: '100%' }}>
                <View style={{ flex: 1, flexDirection: 'column' }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={styles.title}>Unit: <Text style={styles.label}>{this.state.farmName}</Text></Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={styles.title}>Manager: <Text style={styles.label}>{this.state.manager}</Text></Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={styles.title} onPress={() => { this.callManager() }}>Phone: <Text style={styles.phoneText}>{this.state.phoneNo}</Text></Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={styles.title}>Status: <Text style={styles.label}>{this.getBoxStatus()}</Text></Text>
                    </View>
                </View>
                <Portal>
                    <Modal visible={this.state.calendarVisible} onDismiss={this.hideCalendar}>
                        {this.renderCalendarModal()}
                    </Modal>
                </Portal>
                <Portal>
                    <Modal visible={this.state.productVisible} onDismiss={() => { this.hideProductModal() }}>
                        {this.getTotalProductsNeeded()}
                    </Modal>
                </Portal>
                {this.state.offlineDatesExisted &&
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {this.renderOffline()}
                        </View>
                    </View>
                }
                <View style={{ flex: 1, flexDirection: 'column' }}>
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity style={[this.getButtonStyle(), { width: '28%', marginLeft: '4%', marginRight: 0 }]}
                            onPress={() => { this.getDirections() }}>
                            <Text style={[styles.buttonTitle, { fontSize: 14 }]}>Take me here!</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[this.getProductsButtonStyle(), { marginLeft: '2%', marginRight: '2%', width: '28%' }]}
                            onPress={() => { this.showProductModal() }}>
                            <Text style={[styles.buttonTitle, { fontSize: 14 }]}>View Products</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, { width: '28%', marginLeft: 0, marginRight: '4%' }]}
                            onPress={() => this.props.navigation.navigate('Delivery Details', {
                                Id: this.props.route.params.Id,
                                user: this.props.route.params.user,
                                DeviceId: this.props.route.params.DeviceId,
                                customer: this.props.route.params.CompanyName
                            })}>
                            <Text style={styles.buttonTitle}>Deliveries</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '1%' }}>
                    <Button mode="contained" onPress={() => { this.showCalendar() }} icon={"calendar"}>Calendar</Button>
                </View>
                { this.state.tankCount > 0 &&
                    <View style={{ marginVertical: '2%' }}>
                        <TankLevelsDisplay  {...this.props} tankVolumes={this.state.tankVolumes} tankCount={this.state.tankCount} />
                    </View>
                }
                <View style={{ flex: 1, width: '80%', flexDirection: 'row', marginHorizontal: '10%', alignItems: 'center', justifyContent: 'center' }}>
                    <TextInput
                        style={[styles.inputContainerStyle, styles.textArea, { width: '80%', height: this.state.mineralNotesFocused ? 128 : 48 }]}
                        label="Mineral notes"
                        multiline
                        placeholder="Needs more Phos...etc"
                        onFocus={() => { this.setState({ mineralNotesFocused: true }) }}
                        onBlur={() => { this.setState({ mineralNotesFocused: false }) }}
                        theme={{
                            colors: {
                                placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                            }
                        }}
                        value={this.state.mineralNotesTextArea}
                        onChangeText={mineralNotesTextArea => this.setState({ mineralNotesTextArea })
                        }
                    />
                    <TouchableOpacity style={[styles.buttonSlim, { marginTop: 20, marginLeft: 0, marginRight: 0, width: '20%' }]}
                        onPress={() => { this.saveNotes() }}>
                        <Text style={styles.buttonTitle}>Save</Text>
                    </TouchableOpacity>
                </View>

                <View style={{ flex: 1, width: '80%', flexDirection: 'row', marginHorizontal: '10%', alignItems: 'center', justifyContent: 'center' }}>
                    <TextInput
                        style={[styles.inputContainerStyle, styles.textArea, { width: '80%', height: this.state.farmNotesFocused ? 128 : 48 }]}
                        label="Farm notes"
                        multiline
                        placeholder="Gate code...etc"
                        onFocus={() => { this.setState({ farmNotesFocused: true }) }}
                        onBlur={() => { this.setState({ farmNotesFocused: false }) }}
                        theme={{
                            colors: {
                                placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
                                underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
                            }
                        }}
                        value={this.state.farmNotesTextArea}
                        onChangeText={farmNotesTextArea => this.setState({ farmNotesTextArea })
                        }
                    />
                    <TouchableOpacity style={[styles.buttonSlim, { marginTop: 20, marginLeft: 0, marginRight: 0, width: '20%' }]}
                        onPress={() => { this.saveNotes() }}>
                        <Text style={styles.buttonTitle}>Save</Text>
                    </TouchableOpacity>
                </View>

                { this.state.farmIssues &&
                // <View style={{flex: 1}}>
                    <FarmIssues  {...this.props} extraData={this.state.farmIssues} onChange={this.updateIssues} />
                ///* </View> */}
                }



                { this.state.tankCount > 0 &&
                    this.getMoreDetailsButtons()
                }
                { this.state.tankCount <= 0 &&
                    <View style={{ flex: 1, flexDirection: 'row', height: 50, alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                        <TouchableOpacity style={{ width: '80%', backgroundColor: '#7fbe41', borderRadius: 5, borderColor: 'black', borderWidth: 1, alignItems: 'center', justifyContent: 'center', height: 50 }}
                            onPress={() => this.props.navigation.navigate('Admin Extra Functions', {
                                DeviceId: this.props.route.params.DeviceId,
                                user: this.props.route.params.user
                            })}>
                            <Text style={styles.buttonTitle}>More details</Text>
                        </TouchableOpacity>
                    </View>
                }

                <Snackbar
                    visible={this.state.toastVisible}
                    onDismiss={() => this.setState({ toastVisible: false })}
                    action={{
                        label: 'Close',
                        onPress: () => {
                        },
                    }}
                    style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                >
                    <Text>{this.state.toastBody}</Text>
                </Snackbar>

            </View>
        );
    }
}
