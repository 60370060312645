import { ActivityIndicator, Image, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { firebase } from '../../firebase/config.js'
import { setDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default function RegistrationScreen({ navigation }) {
    const [firstName, setFirstName] = useState('')
    const [secondName, setSecondName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setLoading] = useState(false)


    const addUserRole = (data) => {
        var requestBody = {
          context: 'addUserRole',
          FirstName: data['firstName'],
          LastName: data['secondName'],
          UserId: data['id'],
        }
        var data = setDataAPI(requestBody)
      }

    const onFooterLinkPress = () => {
        navigation.navigate('Login')
    }

    const onRegisterPress = () => {
        if (password !== confirmPassword) {
            alert("Passwords don't match.")
            return
        }
        setLoading(true)
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((response) => {
                const uid = response.user.uid
                const data = {
                    id: uid,
                    email,
                    firstName,
                    secondName,
                };
                const usersRef = firebase.firestore().collection('users')
                usersRef
                    .doc(uid)
                    .set(data)
                    .then(() => {
                        addUserRole(data)
                        navigation.navigate('Home', { user: data })
                        setLoading(false)
                    })
                    .catch((error) => {
                        alert(error)
                        setLoading(false)
                    });
            })
            .catch((error) => {
                alert(error)                
                setLoading(false)
            });
    }

    if (isLoading) {
        return (
          <View style={{ flex: 1, padding: 20 }}>
                <ActivityIndicator style={{marginTop: '20%'}} />
          </View>
        );
      }
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <Image
                    style={styles.logo}
                    source={require('../../../assets/splash.png')}
                />
                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                    style={styles.input}
                    placeholder='First Name'
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => setFirstName(text)}
                    value={firstName}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                    style={styles.input}
                    placeholder='Second Name'
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => setSecondName(text)}
                    value={secondName}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                    style={styles.input}
                    placeholder='E-mail'
                    placeholderTextColor="#aaaaaa"
                    onChangeText={(text) => setEmail(text.trim())}
                    value={email}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                    style={styles.input}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder='Password'
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
                <TextInput theme={{ colors: { text: 'rgb(43,43,43)' } }}
                    style={styles.input}
                    placeholderTextColor="#aaaaaa"
                    secureTextEntry
                    placeholder='Confirm Password'
                    onChangeText={(text) => setConfirmPassword(text)}
                    value={confirmPassword}
                    underlineColorAndroid="transparent"
                    autoCapitalize="none"
                />
                <TouchableOpacity
                    style={styles.button}
                    onPress={() => onRegisterPress()}>
                    <Text style={styles.buttonTitle}>Create account</Text>
                </TouchableOpacity>
                <View style={styles.footerView}>
                    <Text style={styles.footerText}>Already got an account? <Text onPress={onFooterLinkPress} style={styles.footerLink}>Log in</Text></Text>
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}