import * as Location from 'expo-location';
import * as React from 'react'

import {ActivityIndicator, AsyncStorage, Image, Linking, Platform, Text, TouchableOpacity, View} from 'react-native'
import {Modal, Portal, Snackbar, Dialog, RadioButton, Button as PButton} from 'react-native-paper';
import {getDataAPI, setDataAPI} from '../_shared/ERP_API'
import {setDataLocal, getDataLocal, removeItemValue} from '../_shared/ERP_Local';

import {Entypo} from '@expo/vector-icons';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import MyRoutes from './MyRoutes'
import {captureScreen} from 'react-native-view-shot';
import {showMessage} from 'react-native-flash-message'
import styles from '../../styles';
import packageJson from '../../../package.json';
import {isNumber} from "firebase-mock/src/lodash";


const containerStyle = {backgroundColor: 'white', padding: 20};


export default class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPriv: '',
            id: props.route.params.user.id,
            pendingExists: false,
            email: props.route.params.user.email,
            username: props.route.params.user.firstName,
            toastVisible: false,
            screenshot: {},
            distributorName: "",
            distributorId: null,
            distrubutor: {},
            distributorList: [],
            partnerVisible: false,
            checkedDistributor: null
        };
    }

    setDistributor(distributorId) {
        // console.log('Set Did ', distributorId)
        const distributorsList = this.state.distributorList;
        const index = distributorsList.findIndex(x => x[0] === distributorId)
        if (index >= 0) {
            this.setState({distributorId: distributorId})
            this.setState({distributorName: distributorsList[index][1]})
            this.setDistributorInAsyncStorage(distributorId).then(r => {
            })
            this.hideDialog()
            this.props.route.params.user.userDistrib = distributorsList[index]
        }
    }

    async setDistributorInAsyncStorage(value) {
        await AsyncStorage.setItem(
            'distributor',
            JSON.stringify(value)
        ).then(r => {
            console.log('Distributor set in async storage')
        })
    }


    hideDialog() {
        this.setState({partnerVisible: false})
    }

    openDialog() {
        this.setState({partnerVisible: true})
    }

    async checkIfAdmin() {
        var requestBody = {
            context: 'getUserPrivs',
            UserId: this.state.id,
        }
        var data = await getDataAPI(requestBody)
        // console.log('Data ',data)
        this.setState({userPriv: data[0][0], currVersion: data[0][1]})

        this.props.route.params.user.userAccess = this.state.userPriv
        if (Platform.OS == 'ios' || Platform.OS == 'android') {
            await this.checkForUpdate()
        }
    }

    adminButton() {
        if (this.state.userPriv == "Admin" || this.state.userPriv == "GlobalAdmin") {
            return <TouchableOpacity style={styles.button_alt}
                                     onPress={() => this.props.navigation.navigate('Admin Management Home', {
                                         user: this.props.route.params.user,
                                         distributorsList: this.state.distributorList,
                                         distributorId: this.state.distributorId,
                                         distributorName: this.state.distributorName
                                     })}>
                <View>
                    <Text style={styles.buttonTitle}>{"Admin Section"}</Text>
                </View>
            </TouchableOpacity>
        }
        return
    }

    customQueryButton() {

        return <TouchableOpacity style={styles.button_alt}
                                 onPress={() => this.props.navigation.navigate('Custom queries', {
                                     user: this.props.route.params.user, distributorId: this.state.distributorId
                                 })}>
            <View>
                <Text style={styles.buttonTitle}>{"Custom queries"}</Text>
            </View>
        </TouchableOpacity>

    }

    hideModal() {
        this.setState({visible: false})
    }

    async checkForUpdate() {
        var links = this.state.links
        if (!this.state.currVersion) {
            // console.log("Current app version: ",this.state.currVersion)
            this.setState({toastVisible: true, toastBody: 'There was a problem getting app version.'})
            this.sleepToast(2000)
        } else if (!this.state.links) {
            //console.log("Database app version: ",links[0][2])
            this.setState({toastVisible: true, toastBody: 'There was a problem getting database version.'})
            this.sleepToast(2000)
        } else {
            if (this.state.currVersion !== links[0][2]) {
                this.setState({visible: true})
            } else {
                console.log("Database app version: ", links[0][2])
                this.setState({toastVisible: true, toastBody: 'Your app is up to date.'})
                this.sleepToast(2000)
            }
        }
    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({toastVisible: false})
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getUpdateTable() {
        // this.setState({isLoading: true})
        var requestBody = {
            context: 'getUpdateLinks'
        }
        var links = await getDataAPI(requestBody)
        this.setState({links: links})

    }

    async retrieveUpdate() {
        var links = this.state.links
        console.log(links)
        console.log(links[0][0])//android
        console.log(links[0][1])//ios
        console.log(links[0][2])//version

        if (Platform.OS == 'ios') {
            //Set the isUpdated flag to true
            var requestBody = {
                context: 'setUpdatedTrue',
                UserId: this.state.id,
                Version: links[0][2]
            }
            var dataTrue = await setDataAPI(requestBody)
            console.log(dataTrue)
            if (dataTrue == 'success') {
                try {
                    Linking.openURL(links[0][1])
                    // console.log("Redirecting to dl")
                } catch (error) {
                    //if error set updated flag back to false
                    var requestBody = {
                        context: 'setUpdatedFalse',
                        UserId: this.state.id,
                        Version: this.state.currVersion
                    }
                    await setDataAPI(requestBody)
                    Alert("Update failed, please restart your app with internet connection.")
                    this.hideModal()
                    this.setState({isLoading: false})
                }
            } else {
                alert("Something went wrong, please try again.")
                this.hideModal()
                this.setState({isLoading: false})
            }
        } else if (Platform.OS == 'android') {//Android
            //Set the isUpdated flag to true
            var requestBody = {
                context: 'setUpdatedTrue',
                UserId: this.state.id,
                Version: links[0][2]
            }
            var dataTrue = await setDataAPI(requestBody)
            console.log(dataTrue)
            if (dataTrue == 'success') {
                try {
                    Linking.openURL(links[0][0])
                    // console.log("Redirecting to dl")
                } catch (error) {
                    //if error set updated flag back to false
                    var requestBody = {
                        context: 'setUpdatedFalse',
                        UserId: this.state.id,
                        Version: this.state.currVersion
                    }
                    await setDataAPI(requestBody)
                    Alert("Update failed, please restart your app with internet connection.")
                    this.hideModal()
                    this.setState({isLoading: false})
                }
            } else {
                alert("Something went wrong, please try again.")
                this.hideModal()
                this.setState({isLoading: false})
            }
        }
    }

    modalItems() {
        if (this.state.isLoading) {
            return (
                <View style={[styles.modalView, {
                    margin: '5%',
                    width: '90%',
                    height: 200,
                    alignItems: 'center',
                    justifyContent: 'center'
                }]}>
                    <ActivityIndicator style={{marginTop: '20%'}}/>
                </View>
            )
        }
        return (
            <View style={[styles.modalView, {margin: '5%', width: '90%', height: 200}]}>
                <Text style={[styles.title, {marginTop: 20, textAlign: 'center'}]}>There is a new update!</Text>
                <Text style={[styles.title, {textAlign: 'center'}]}>Would you like to update now?</Text>
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 10,
                    height: 48
                }}>
                    <TouchableOpacity style={[styles.button_red, {
                        width: '40%',
                        marginVertical: '4%',
                        marginLeft: 20,
                        marginRight: 20
                    }]}
                                      onPress={() => {
                                          this.hideModal()
                                      }}>
                        <Text style={styles.buttonTitle}>No</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.button, {width: '40%', marginVertical: '4%', marginLeft: 20, marginRight: 20}]}
                        onPress={() => {
                            this.retrieveUpdate()
                        }}>
                        <Text style={styles.buttonTitle}>Yes</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    async getLocationAndMap() {
        let {status} = await Location.requestPermissionsAsync();
        if (status !== 'granted') {
            setErrorMsg('Permission to access location was denied');
            alert("Permission for location denied.")
            var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found
            this.setState({imageURL: url})
        } else {
            try {
                var currLocation = await Location.getCurrentPositionAsync({
                    enableHighAccuracy: true,
                    accuracy: Location.Accuracy.Highest
                });
                if (currLocation.coords.latitude != null && currLocation.coords.longitude != null || currLocation == undefined) {
                    var latitude = currLocation.coords.latitude
                    var longitude = currLocation.coords.longitude
                    var url = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=400x400&markers=color:blue%7Clabel:Delivery%7C${latitude},${longitude}&key=${global.Apikey}`

                    this.setState({imageURL: url})
                } else {
                    var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                    this.setState({imageURL: url})
                }
            } catch (error) {
                console.log(error)
                var url = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTMmuw4bmGz9sljJZhOeVtr-mbgK_almFO5wA&usqp=CAU` //random image for no location found

                this.setState({imageURL: url})
            }
        }
    }

    async checkDeliveryNumber() {
        var requestBody = {
            context: 'checkDeliveryNumber'
        }
        var data = await getDataAPI(requestBody)
        return data
    }

    async emailQuantities(reqBody) {
        var emailBody = ``
        for (let i = 0; i < 6; i++) {
            switch (i) {
                case 0:
                    let tank1Det = reqBody['Tank1']
                    if (tank1Det != undefined) {
                        console.log("1", tank1Det)
                        for (let j = 0; j < tank1Det.Batch.length; j++) {
                            if (tank1Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank1Det['tankNo']}<br>
                                                                        Product: ${tank1Det.Batch[j][3]}<br>
                                                                        Batch: ${tank1Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank1Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank1Det.containers[j][0]}<br>
                                                                        ${tank1Det.containers[j][0]} #: ${tank1Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 1:
                    let tank2Det = reqBody['Tank2']
                    if (tank2Det != undefined) {
                        console.log("2", tank2Det)
                        for (let j = 0; j < tank2Det.Batch.length; j++) {
                            if (tank2Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank2Det['tankNo']}<br>
                                                                        Product: ${tank2Det.Batch[j][3]}<br>
                                                                        Batch: ${tank2Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank2Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank2Det.containers[j][0]}<br>
                                                                        ${tank2Det.containers[j][0]} #: ${tank2Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 2:
                    let tank3Det = reqBody['Tank3']
                    if (tank3Det != undefined) {
                        console.log("3", tank3Det)
                        for (let j = 0; j < tank3Det.Batch.length; j++) {
                            if (tank3Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank3Det['tankNo']}<br>
                                                                        Product: ${tank3Det.Batch[j][3]}<br>
                                                                        Batch: ${tank3Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank3Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank3Det.containers[j][0]}<br>
                                                                        ${tank3Det.containers[j][0]} #: ${tank3Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 3:
                    let tank4Det = reqBody['Tank4']
                    if (tank4Det != undefined) {
                        console.log("4", tank4Det.Batch)
                        for (let j = 0; j < tank4Det.Batch.length; j++) {
                            if (tank4Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank4Det['tankNo']}<br>
                                                                        Product: ${tank4Det.Batch[j][3]}<br>
                                                                        Batch: ${tank4Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank4Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank4Det.containers[j][0]}<br>
                                                                        ${tank4Det.containers[j][0]} #: ${tank4Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 4:
                    let tank5Det = reqBody['Tank5']
                    if (tank5Det != undefined) {
                        console.log("5", tank5Det.Batch)
                        for (let j = 0; j < tank5Det.Batch.length; j++) {
                            if (tank5Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank5Det['tankNo']}<br>
                                                                        Product: ${tank5Det.Batch[j][3]}<br>
                                                                        Batch: ${tank5Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank5Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank5Det.containers[j][0]}<br>
                                                                        ${tank5Det.containers[j][0]} #: ${tank5Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
                case 5:
                    let tank6Det = reqBody['Tank6']
                    if (tank6Det != undefined) {
                        console.log("6", tank6Det.Batch)
                        for (let j = 0; j < tank6Det.Batch.length; j++) {
                            if (tank6Det['Product'] != 'No Product') {
                                emailBody = emailBody + `<br>` + `<div>Tank: ${tank6Det['tankNo']}<br>
                                                                        Product: ${tank6Det.Batch[j][3]}<br>
                                                                        Batch: ${tank6Det.Batch[j][1]}<br>
                                                                        Quantity: ${tank6Det.Batch[j][2]}kg<br>
                                                                        Delivery method: ${tank6Det.containers[j][0]}<br>
                                                                        ${tank6Det.containers[j][0]} #: ${tank6Det.containers[j][1]}<br>
                                                                    </div>`
                            }
                        }
                    }
                    break;
            }
        }
        console.log(emailBody)
        return emailBody
    }

    async sendEmail(reqBody) {
        var deliveryNumberString = await this.checkDeliveryNumber()

        var formDate = new Date().toLocaleString()

        var deliveryNumber = parseInt(deliveryNumberString)
        deliveryNumber++

        var requestBody = {
            context: 'emailAccounts',
            Body: `<html>
            <head>
                <title></title>
                <link href="https://svc.webspellchecker.net/spellcheck31/lf/scayt3/ckscayt/css/wsc.css" rel="stylesheet" type="text/css" />
            </head>
            <body>Delivery Docket #${deliveryNumber}<br />
            Date: ${formDate}<br />
            <br />
            Customer name: ${this.props.route.params.customer}<br />
            &nbsp;
            
            &nbsp;</div>
            
            ${await this.emailQuantities(reqBody)}
            
            
            <div>&nbsp;</div>
            
    
            <div>Delivered by: ${this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName}</div>
            <div>Delivery location</div>
            <img src="${this.state.imageURL}" width="450" height="300"></img>
            </body>
            </html>
            `,
            Subject: `Delivery #${deliveryNumber}`
        }
        var data = await setDataAPI(requestBody)
        return data[0]
    }

    async checkSubmissionTime(time) {
        var requestBody = {
            context: 'checkSubmissionTime',
            submissionTime: time
        }
        var data = await getDataAPI(requestBody)
        console.log(data)
        return data[0]
    }

    async checkUndelivered() {
        //check for undelivered
        // get id list, if not null/undefined then loop through the array of ids.
        let retryIdList = await getDataLocal("retryIdList")
        // console.log(retryIdList)
        if (retryIdList != undefined && retryIdList != null) {
            for (let i = 0; i < retryIdList["list"].length; i++) {
                let requestBody = await getDataLocal("failedDelivery" + retryIdList["list"][i])
                console.log(requestBody)
                if (requestBody != null && requestBody != "error" && requestBody != undefined) {
                    if (requestBody['submissionTime'] != undefined && requestBody['submissionTime'] != 'Not Available') {
                        let count = await this.checkSubmissionTime(requestBody['submissionTime'])
                        if (count < 1) {
                            this.setState({
                                pendingExists: true
                            })
                            let result = await setDataAPI(requestBody)
                            console.log(result)
                            if (result[0] != 'success') {
                                //resubmit failed
                                showMessage({
                                    message: "An error occurred while saving delivery.",
                                    type: "danger"
                                })
                            } else {
                                await removeItemValue("failedDelivery" + retryIdList["list"][i])
                                showMessage({
                                    message: "Delivery Recorded Successfully!",
                                    type: "success" //default options are "success" (green), "warning" (orange), "danger" (red), "info" (blue) and "default" (gray).
                                })
                            }
                        } else {
                            //success already happened, remove value
                            await removeItemValue("failedDelivery" + retryIdList["list"][i])
                        }
                    } else {
                        //item is undeliverable
                        await removeItemValue("failedDelivery" + retryIdList["list"][i])
                    }
                }
            }
        } else {
            console.log("Nothing to resubmit!")
        }
    }

    async loadUserDistributorsList() {
        await this.checkIfAdmin()
        var requestBody = {
            context: 'getUserDistrubutors',
            UserId: this.state.id
        }
        var data = await getDataAPI(requestBody)
        var currentUserDistrib = this.props.route.params.user.userDistrib


        this.setState({distributorList: data})
        console.log(this.state.userPriv)
        if (this.state.userPriv == 'GlobalAdmin') {
            data.unshift([null, 'All'])
        }
        // console.log('Data', data)

        const distributorId = await AsyncStorage.getItem('distributor')

        let distributor = null;
        if (distributorId !== "null") {
            distributor = data.find((item) => item[0] == distributorId)
        } else {
            distributor = data[0];
        }
        this.setDistributor(distributor)
        this.setState({distributorName: distributor[1]})
        this.setState({distributorId: distributor[0]})
        this.props.route.params.user.userDistrib = distributor

    }

    componentDidMount() {
        this.checkUndelivered()
        this.getUpdateTable()
        this.loadUserDistributorsList()
    }

    _saveScreenshotAsync = async () => {
        try {
            await captureScreen({
                format: "png",
                quality: 0.8
            }).then(
                uri => this.setState({screenshot: uri}),
                error => console.error("Oops, snapshot failed", error)
            )

        } catch (e) {
            console.log("ERROR: ", e)
        }


    }


    clearCacheData = () => {
        caches.keys().then((names) => {
            console.log(names)
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        console.log('Complete Cache Cleared')
    };

    render() {
        return (
            <View style={[styles.container, {justifyContent: 'space-between'}]}>
                <KeyboardAwareScrollView
                    style={{flex: 1, width: '100%'}}
                    keyboardShouldPersistTaps="always">
                    <View>
                        <Text style={[styles.title, {paddingTop: 15}]}>Welcome, {this.state.username}</Text>
                        {this.state.distributorList && this.state.distributorList.length > 1 &&
                            <TouchableOpacity
                                onPress={() => this.openDialog()}
                                style={styles.button_switch}>
                                <View>
                                    <Text style={styles.buttonTitle}>{this.state.distributorName}</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        <Portal>
                            <Modal visible={this.state.partnerVisible} onDismiss={() => {
                                this.hideDialog()
                            }} contentContainerStyle={containerStyle}>
                                <View>
                                    {/*<Text style={[styles.title, { paddingTop: 15 }]}>Select Distributor</Text>*/}
                                    <RadioButton.Group onValueChange={value => this.setDistributor(value)}
                                                       value={this.state.distributorId}>
                                        {this.state.distributorList && this.state.distributorList.map((value, index) => {
                                            return (<RadioButton.Item key={index} label={value[1]} value={value[0]}/>)
                                        })
                                        }
                                    </RadioButton.Group>
                                </View>
                            </Modal>
                        </Portal>
                        <TouchableOpacity
                            onPress={() => this.props.navigation.navigate('Search Farms', {
                                user: this.props.route.params.user,
                            })}
                            style={styles.button_alt}>
                            <View>
                                <Text style={styles.buttonTitle}>{"Search Farms"}</Text>
                            </View>
                        </TouchableOpacity>
                        {
                            this.adminButton()
                        }
                        {
                            this.customQueryButton()
                        }
                        <Text style={styles.title}> </Text>
                        <Text style={styles.title}>My Routes</Text>
                        <MyRoutes {...this.props} extraData={this.props.route.params.user}/>
                        {
                            this.state.pendingExists &&
                            <TouchableOpacity
                                onPress={() => this.checkUndelivered()}
                                style={styles.button_alt}>
                                <View>
                                    <Text style={styles.buttonTitle}>{"Submit Pending Deliveries"}</Text>
                                </View>
                            </TouchableOpacity>
                        }

                        <Portal>
                            <Modal visible={this.state.visible} onDismiss={() => {
                                this.hideModal()
                            }}>
                                {this.modalItems()}
                            </Modal>
                        </Portal>
                        {/*<TouchableOpacity*/}
                        {/*    onPress={() => this.clearCacheData()}*/}
                        {/*    style={styles.button_alt}>*/}
                        {/*    <View>*/}
                        {/*        <Text style={styles.buttonTitle}>{"Submit Pending Deliveries"}</Text>*/}
                        {/*    </View>*/}
                        {/*</TouchableOpacity>*/}
                    </View>
                </KeyboardAwareScrollView>
                <Text style={{textAlignVertical: "center", textAlign: "center", marginBottom: 5, color: "#7fbe41"}}>
                    {this.state.distributorName}
                </Text>
                <Text style={{textAlignVertical: "center", textAlign: "center", marginBottom: 25}} onPress={()=> {this.clearCacheData()}}>
                    Current Version: {packageJson.version}
                </Text>
                {/*<Text style={{ bottom: 25, position: 'absolute', right: 5 }}>Current Version: {this.state.currVersion}</Text>*/}

                <Snackbar
                    visible={this.state.toastVisible}
                    onDismiss={() => this.setState({toastVisible: false})}
                    action={{
                        label: 'Close',
                        onPress: () => {
                        },
                    }}
                    style={{backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center'}}
                >
                    <Text>{this.state.toastBody}</Text>
                </Snackbar>
            </View>
        )
    }
}
