import { Image, View } from 'react-native';

import AdminExtraFunctionsSearch from './AdminExtraFunctionsSearch'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import styles from '../../styles';

export default function AdminExtraFunctionsSearchScreen(props) {
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View style={{ flex: 1, width: '100%', height: '100%' }}>
                    <AdminExtraFunctionsSearch {...props} extraData={props.route.params.Id} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}