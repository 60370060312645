import {
    ActivityIndicator,
    AsyncStorage,
    Dimensions,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from 'react-native'

import {DataTable, Modal, Portal} from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { Searchbar, Checkbox } from 'react-native-paper'
import {getDataAPI, setDataAPI} from '../_shared/ERP_API'
import styles from '../../styles';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const containerStyle = {backgroundColor: 'white', padding: 20 };

export default class AdminUserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            filteredData: [],
            partnerVisible: false,
            dimensions: {
                window,
                screen
            },
            distributorList: [],
            userDistributors: [],
            checked: [],
            selectedUserId: null
        };
    }

    selectDistributor(index) {
    const distributors = [...this.state.distributorList]
    distributors[index][2] = !distributors[index][2]
    this.setState({ distributorList: distributors  })
  }
    async loadDistributorsList() {
      var requestBody = {
          context: 'getDistrubutorsList'
      }
      var data = await getDataAPI(requestBody)
      var _data = [];
        data.map(d => {
            d.push(false)
        })
      this.setState({ distributorList: data  })

    }

      async loadUserDistributorsList(userId) {

      var requestBody = {
          context: 'getUserDistrubutors',
          UserId: userId
      }
      var data = await getDataAPI(requestBody)

          data.map( d => {
              const index = this.state.distributorList.findIndex(item => item[0] == d[0])
              // console.log('index',index, d,  this.state.distributorList)
              if (index >= 0) {
                  this.selectDistributor(index)
              }
          })
  }

    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
            return item['name'].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
    };

    hideDialog() {
        this.setState({partnerVisible: false})
        this.saveChanges()
        this.setState({selectedUserIs: null})
    }
    openDialog(userId){
        this.loadUserDistributorsList(userId).then(()=>{
            this.setState({ partnerVisible: true})
            this.setState({selectedUserId: userId})

        })

    }

    async saveChanges(){
        var selected = []
        this.state.distributorList.map( d => {
            if(d[2] == true) {
                selected.push(d[0])
            }
        })

        var requestBody = {
            context: 'submitUserDistributors',
            distributorsList: JSON.stringify(selected),
            userId: this.state.selectedUserId

        }
        var response = await setDataAPI(requestBody)

        // this.setState({ result: response[0] })
        this.loadDistributorsList()
        this.getData()

    }

    processData = (data) => {
        let resultData = []
        let sortedData = data.sort()

        resultData.push(
            {
                id: sortedData[0][0],
                name: sortedData[0][1],
                distributors: [
                    {
                        id: sortedData[0][3] ? sortedData[0][3]  : null,
                        name: sortedData[0][4] ? sortedData[0][4] : null
                    }
                ],
                userId: sortedData[0][2]
            }
        )

        sortedData.map( d => {
            // console.log(d)
            // debugger
            const index = resultData.findIndex( obj => {
                return obj.id == d[0]
            })

            if (index >= 0) {
                resultData[index].distributors.push(
                    {
                        id: d[3],
                        name: d[4]
                    }
                )
            } else {
                resultData.push(
                {
                    id: d[0],
                    name: d[1],
                    distributors: [
                        {
                            id: d[3] ? d[3] : null,
                            name: d[4] ? d[4] : null
                        }
                    ],
                    userId: d[2]
                }
        )
            }
        })

        return resultData
    }

    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    };

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true })
            this.getData()
            this.loadDistributorsList()
            Dimensions.addEventListener("change", this.onChange);
        })


    }
    async getData() {
        var requestBody = {
            context: 'getAllUsersWithPartners',
        }
        var usersList = await getDataAPI(requestBody)
        usersList = this.processData(usersList)
        this.setState({
            isLoading: false,
            data: usersList,
            filteredData: usersList,
        })
    }


    render() {
        const { dimensions } = this.state;
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1 }}>
                    <Searchbar placeholder="Search user..." onChangeText={this.search} value={this.state.searchText} />
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }

            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search User..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>User Name</DataTable.Title>
                                    <DataTable.Title>Partner</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData.map(user => {
                                                return (
                                                    <DataTable.Row key={user.id}
                                                        onPress={() => {
                                                         this.openDialog(user.userId)
                                                        }}
                                                    >
                                                        <DataTable.Cell>
                                                            { user.name }
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            <Text numberOfLines={user.distributors.length} key={user.id}>
                                                            {
                                                                user.distributors.map( d => {
                                                                    return (
                                                                        <p key={d.id} style={ d.id ? { color: "white", backgroundColor: "green", padding: "5px" } : {  padding: "5px" } }>
                                                                        {d.name}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                            </Text>

                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                        <Portal>
                                        <Modal visible={this.state.partnerVisible} onDismiss={() => { this.hideDialog() }} contentContainerStyle={containerStyle}>
                                              <View>
                                                 <Text style={[styles.title, { paddingTop: 15 }]}>Select Distributor</Text>
                                                     {this.state.distributorList && this.state.distributorList.map( (value, index) => {
                                                       return ( <Checkbox.Item label={value[1]} status={value[2] ? "checked" : "unchecked"} onPress={ () => this.selectDistributor(index) } /> )
                                                     })
                                                     }
                                                    <TouchableOpacity
                                                        onPress={() => this.hideDialog()}
                                                        style={styles.button_alt}>
                                                        <View>
                                                            <Text style={styles.buttonTitle}>{"Save"}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                            </View>
                                        </Modal>
                                    </Portal>
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )

    }
}
