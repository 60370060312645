import { Image, View } from 'react-native';

import AddReading from './AddReading'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import styles from '../../styles';

export default function AddReadingScreen(props) {

    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <AddReading {...props} extraData={props.route.params.Id} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}