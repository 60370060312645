import { Image, View } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import RouteManagement from './RouteManagement'
import styles from '../../styles';

export default function RouteManagementScreen(props) {

    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <RouteManagement {...props} extraData={props.route.params.Id} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}