import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center'
    },
    container_margin: {
        flex: 1,
        alignItems: 'center',
        margin: 20
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    label: {
        fontSize: 20,
        fontWeight: 'normal',
        alignSelf: 'center'
    },
    subTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
        paddingTop: 10,
        marginHorizontal: '2%',
        flex: 1
    },
    subTitle_ios: {
        fontSize: 30,
        fontWeight: 'bold',
        marginHorizontal: '2%',
        alignSelf: 'center',
        flex: 1
    },
    phoneText: {
        fontSize: 20,
        color: '#0000FF'
    },
    logo: {
        flex: 1,
        height: 100,
        width: 75,
        alignSelf: 'center',
        margin: 20
    },
    logoSm: {
        flex: 1,
        height: 60,
        width: 60,
        alignSelf: 'center',
        margin: 5
    },
    icon: {
        flex: 1,
        height: 10,
        width: 10,
        alignSelf: 'flex-end'
    },
    input: {
        height: 48,
        borderRadius: 5,
        overflow: 'hidden',
        backgroundColor: 'white',
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 30,
        marginRight: 30,
        paddingLeft: 16
    },
    button: {
        backgroundColor: '#7fbe41',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    button_fixed: {
        backgroundColor: '#7fbe41',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: '5%',
        width: '5%',
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonColumnA: {
        backgroundColor: '#7fbe41',
        width: '40%',
        marginLeft: '7%',
        marginRight: '3%',
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonColumnB: {
        backgroundColor: '#7fbe41',
        width: '40%',
        marginLeft: '3%',
        marginRight: '7%',
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    marginBottom: {
        marginBottom: 20
    },
    skipButton: {
        backgroundColor: 'rgb(52, 93, 174)',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 20,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: '#ffffff',
        borderWidth: 1
    },
    greyButtonNav: {
        backgroundColor: '#9b9e9c',
        width: '40%',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    button_alt: {
        backgroundColor: '#345DAE',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },

    button_switch: {
        backgroundColor: '#7fbe41',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    button_red: {
        backgroundColor: '#FF0000',
        marginLeft: 30,
        marginRight: 30,
        marginTop: 20,
        height: 48,
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonTitle: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },
    largeText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    footerView: {
        flex: 1,
        alignItems: 'center',
        marginTop: 20
    },
    footerText: {
        fontSize: 16,
        color: '#2e2e2d'
    },
    footerLink: {
        color: '#788eec',
        fontWeight: 'bold',
        fontSize: 16
    },
    // for text area on farmDetails
    inputContainerStyle: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20
    },
    viewContainer: {
        flex: 1,
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: 'grey',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: '7%',
        marginRight: '7%',
        marginTop: '5%'
    },
    viewContainer_alt: {
        flex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: '7%',
        marginRight: '7%',
        marginTop: '5%'
    },
    modalView: {
        backgroundColor: 'white',
        borderWidth: 3,
        borderColor: 'grey',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '5%'
    },
    textArea: {
        height: 120
    },
    textFrame: {
        borderWidth: 1,
        borderColor: 'grey',
        backgroundColor: 'white'
    },
    cellFrame: {
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'red',
        backgroundColor: 'white',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10
    },
    list: {
        flexDirection: 'column'
    },
    contentContainer: {
        width: window.width,

        ...Platform.select({
            ios: {
                paddingHorizontal: 30,
            },

            android: {
                paddingHorizontal: 0,
            }
        })
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: 16,
        height: 80,
        flex: 1,
        marginTop: 7,
        marginBottom: 12,
        borderRadius: 4,


        ...Platform.select({
            ios: {
                width: window.width - 30 * 2,
                shadowColor: 'rgba(0,0,0,0.2)',
                shadowOpacity: 1,
                shadowOffset: { height: 2, width: 2 },
                shadowRadius: 2,
            },

            android: {
                width: window.width - 30 * 2,
                elevation: 0,
                marginHorizontal: 30,
            },
        })
    },
    buttonSlim: {
        backgroundColor: '#7fbe41',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: 50,
        height: 50,
        marginRight: 30,
        borderRadius: 25,
    },
    dataCellA: {
        flex: 1,
        width: '80%'
    },
    dataCellB: {
        flex: 1,
        width: '20%'
    },
    greyButton_reduceMargin: {
        backgroundColor: '#9b9e9c',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeButton_reduceMargin: {
        backgroundColor: '#7fbe41',
        width: '40%',
        marginLeft: '7%',
        marginRight: '3%',
        marginTop: 20,
        marginBottom: 20,
        height: 48,
        borderRadius: 5,
        borderColor: 'black',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    description: {
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: 20,
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold'
    },
    sort_container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eee',

        ...Platform.select({
            ios: {
                paddingTop: 20,
            },
        }),
    },
    floatingBugButton: {
        position: 'absolute',
        top: '80%',
        left: 10,
        borderRadius:25,
        borderWidth:1,
        borderColor:'rgba(0,0,0,0.2)',
        alignItems:'center',
        justifyContent:'center',
        width:50,
        height:50,
        backgroundColor: 'rgba(226, 12, 12, 0.7)',
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 15 ,
        shadowOffset : { width: 1, height: 13},
        textAlign: 'center'
    },
})