import { ActivityIndicator, Linking, Platform, TouchableOpacity, View } from 'react-native'
import { Button, Caption, DataTable, Modal, Portal, Snackbar, Text, TextInput, Title } from 'react-native-paper'
import { getDataAPI, setDataAPI } from '../_shared/ERP_API'

import { Entypo } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { captureScreen } from 'react-native-view-shot';
import styles from '../../styles';

export default class DeliveryDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toastVisible: false, screenshot: {}, isLoading: true, focused: false, comments: '', displayed: 0, adisplayed: 0, accuracyHistoryList: [], isCollapsed: [true, true, true, true, true, true], AssignedToastVisible: false, productsList: [], amountsList: []
        }; //, vdisplayed: 0, addVisitVisible: false  };
    }

    componentDidMount() {
        console.log('reloaded')
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({
                toastVisible: false, screenshot: {}, isLoading: true, focused: false, comments: '', displayed: 0, adisplayed: 0, accuracyHistoryList: [], isCollapsed: [true, true, true, true, true, true], AssignedToastVisible: false, productsList: [], amountsList: []
            }) //, vdisplayed: 0,addVisitVisible: false  })
            this.getData()
            console.log(this.props.route.params)
        })

    }
    async getData() {
        this.setState({ isLoading: true })
        var requestBody = {
            context: 'deliveryHistory',
            FarmId: this.props.route.params.Id
        }
        var history = await getDataAPI(requestBody)
        this.setState({
            data: history

        })
        //await this.visitHistory()
        let isOnline = await this.checkIfOnlineDevice()
        await this.getAccuracyHistory(isOnline)
        await this.checkForAssignedDelivery()

        this.setState({ isLoading: false })

    }
    async checkForAssignedDelivery() {

        var requestBody = {
            context: 'getAssignedProductByFarm',
            FarmId: this.props.route.params.Id
        }
        var products = await getDataAPI(requestBody)

        console.log("Response of products: ", JSON.stringify(products))
        let cleanProducts = []
        for (let i = 0; i < products.length; i++) {
            if (typeof products[i][0] === 'string') {
                if (products[i][0].length > 0) {
                    cleanProducts.push(JSON.parse(products[i][0]));
                }
            }
        }
        console.log("cleanProducts: ", cleanProducts)
        let productsList = []
        let amountsList = []
        for (let i = 0; i < cleanProducts.length; i++) {
            for (const key in cleanProducts[i]) {
                if (cleanProducts[i].hasOwnProperty(key)) {
                    if (typeof cleanProducts[i][key]['Products'] != 'undefined') {
                        console.log(cleanProducts[i][key]['Products'])
                        for (let k = 0; k < cleanProducts[i][key]['Products'].length; k++) {
                            if (productsList.includes(cleanProducts[i][key]['Products'][k])) {
                                for (let j = 0; j < productsList.length; j++) {
                                    if (cleanProducts[i][key]['Products'][k] == productsList[j]) {
                                        amountsList[j] = amountsList[j] + parseInt(cleanProducts[i][key]['Amounts'][k], 10)
                                    }
                                }
                            }
                            else {
                                if (cleanProducts[i][key]['Products'][k] != 'No Product' && parseInt(cleanProducts[i][key]['Amounts'][k]) != 0) {
                                    productsList.push(cleanProducts[i][key]['Products'][k])
                                    amountsList.push(parseInt(cleanProducts[i][key]['Amounts'][k], 10))
                                }
                            }

                        }
                    }
                }
            }
        }
        console.log("productsList: ", productsList)
        console.log("amountsList: ", amountsList)
        if (productsList[0]) {
            this.setState({ AssignedToastVisible: true, productsList: productsList, amountsList: amountsList })
        }


    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getAccuracyHistory(isOnline) {
        var requestBody = {
            context: 'getAccuracyHistory',
            FarmId: this.props.route.params.Id,
            Online: isOnline
        }
        var ahistory = await getDataAPI(requestBody)
        // console.log(ahistory)
        this.setState({
            accuracyHistoryList: ahistory,
            accuracyExists: (ahistory == undefined || ahistory == null || ahistory['1week'].length == 0) ? false : true
        })
    }

    async checkIfOnlineDevice() {
        var requestBody = {
            context: 'checkIfOnline',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        if (data == 'False' || data.length == 0 || data == null) {
            this.setState({
                isOnline: false
            })
            return false
        }

        this.setState({
            isOnline: true
        })
        return true

    }
    addReading() {
        if (this.state.isOnline == false) {
            return <TouchableOpacity style={styles.button}
                onPress={() => this.props.navigation.navigate('Add Reading', {
                    Id: this.props.route.params.Id,
                    user: this.props.route.params.user,
                    DeviceId: this.props.route.params.DeviceId,
                    customer: this.props.route.params.customer
                })}
            >
                <Text style={styles.buttonTitle} theme={{ colors: { text: 'rgb(43,43,43)' } }}>Add Reading</Text>
            </TouchableOpacity>
        }
        return
    }
    showVisitModal = () => {
        this.setState({ addVisitVisible: true })

    }
    hideVisitModal = () => {
        this.setState({ addVisitVisible: false })
    }

    async getVolumes() {
        var requestBody = {
            context: 'tankVolumes',
            DeviceId: this.props.route.params.DeviceId
        }
        var data = await getDataAPI(requestBody)
        return data
    }

    reNavigate() {
        // console.log("in test navigate")
        this.props.navigation.navigate('Farm Details', {
            Id: this.props.route.params.Id,
            user: this.props.route.params.user,
            DeviceId: this.props.route.params.DeviceId,
            customer: this.props.route.params.CompanyName,
            DeliveryMessage: this.state.returnMsg == 'success' ? this.state.returnMsg : 'error'
        })
    }
    accordian(tankNum) {
        var tanksCollapsed = this.state.isCollapsed
        let currentstate = tanksCollapsed[tankNum - 1]
        tanksCollapsed = [true, true, true, true, true, true]
        tanksCollapsed[tankNum - 1] = !currentstate
        this.setState({
            isCollapsed: tanksCollapsed,
            adisplayed: 0
        })
    }
    getTankDisplay(item, tankNum) {
        if (item.length > 0) {
            // console.log(item)
            if (this.state.isCollapsed[tankNum - 1]) {
                return (
                    <DataTable>
                        <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                            <DataTable.Title onPress={() => (this.accordian(tankNum))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNum}</Title></DataTable.Title>
                        </DataTable.Header>
                    </DataTable>
                )
            } else {
                return (
                    <DataTable>
                        <DataTable.Header style={{ backgroundColor: 'rgba(127,190,65,0.3)' }}>
                            <DataTable.Title onPress={() => (this.accordian(tankNum))}><Title theme={{ colors: { text: 'rgb(43,43,43)' } }}>Tank {tankNum}</Title></DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row key={'headers'}>
                            <DataTable.Cell>
                                <Caption>Date</Caption>
                            </DataTable.Cell>
                            <DataTable.Cell>
                                <Caption>Accuracy</Caption>
                            </DataTable.Cell>
                        </DataTable.Row>
                        {
                            item.map(accuracyHistory => {
                                if (accuracyHistory[6] != null)
                                    if (this.state.adisplayed < 5) {
                                        this.state.adisplayed++;
                                        return (
                                            <DataTable.Row key={accuracyHistory[6] + '-' + this.state.adisplayed}>
                                                <DataTable.Cell>
                                                    {accuracyHistory[6].substring(0, accuracyHistory[6].indexOf(':') - 2)}
                                                </DataTable.Cell>
                                                <DataTable.Cell>
                                                    {accuracyHistory[5]}
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        )
                                    }
                            })
                        }
                    </DataTable>
                )
            }
        }
        return
    }
    getAccuracyHistoryTable() {
        if (this.state.accuracyExists) {
            let accuracyList = this.state.accuracyHistoryList['1week']
            let tanks = { 'TANK1': [], 'TANK2': [], 'TANK3': [], 'TANK4': [], 'TANK5': [], 'TANK6': [] }
            for (let i = 0; i < accuracyList.length; i++) {
                switch (accuracyList[i][1]) {
                    case "TANK1":
                        tanks['TANK1'].push(accuracyList[i])
                        break;
                    case "TANK2":
                        tanks['TANK2'].push(accuracyList[i])
                        break;
                    case "TANK3":
                        tanks['TANK3'].push(accuracyList[i])
                        break;
                    case "TANK4":
                        tanks['TANK4'].push(accuracyList[i])
                        break;
                    case "TANK5":
                        tanks['TANK5'].push(accuracyList[i])
                        break;
                    case "TANK6":
                        tanks['TANK6'].push(accuracyList[i])
                        break;
                    default:
                        break;
                }
            }
            // console.log(tanks)
            return (
                <View style={{ marginTop: '2%' }}>
                    {this.getTankDisplay(tanks['TANK1'], 1)}
                    {this.getTankDisplay(tanks['TANK2'], 2)}
                    {this.getTankDisplay(tanks['TANK3'], 3)}
                    {this.getTankDisplay(tanks['TANK4'], 4)}
                    {this.getTankDisplay(tanks['TANK5'], 5)}
                    {this.getTankDisplay(tanks['TANK6'], 6)}
                </View>
            )
        }
        return <View><Text style={{ marginLeft: '33%', marginTop: '7%' }} theme={{ colors: { text: 'rgb(43,43,43)' } }}>No Data Available</Text></View>
    }

    shewAssignedProductsModal() {
        return (
            <View style={[styles.modalView, { margin: '5%', width: 250, height: 200, alignItems: 'center', alignSelf: 'center' }]}>
                <Text style={[styles.title, { marginBottom: 20, marginTop: 10 }]}>Assigned deliveries</Text>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[0] ? this.state.productsList[0] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[0] ? this.state.amountsList[0] + 'kg' : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[1] ? this.state.productsList[1] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[1] ? this.state.amountsList[1] + 'kg' : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[2] ? this.state.productsList[2] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[2] ? this.state.amountsList[2] + 'kg' : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[3] ? this.state.productsList[3] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[3] ? this.state.amountsList[32] + 'kg' : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[4] ? this.state.productsList[4] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[4] ? this.state.amountsList[4] + 'kg' : ''}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.title}>{this.state.productsList[5] ? this.state.productsList[5] + ':  ' : ''}</Text>
                    <Text style={styles.title}>{this.state.amountsList[5] ? this.state.amountsList[5] + 'kg' : ''}</Text>
                </View>
                <Button style={{ margin: 10, backgroundColor: '#FF0000' }} mode="contained" onPress={() => { this.setState({ AssignedToastVisible: false }) }}>Exit</Button>
            </View>
        )
    }

    async sleepToast(i) {
        await this.sleep(i)
        this.setState({ toastVisible: false })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    _saveScreenshotAsync = async () => {
        try {
            await captureScreen({
                format: "png",
                quality: 0.8
            }).then(
                uri => this.setState({ screenshot: uri, isLoading: true }),
                error => console.error("Oops, snapshot failed", error)
            )

        } catch (e) {
            console.log("ERROR: ", e)
        }


    }

    render() {
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1, padding: 20 }}>
                    <ActivityIndicator style={{ marginTop: '20%' }} />
                </View>
            );
        }
        else if (this.state.isLoading == false) {
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <View>
                            <Text style={styles.title} theme={{ colors: { text: 'rgb(43,43,43)' } }}>Delivery Details</Text>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Date</DataTable.Title>
                                    <DataTable.Title>Delivered By</DataTable.Title>
                                </DataTable.Header>
                                {
                                    this.state.data.map(delivery => {
                                        return (
                                            <DataTable.Row key={delivery[0]}
                                                onPress={() => this.props.navigation.navigate('View Delivery', {
                                                    Id: delivery[0],
                                                    user: this.props.route.params.user
                                                })}
                                            >
                                                <DataTable.Cell>
                                                    {delivery[2].substring(0, delivery[2].indexOf(':') - 2)}
                                                </DataTable.Cell>
                                                <DataTable.Cell>
                                                    {delivery[3]}
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        )

                                    })
                                }
                            </DataTable>

                            {this.state.isOnline == true ?
                                <TouchableOpacity style={styles.button}
                                    onPress={() => this.props.navigation.navigate('Add Delivery', {
                                        Id: this.props.route.params.Id,
                                        user: this.props.route.params.user,
                                        customer: this.props.route.params.customer,
                                        DeviceId: this.props.route.params.DeviceId

                                    })}
                                >
                                    <Text style={styles.buttonTitle} theme={{ colors: { text: 'rgb(43,43,43)' } }}>Add Delivery</Text>
                                </TouchableOpacity> :
                                <View></View>
                            }


                            {
                                this.addReading()
                            }
                            <View
                                style={{
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 2,
                                    marginBottom: 20,
                                    marginTop: 20
                                }}
                            />

                            <Text style={styles.title} theme={{ colors: { text: 'rgb(43,43,43)' } }}>Accuracy History</Text>
                            {this.getAccuracyHistoryTable()}
                        </View>
                    </KeyboardAwareScrollView>
                    <Portal>
                        <Modal visible={this.state.AssignedToastVisible} onDismiss={() => this.setState({ AssignedToastVisible: false })}>
                            {this.shewAssignedProductsModal()}
                        </Modal>
                    </Portal>

                    <Snackbar
                        visible={this.state.toastVisible}
                        onDismiss={() => this.setState({ toastVisible: false })}
                        action={{
                            label: 'Close',
                            onPress: () => {
                            },
                        }}
                        style={{ backgroundColor: "#345DAE", bottom: 50, position: 'absolute', alignSelf: 'center' }}
                    >
                        <Text>{this.state.toastBody}</Text>
                    </Snackbar>
                </View>
            )
        }
    }
}


    // async visitHistory(){
    //     var requestBody = {
    //         context: 'getVisitHistory',
    //         FarmId: this.props.route.params.Id
    //     }
    //     var vhistory = await getDataAPI(requestBody)
    //     this.setState({
    //         visitData: vhistory
    //     })
    // }

    // async saveVisit() {
    //     var tank1Details = {}
    //     var tank2Details = {}
    //     var tank3Details = {}
    //     var tank4Details = {}
    //     var tank5Details = {}
    //     var tank6Details = {}
    //     //get tank details first then add to object and submit
    //     var tankData = await this.getVolumes()
    //     for (var i = 0; i < tankData.length; i++) {
    //         var tempObj = {
    //             "Dosage": tankData[i][4],
    //             "Accuracy": tankData[i][7],
    //             "Calibration": tankData[i][6]
    //         }
    //         switch (i) {
    //             case 0:
    //                 tank1Details = tempObj
    //                 break;
    //             case 1:
    //                 tank2Details = tempObj
    //                 break;
    //             case 2:
    //                 tank3Details = tempObj
    //                 break;
    //             case 3:
    //                 tank4Details = tempObj
    //                 break;
    //             case 4:
    //                 tank5Details = tempObj
    //                 break;
    //             case 5:
    //                 tank6Details = tempObj
    //                 break;
    //         }
    //     }

    //     var requestBody = {
    //         context: 'saveVisit',
    //         FarmId: this.props.route.params.Id,
    //         Comments: this.state.comments,
    //         VisitBy: (this.props.route.params.user.firstName + ' ' + this.props.route.params.user.secondName),
    //         Tank1: JSON.stringify(tank1Details),
    //         Tank2: JSON.stringify(tank2Details),
    //         Tank3: JSON.stringify(tank3Details),
    //         Tank4: JSON.stringify(tank4Details),
    //         Tank5: JSON.stringify(tank5Details),
    //         Tank6: JSON.stringify(tank6Details)
    //     }
    //     console.log("before set data")
    //     var data = await setDataAPI(requestBody)
    //     console.log("before test navigate")
    //     this.setState({ comments: '', returnMsg: data[0] })
    //     this.hideVisitModal()
    //     this.reNavigate()
    //     console.log(this.state.returnMsg)


    // }
    // visitCommentsAndSubmit(){
    //     return (
    //         <View style={[styles.modalView, { margin: '5%', width: '90%', height: 250 }]}>
    //             <Text style={styles.title}>Visit comments</Text>
    //             <TextInput
    //                 style={[styles.inputContainerStyle, styles.textArea, { height: this.state.focused ? 128 : 48 }]}
    //                 label="Visit comments"
    //                 multiline
    //                 placeholder="Any visit comments"
    //                 onFocus={() => { this.setState({ focused: true }) }}
    //                 onBlur={() => { this.setState({ focused: false }) }}
    //                 theme={{
    //                     colors: {
    //                         placeholder: 'rgb(43,43,43)', text: 'rgb(43,43,43)', primary: 'rgb(43,43,43)',
    //                         underlineColor: 'transparent', background: 'rgb(239, 239, 239)'
    //                     }
    //                 }}
    //                 value={this.state.comments}
    //                 onChangeText={comments => this.setState({ comments })
    //                 }
    //             />
    //             <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

    //                 <TouchableOpacity style={[styles.button_red, { width: '40%', marginVertical:'4%', marginLeft: 20 }]}
    //                 onPress={() => { this.hideVisitModal() }}>
    //                 <Text style={styles.buttonTitle}>Close</Text>
    //                 </TouchableOpacity>

    //                 <TouchableOpacity style={[styles.button, { width: '40%', marginVertical:'4%', marginRight: 20 }]}
    //                     onPress={() => this.saveVisit()}
    //                 >
    //                 <Text style={styles.buttonTitle}>Save visit</Text>
    //                 </TouchableOpacity>

    //             </View>
    //         </View>
    //     )
    // }
/* <Text style={styles.title}>Visit Details</Text>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Date</DataTable.Title>
                                    <DataTable.Title>Visit By</DataTable.Title>
                                </DataTable.Header>
                                {
                                    this.state.visitData.reverse().map(visit => {
                                        if (this.state.vdisplayed < 5) {
                                            this.state.vdisplayed++;
                                            return (
                                                <DataTable.Row key={visit[0]}
                                                    onPress={() => this.props.navigation.navigate('View Visits', {
                                                        Id: visit[0],
                                                        user: this.props.route.params.user,
                                                        visitData: this.state.visitData
                                                    })}
                                                >
                                                    <DataTable.Cell>
                                                        {visit[2].substring(0, visit[2].indexOf(':') - 2)}
                                                    </DataTable.Cell>
                                                    <DataTable.Cell>
                                                        {visit[3]}
                                                    </DataTable.Cell>
                                                </DataTable.Row>
                                            )
                                        }
                                    })
                                }
                            </DataTable>
                            <TouchableOpacity style={styles.button}
                                onPress={() => this.showVisitModal()}
                            >
                            <Text style={styles.buttonTitle}>Add Visit</Text>
                            </TouchableOpacity>
                            <Portal>
                            <Modal visible={this.state.addVisitVisible} onDismiss={() => { this.hideVisitModal() }}>
                                {this.visitCommentsAndSubmit()}
                            </Modal>
                            </Portal> */
