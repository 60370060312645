import { Image, TouchableOpacity, View } from 'react-native';

import React from 'react'
import { useNavigation } from '@react-navigation/native';

export default function ActionBarLogo()  {
      const navigation = useNavigation();  
      return (
        <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
          <TouchableOpacity onPress={()=> navigation.navigate('Home')}>
          <Image
            source={require('../../../assets/splash.png')}
            style={{ width: 50, height: 50 }}
          />
          </TouchableOpacity>  
        </View> 
      ); 
  }