import '../../globals.js'

import * as scale from 'd3-scale'

import { ActivityIndicator, Dimensions, SafeAreaView, ScrollView, Text, TouchableHighlight, TouchableOpacity, View } from 'react-native'
import { AreaChart, BarChart, Grid, XAxis, YAxis } from 'react-native-svg-charts'
import { Button, DataTable, Modal, Portal, Title } from 'react-native-paper'

import AdminMenu from '../../../components/device_info.js';
import { AntDesign } from '@expo/vector-icons';
import Drawer from 'react-native-drawer';
import React from 'react'
import drawerStyles from '../../drawerStyles'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

export default class AdminManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true, displayed: 0, drawerOpen: null };
    }

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({
                drawerOpen: false,
                isLoading: true,
                modalVisible: false,
                screenHeight: Math.round(Dimensions.get('window').height),
                screenWidth: Math.round(Dimensions.get('window').width)
            })
            this.onChange
            Dimensions.addEventListener("change", this.onChange);

            this.getDashboardIssuesCreatedToday()
            this.getDashboardIssuesResolvedToday()
            this.getDashboardReadingsCount()
            this.getAirlockCount()
            this.getTables()
            this.getDeliveriesToday()

            //last function sets loading to false


        })
    }

    async getOverdosingDevices(){
      var requestBody = {
        context: 'getOverdosing',
          distributorId: this.props.route.params.distributorId
      }
      var overDoseData = await getDataAPI(requestBody)
      // console.log(overDoseData)
      this.setState(
        {
          overDoseData:  overDoseData
        })
    }

    async getUnderdosingDevices(){
      var requestBody = {
        context: 'getUnderdosing',
            distributorId: this.props.route.params.distributorId
      }
      var underDoseData = await getDataAPI(requestBody)
      // console.log(underDoseData)
      this.setState(
        {
          underDoseData:  underDoseData
        })
    }

    async getTables(){
        this.getAirlockTable()
        this.getDeliveryTable()
        this.getIssuesCreatedTable()
        this.getIssuesResolvedTable()
        this.getReadingsTable()
    }

    async getAirlockCount(){
      var requestBody = {
        context: 'getAirlockCount',
          distributorId: this.props.route.params.distributorId
      }
      var airlockCount = await getDataAPI(requestBody)
      // console.log(airlockCount)
      this.setState(
        {
          airlockCount:  airlockCount
        })
    }

    async getAirlockTable(){
      var requestBody = {
        context: 'getAirlocks',
          distributorId: this.props.route.params.distributorId
      }
      var airlockData = await getDataAPI(requestBody)
      // console.log(airlockData)
      this.setState(
        {
          airlockData:  airlockData
        })
    }

    async getDeliveryTable(){
      var requestBody = {
        context: 'getDeliveriesToday',
            distributorId: this.props.route.params.distributorId
      }
      var deliveryData = await getDataAPI(requestBody)
      // console.log(deliveryData)
      this.setState(
        {
          deliveryData:  deliveryData
        })
    }

    async getIssuesCreatedTable(){
      var requestBody = {
        context: 'getIssuesCreatedToday',
            distributorId: this.props.route.params.distributorId
      }
      var issueCreatedData = await getDataAPI(requestBody)
      // console.log(issueCreatedData)
      this.setState(
        {
          issueCreatedData:  issueCreatedData
        })
    }

    async getIssuesResolvedTable(){
      var requestBody = {
        context: 'getIssuesResolvedToday',
            distributorId: this.props.route.params.distributorId
      }
      var issueResolvedData = await getDataAPI(requestBody)
      // console.log(issueResolvedData)
      this.setState(
        {
          issueResolvedData:  issueResolvedData
        })
    }

    async getReadingsTable(){
      var requestBody = {
        context: 'getReadingsToday',
            distributorId: this.props.route.params.distributorId
      }
      var readingsData = await getDataAPI(requestBody)
      // console.log(readingsData)
      this.setState(
        {
          readingsData:  readingsData
        })
    }

    gotoDelivery(data){

      this.hideModal()
      this.props.navigation.navigate('View Delivery', {
        Id: data[3],
        user: this.props.route.params.user
      })

    }

    gotoIssues(){
      this.hideModal()
      this.props.navigation.navigate('View Issues', {
        user: this.props.route.params.user
      })

    }
    renderModalDetails() {
      switch(this.state.modalOption){
        case 'Airlocked':
          return (
            <View style={{ marginLeft: 15 }}>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Tank</DataTable.Title>
                      <DataTable.Title>Mineral</DataTable.Title>
                      <DataTable.Title>% Predicted</DataTable.Title>
                      <DataTable.Title>Days airlocked</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: '100%' }}>
                          {
                              this.state.airlockData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[2]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>Predicted {data[3]}% change</Text>
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>{data[4]} days</Text>
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
          )
        case 'Deliveries':
          return (
            <View style={{ marginLeft: 15 }}>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Time</DataTable.Title>
                      <DataTable.Title>DeliveredBy</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: '100%' }}>
                          {
                              this.state.deliveryData.map(data => {
                                  return (
                                      <DataTable.Row key={data[3]}
                                      onPress={() => this.gotoDelivery(data)
                                        }>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[2]}
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
          )
        case 'Readings':
          return (
            <View style={{ marginLeft: 15 }}>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Time</DataTable.Title>
                      <DataTable.Title>RecordedBy</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: '100%' }}>
                          {
                              this.state.readingsData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[2]}
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
          )
        case 'IssuesC':
          return (
            <View style={{ marginLeft: 15 }}>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Issue</DataTable.Title>
                      <DataTable.Title>CreatedBy</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: '100%' }}>
                          {
                              this.state.issueCreatedData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}
                                      onPress={() => this.gotoIssues(data)
                                      }>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[2]}
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
          )
        case 'IssuesR':
          return (
            <View style={{ marginLeft: 15 }}>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Resolution</DataTable.Title>
                      <DataTable.Title>ResolvedBy</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: '100%' }}>
                          {
                              this.state.issueResolvedData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}
                                      onPress={() => this.gotoIssues(data)
                                      }>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[2]}
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
          )
      }

  }
    renderModal() {
        return (
            <SafeAreaView style={[styles.modalView, { marginTop: '5%', marginBottom: '5%', width: '100%', height: 500, alignSelf: 'center' }]}>
                <Title style={{ margin: 10, alignItems: 'center', alignSelf: 'center' }}>More info</Title>
                <ScrollView style={{height: '80%'}}>{this.renderModalDetails()}</ScrollView>
                <Button style={{ margin: 10, backgroundColor: '#FF0000' }} mode="contained" onPress={() => { this.hideModal() }}>Exit</Button>
            </SafeAreaView>
        )
    }

    handleModalDismiss() {
        this.hideModal()
    }

    showModal = (i) => {
        this.setState({
          modalVisible: true,
          modalOption: i
        })
    }

    hideModal = () => {
        this.setState({ modalVisible: false })
    }

    async getDashboardIssuesCreatedToday(){
      var requestBody = {
          context: 'dashboardIssueCreatedCount',
          distributorId: this.props.route.params.distributorId
      }
      var issueCreatedCount = await getDataAPI(requestBody)
      // console.log(issueCreatedCount)
      this.setState(
        {
          issueCreatedCount:  issueCreatedCount[0]
        })
    }
    async getDashboardIssuesResolvedToday(){
      var requestBody = {
        context: 'dashboardIssueResolvedCount',
          distributorId: this.props.route.params.distributorId
      }
      var issueResolvedCount = await getDataAPI(requestBody)
      // console.log(issueResolvedCount)
      this.setState(
        {
          issueResolvedCount:  issueResolvedCount[0]
        })
    }
    async getDashboardReadingsCount(){
      var requestBody = {
        context: 'dashboardReadingsCount',
          distributorId: this.props.route.params.distributorId
      }
      var dashboardReadingCount = await getDataAPI(requestBody)
      // console.log(dashboardReadingCount)
      this.setState(
        {
          dashboardReadingCount:  dashboardReadingCount[0]
        })
    }

    async getDeliveriesToday(){
      var requestBody = {
        context: 'dashboardDeliveryCount',
          distributorId: this.props.route.params.distributorId
      }
      var deliveryCount = await getDataAPI(requestBody)
      // console.log(deliveryCount)
      this.setState(
        {
          deliveryCount:  deliveryCount[0]
        })

      await this.getOverdosingDevices()
      await this.getUnderdosingDevices()
      await this.getProportionalVolumes()
      await this.getDaysToEmpty()
      await this.getBarchartVolumes()

    }

    async getBarchartVolumes(){
      var requestBody = {
        context: 'dashboardVolumes',
          distributorId: this.props.route.params.distributorId
      }
    var barchartData = await getDataAPI(requestBody)
    // console.log(barchartData)

    const sortedData = barchartData.sort((a, b) => b[4] - a[4])

    var volumeData = []
    var mineralName = []
    var deviceNameData = []
    var deviceTank = []
    var deviceRoute = []

    for(let i=0; i < sortedData.length; i++){
        volumeData[i]=barchartData[i][4]
        mineralName[i]=barchartData[i][3]
        deviceNameData[i]=barchartData[i][1]
        deviceTank[i]=barchartData[i][2]
        deviceRoute[i]=barchartData[i][5]

    }
    // console.log(volumeData)
    this.setState(
        {
            isLoading: false,
            barchartVolumeData: volumeData,
            barchartMineralName: mineralName,
            barchartDeviceName: deviceNameData,
            barchartTankNo: deviceTank,
            barchartRoute: deviceRoute
        })
      // console.log(barchartData)
    }

    async getDaysToEmpty(){
      var requestBody = {
        context: 'dashboardDaysToEmpty',
          distributorId: this.props.route.params.distributorId
      }
    var barchart2Data = await getDataAPI(requestBody)
    // console.log("barchart 2 response: ",barchart2Data)
    var daysLeftData = []
    var mineralName = []
    var deviceNameData = []
    var deviceTank = []
    var deviceRoute = []

    for(let i=0; i < barchart2Data.length; i++){
        daysLeftData[i]=barchart2Data[i][4]
        mineralName[i]=barchart2Data[i][3]
        deviceNameData[i]=barchart2Data[i][1]
        deviceTank[i]=barchart2Data[i][2]
        deviceRoute[i]=barchart2Data[i][5]

    }
    // console.log(daysLeftData)
    this.setState(
        {
            barchart2DaysLeftData: daysLeftData,
            barchart2MineralName: mineralName,
            barchart2DeviceName: deviceNameData,
            barchart2TankNo: deviceTank,
            barchart2Route: deviceRoute
        })
      // console.log(barchart2Data)

    }

    async getProportionalVolumes(){
      var requestBody = {
        context: 'dashboardProportionalVols',
          distributorId: this.props.route.params.distributorId
      }
    var barchart3Data = await getDataAPI(requestBody)

    const sortedBarChart3Data = barchart3Data.sort((a, b) => b[4] - a[4])

    var deviceTankVol = []
    var mineralName = []
    var deviceNameData = []
    var deviceTank = []
    var deviceRoute = []

    for(let i=0; i < sortedBarChart3Data.length; i++){
        deviceTankVol[i]=barchart3Data[i][4]
        mineralName[i]=barchart3Data[i][3]
        deviceNameData[i]=barchart3Data[i][1]
        deviceTank[i]=barchart3Data[i][2]
        deviceRoute[i]=barchart3Data[i][5]

    }
    // console.log("Barchart volumes from proportional tanks: ",deviceTankVol)
    this.setState(
        {
            barchart3VolumeData: deviceTankVol,
            barchart3MineralName: mineralName,
            barchart3DeviceName: deviceNameData,
            barchart3TankNo: deviceTank,
            barchart3Route: deviceRoute
        })
      // console.log("Barchart 3 data: ",barchart3Data)
    }

    onChange = ({ }) => {
        this.setState({
            screenHeight: Math.round(Dimensions.get('window').height),
            screenWidth: Math.round(Dimensions.get('window').width)
        });
    };

    getDrawerSize(){
      if(Math.round(Dimensions.get('window').width) < 600){
        return '60%'
      }
      else {return '40%'}
    }

    exitButton(){
      return (
      <TouchableOpacity style={styles.button_red}
      onPress={() => {this.state.drawerOpen ? this.setState({drawerOpen: false}) : this.setState({drawerOpen: true});}}
      >
        <View>
            <Text style={styles.buttonTitle}>{"Close drawer"}</Text>
        </View>
      </TouchableOpacity>)
    }

    // putting the old admin management menu into the side menu. It is stored in a new component
    renderSideMenuContent = () => {
        return (
          <View style={{height: this.state.screenHeight, width: this.getDrawerSize(), backgroundColor: 'rgba(0, 0, 0, 0.45)'}}>
            <Text style={[styles.title, {width: '80%'}]}>Admin Management</Text>
            <View style={{height: '60%'}}>
            <AdminMenu {...this.props}></AdminMenu>

            </View>
            {this.exitButton()}

          </View>
        )
      }

    //   this is the main content inside the drawer when the drawer is CLOSED
    // charts go in here
      renderMainContent = () => {
        if (!this.state.drawerOpen) {
          return (
            <View style={{height: this.state.screenHeight - 100}}>
                <View style={{height: this.state.screenHeight - 100}}>
                    <ScrollView style={{height: '100%'}}>
                            {this.daysToEmpty()}
                            <View style={{flexDirection: 'column', flex: 1, justifyContent: 'space-between'}}>
                              <View style={{flexDirection: 'row', flex: 1, marginTop: 30, marginLeft: 20, marginRight: 20, justifyContent: 'center'}}>
                                <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                                  <Text style={{fontSize: 25, fontWeight: 'bold'}}>Deliveries: <Text onPress={() => {this.showModal('Deliveries')}} style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.deliveryCount}</Text></Text>
                                </View>
                                <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                                  <Text style={{fontSize: 25, fontWeight: 'bold'}}>Readings: <Text onPress={() => {this.showModal('Readings')}} style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.dashboardReadingCount}</Text></Text>
                                </View>
                              </View>
                              <View style={{flexDirection: 'row', flex: 1, marginTop: 30 , marginLeft: 20, marginRight: 20, justifyContent: 'center'}}>
                                <View style={{flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                  <Text style={{fontSize: 25, fontWeight: 'bold'}}>Active airlocks: <Text onPress={() => {this.showModal('Airlocked')}} style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.airlockCount}</Text></Text>
                                </View>
                              </View>
                              <View style={{flexDirection: 'row', flex: 1, marginTop: 30 , marginLeft: 20, marginRight: 20, marginBottom: 60, justifyContent: 'center'}}>
                                <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                                  <Text style={{fontSize: 25, fontWeight: 'bold'}}>Issues created: <Text onPress={() => {this.showModal('IssuesC')}} style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.issueCreatedCount}</Text></Text>
                                </View>
                                <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                                  <Text style={{fontSize: 25, fontWeight: 'bold'}}>Issues resolved: <Text onPress={() => {this.showModal('IssuesR')}} style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.issueResolvedCount}</Text></Text>
                                </View>
                              </View>
                            </View>
                            {this.below20Barchart()}
                            {this.proportionalVolumeBarchart()}
                            {this.overdosingTable()}
                            {this.underdosingTable()}
                    </ScrollView>
                </View>
            </View>
          )
        } else {
            // this is the main content inside the drawer when the drawer is OPEN
            // charts go in here
          return (
            <View style={{height: this.state.screenHeight - 100}}>
              <View style={{height: this.state.screenHeight - 100}}>
                <ScrollView style={{height: '100%'}}>
                    {this.daysToEmpty()}
                    <View style={{flexDirection: 'column', flex: 1, justifyContent: 'space-between'}}>
                      <View style={{flexDirection: 'row', flex: 1, marginTop: 30 , marginLeft: 20, marginRight: 20, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                          <Text style={{fontSize: 25, fontWeight: 'bold'}}>Deliveries: <Text style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.deliveryCount}</Text></Text>
                        </View>
                        <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                          <Text style={{fontSize: 25, fontWeight: 'bold'}}>Readings: <Text style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.dashboardReadingCount}</Text></Text>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row', flex: 1, marginTop: 30 , marginLeft: 20, marginRight: 20, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                          <Text style={{fontSize: 25, fontWeight: 'bold'}}>Active airlocks: <Text style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.airlockCount}</Text></Text>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row', flex: 1, marginTop: 30 , marginLeft: 20, marginRight: 20, marginBottom: 60, justifyContent: 'center'}}>
                        <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                          <Text style={{fontSize: 25, fontWeight: 'bold'}}>Issues created: <Text style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.issueCreatedCount}</Text></Text>
                        </View>
                        <View style={{flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'center'}}>
                          <Text style={{fontSize: 25, fontWeight: 'bold'}}>Issues resolved: <Text style={{fontSize: 35, fontWeight: 'bold', color: 'red'}}>{this.state.issueResolvedCount}</Text></Text>
                        </View>
                      </View>
                    </View>
                    {this.below20Barchart()}
                    {this.proportionalVolumeBarchart()}
                    {this.overdosingTable()}
                    {this.underdosingTable()}
                </ScrollView>
              </View>
            </View>
          )
        }
      }

      getMoreLocation(){
          if(this.state.drawerOpen){
            if(Math.round(Dimensions.get('window').width) < 600){
              return { marginLeft: -100}
            }
            else return {marginLeft: -100}

          }
          else{
            return  {width: 30}
          }
      }

      getBarColour(index){
        var mineralName = this.state.barchartMineralName
        //minerals: blue, mag: yellow, calvPlus: grey, bloat: orange, phos: purple, biotin: green
        switch(mineralName[index]){
          case 'Magnesium':
            return '#bcc211'
          case 'Minerals':
            return '#03befc'
          case 'Bloat':
            return '#f5ab22'
          case 'Phosphorus':
            return '#da45ff'
          case 'Calving Plus':
            return '#696969'
          case 'Biotin':
            return '#35bf0f'
          case 'Mag plus':
            return '#ff4922'
          case 'Dry Cow':
            return '#0400D1'
        }
      }
      getBarColourProportional(index){
        var mineralName = this.state.barchart3MineralName
        //minerals: blue, mag: yellow, calvPlus: grey, bloat: orange, phos: purple, biotin: green
        switch(mineralName[index]){
          case 'Magnesium':
            return '#bcc211'
          case 'Minerals':
            return '#03befc'
          case 'Bloat':
            return '#f5ab22'
          case 'Phosphorus':
            return '#da45ff'
          case 'Calving Plus':
            return '#696969'
          case 'Biotin':
            return '#35bf0f'
          case 'Mag plus':
            return '#ff4922'
          case 'Dry Cow':
            return '#0400D1'
        }
      }

      getBarColourDaysLeft(index){
        var mineralName = this.state.barchart2MineralName
        //minerals: blue, mag: yellow, calvPlus: grey, bloat: orange, phos: purple, biotin: green
        switch(mineralName[index]){
          case 'Magnesium':
            return '#bcc211'
          case 'Minerals':
            return '#03befc'
          case 'Bloat':
            return '#f5ab22'
          case 'Phosphorus':
            return '#da45ff'
          case 'Calving Plus':
            return '#696969'
          case 'Biotin':
            return '#35bf0f'
          case 'Mag plus':
            return '#ff4922'
          case 'Dry Cow':
            return '#0400D1'
        }
      }


      below20Barchart(){
        const volumeData = this.state.barchartVolumeData
        const mineralName = this.state.barchartMineralName
        const deviceNameData = this.state.barchartDeviceName
        // console.log("VOL DATA!!! ", volumeData)
        // console.log("mineral DATA!!! ", mineralName)
        // console.log("name DATA!!! ", deviceNameData)

        const XaxesSvg = { fontSize: 10,  fill: 'black', originY: -20}
        const YaxesSvg = { fontSize: 10, fill: 'black'}
        const verticalContentInset = { bottom: 5, top: 30 }
        const xAxisHeight = 50

        const newData = volumeData.map(
          (item, index) => ({
            y: item,
            svg: {
              onPressIn: () => {
                // console.log("Pressed: ", index, item);
                //navigate to the device routes screen
                this.props.navigation.navigate('Assign Routes', {
                  routeCode: this.state.barchartRoute[index],
                  user: this.props.route.params.user
               })

              },
              fill: this.getBarColour(index),//return a string with hex value + make legend //this.state.selectItem === index ? '#55C45E' : '#4B9DFB',
              key: index
            }
          })
        );

          return(
            <View style={{ flexDirection: 'column', flex: 1, width: '100%', paddingBottom: 5, marginTop: '5%' }}>
            <Text style={{fontSize: 25, fontWeight: 'bold', alignSelf: 'center'}}>Tanks under 20% volume</Text>

              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#696969' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Calving Plus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#da45ff' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Phosphorus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#35bf0f' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Biotin</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#ff4922' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Mag Plus</Text>
              </View>
              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#bcc211' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Magnesium</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#03befc' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Minerals</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#f5ab22' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Bloat</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#0400D1' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Dry Cow</Text>
              </View>

            <View style={{ flexDirection: 'row', height: 300, padding: 20}}>
              <YAxis
                data={volumeData}
                style={{ marginBottom: 30 }}
                contentInset={verticalContentInset}
                svg={YaxesSvg}
                numberOfTicks={10}
                formatLabel={(value) => `${value}%`}
              />
              <BarChart style={{ flex: 1 }} data={newData} yAccessor={({item}) => item.y} xAccessor={({item}) => item.key} contentInset={{ top: 30, bottom: 30 }}>
                <Grid />
              </BarChart>
            </View>
            <View style={{ height: 90 }}>
              <XAxis
                data={mineralName}
                style={{ height: 90}}
                contentInset={{ left: 0, right: 45}}
                // spacing={15}
                scale={scale.scaleBand}
                svg={{ fontSize: 10, rotation: 55, fill: 'black', originY: 70}}
                formatLabel={(index) => `${deviceNameData[index]}` }
              />
            </View>
          </View>
          )
      }

      daysToEmpty(){

        const daysLeftData = this.state.barchart2DaysLeftData
        const mineralName = this.state.barchart2MineralName
        const deviceNameData = this.state.barchart2DeviceName
        // console.log("days left DATA!!! ", daysLeftData)
        // console.log("mineral DATA!!! ", mineralName)
        // console.log("name DATA!!! ", deviceNameData)

        const XaxesSvg = { fontSize: 10,  fill: 'black', originY: -20}
        const YaxesSvg = { fontSize: 10, fill: 'black'}
        const verticalContentInset = { bottom: 5, top: 30 }
        const xAxisHeight = 50

        const newData = daysLeftData.map(
          (item, index) => ({
            y: item,
            svg: {
              onPressIn: () => {
                // console.log("Pressed: ", index, item);
                //navigate to the device routes screen
                this.props.navigation.navigate('Assign Routes', {
                  routeCode: this.state.barchart2Route[index],
                  user: this.props.route.params.user
               })

              },
              fill: this.getBarColourDaysLeft(index),//return a string with hex value + make legend //this.state.selectItem === index ? '#55C45E' : '#4B9DFB',
              key: index
            }
          })
        );

          return(
            <View style={{ flexDirection: 'column', flex: 1, width: '100%', paddingBottom: 5 }}>
            <Text style={{fontSize: 25, fontWeight: 'bold', alignSelf: 'center'}}>Days to empty</Text>

              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#696969' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Calving Plus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#da45ff' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Phosphorus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#35bf0f' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Biotin</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#ff4922' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Mag Plus</Text>
              </View>
              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#bcc211' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Magnesium</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#03befc' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Minerals</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#f5ab22' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Bloat</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#0400D1' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Dry Cow</Text>
              </View>

            <View style={{ flexDirection: 'row', height: 300, padding: 20}}>
              <YAxis
                data={daysLeftData}
                style={{ marginBottom: 30 }}
                contentInset={verticalContentInset}
                svg={YaxesSvg}
                numberOfTicks={10}
                formatLabel={(value) => `${value} days`}
              />
              <BarChart style={{ flex: 1 }} data={newData} yAccessor={({item}) => item.y} xAccessor={({item}) => item.key} contentInset={{ top: 30, bottom: 30 }}>
                <Grid />
              </BarChart>
            </View>
            <View style={{ height: 90 }}>
              <XAxis
                data={mineralName}
                style={{ height: 90}}
                contentInset={{ left: 0, right: 45}}
                // spacing={15}
                scale={scale.scaleBand}
                svg={{ fontSize: 10, rotation: 55, fill: 'black', originY: 70}}
                formatLabel={(index) => `${deviceNameData[index]}` }
              />
            </View>
          </View>
          )
      }

      proportionalVolumeBarchart(){
        const volumeData = this.state.barchart3VolumeData
        const mineralName = this.state.barchart3MineralName
        const deviceNameData = this.state.barchart3DeviceName
        // console.log("VOL DATA!!! ", volumeData)
        // console.log("mineral DATA!!! ", mineralName)
        // console.log("name DATA!!! ", deviceNameData)

        const XaxesSvg = { fontSize: 10,  fill: 'black', originY: -20}
        const YaxesSvg = { fontSize: 10, fill: 'black'}
        const verticalContentInset = { bottom: 5, top: 30 }
        const xAxisHeight = 50

        const newData = volumeData.map(
          (item, index) => ({
            y: item,
            svg: {
              onPressIn: () => {
                // console.log("Pressed: ", index, item);
                //navigate to the device routes screen
                this.props.navigation.navigate('Assign Routes', {
                  routeCode: this.state.barchart3Route[index],
                  user: this.props.route.params.user
               })

              },
              fill: this.getBarColourProportional(index),//return a string with hex value + make legend //this.state.selectItem === index ? '#55C45E' : '#4B9DFB',
              key: index
            }
          })
        );

          return(
            <View style={{ flexDirection: 'column', flex: 1, width: '100%', paddingBottom: 5, marginTop: '5%' }}>
            <Text style={{fontSize: 25, fontWeight: 'bold', alignSelf: 'center'}}>Proportional tank volumes</Text>

              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#696969' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Calving Plus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#da45ff' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Phosphorus</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#35bf0f' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Biotin</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#ff4922' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Mag Plus</Text>
              </View>
              <View style={{height: 50, flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-evenly', marginTop: 20, marginLeft: 50}}>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#bcc211' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Magnesium</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#03befc' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Minerals</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#f5ab22' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Bloat</Text>
                <Text style={{width: '30%', alignItems: 'center', justifyContent: 'center', marginBottom: 3}}><View style={{width: 20, height: 20, backgroundColor: '#0400D1' , alignItems: 'center', justifyContent: 'center', marginRight: 3}}></View>Dry Cow</Text>
              </View>

            <View style={{ flexDirection: 'row', height: 300, padding: 20}}>
              <YAxis
                data={volumeData}
                style={{ marginBottom: 30 }}
                contentInset={verticalContentInset}
                svg={YaxesSvg}
                numberOfTicks={10}
                formatLabel={(value) => `${value}%`}
              />
              <BarChart style={{ flex: 1 }} data={newData} yAccessor={({item}) => item.y} xAccessor={({item}) => item.key} contentInset={{ top: 30, bottom: 30 }}>
                <Grid />
              </BarChart>
            </View>
            <View style={{ height: 90 }}>
              <XAxis
                data={mineralName}
                style={{ height: 90}}
                contentInset={{ left: 0, right: 45}}
                // spacing={15}
                scale={scale.scaleBand}
                svg={{ fontSize: 10, rotation: 55, fill: 'black', originY: 70}}
                formatLabel={(index) => `${deviceNameData[index]}` }
              />
            </View>
          </View>
          )
      }

    overdosingTable(){
      return(
        <View style={{ width: '100%', paddingBottom: 5, marginTop: '5%' }}>
                <Text style={{fontSize: 25, fontWeight: 'bold', alignSelf: 'center'}}>Overdosing tanks</Text>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Tank</DataTable.Title>
                      <DataTable.Title>Accuracy7</DataTable.Title>
                      <DataTable.Title>Accuracy21</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: 500 }}>
                          {
                              this.state.overDoseData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>{data[2]}</Text>
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>{data[4]}</Text>
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
      )
    }

    underdosingTable(){
      return(
        <View style={{ width: '100%', paddingBottom: 5, marginTop: '5%' }}>
                <Text style={{fontSize: 25, fontWeight: 'bold', alignSelf: 'center'}}>Underdosing tanks</Text>
                <DataTable>
                  <DataTable.Header>
                      <DataTable.Title>Device</DataTable.Title>
                      <DataTable.Title>Tank</DataTable.Title>
                      <DataTable.Title>Accuracy7</DataTable.Title>
                      <DataTable.Title>Accuracy21</DataTable.Title>
                  </DataTable.Header>
                  <SafeAreaView >
                      <ScrollView style={{ height: 500 }}>
                          {
                              this.state.underDoseData.map(data => {
                                  return (
                                      <DataTable.Row key={data[0]}>
                                          <DataTable.Cell>
                                              {data[0]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                              {data[1]}
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>{data[2]}</Text>
                                          </DataTable.Cell>
                                          <DataTable.Cell>
                                            <Text>{data[4]}</Text>
                                          </DataTable.Cell>
                                      </DataTable.Row>
                                  )
                              })
                          }
                      </ScrollView>
                  </SafeAreaView>
              </DataTable>
            </View>
      )
    }

    // main render of the application is wrapped in drawer component and calls functions above for content and drawer status
    render() {
      if(this.state.isLoading){
        return(
          <View style={{ flex: 1, padding: 20 }}>
            <ActivityIndicator style={{marginTop: '20%'}} />
          </View>
        )
      }
        return (
            <View style={{height: this.state.screenHeight - 100, width: this.state.screenWidth, flex: 1}}>
                 {this.renderMainContent()}
            {/*<Drawer*/}
            {/*open={this.state.drawerOpen}*/}
            {/*content={this.renderSideMenuContent()}*/}
            {/*type="overlay"*/}
            {/*tapToClose={true}*/}
            {/*styles={[drawerStyles,{height: '100%'}]}*/}
            {/*openDrawerOffset={0.2}*/}
            {/*panCloseMask={0.2}*/}
            {/*closedDrawerOffset={-3}*/}
            {/*onClose={() => {*/}
            {/*    this.setState({drawerOpen: false});*/}
            {/*}}*/}
            {/*tweenDuration={0.1}*/}
            {/*panOpenMask={0.80}*/}
            {/*captureGestures="open"*/}
            {/*acceptPan={false}>*/}
            {/*    <View style={this.getMoreLocation()}>*/}
            {/*    <AntDesign name="bars" size={40} color="black" onPress={() => {this.state.drawerOpen ? this.setState({drawerOpen: false}) : this.setState({drawerOpen: true});}}>*/}
            {/*    </AntDesign>*/}
            {/*    </View>*/}

            {/*</Drawer>*/}
            <Portal>
                <Modal visible={this.state.modalVisible} onDismiss={() => { this.handleModalDismiss() }} animationType={"slide"}>
                    {this.renderModal()}
                </Modal>
            </Portal>
            </View>
        )
    }
}
