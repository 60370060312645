import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react';
import { View } from 'react-native';
import ViewDelivery from './ViewDelivery'
import styles from '../../styles';

export default function ViewDeliveryScreen(props) {
    return (
        <View style={styles.container}>
            <KeyboardAwareScrollView
                style={{ flex: 1, width: '100%' }}
                keyboardShouldPersistTaps="always">
                <View>
                    <ViewDelivery {...props} extraData={props.route.params.Id} />
                </View>
            </KeyboardAwareScrollView>
        </View>
    )
}