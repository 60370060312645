import { ActivityIndicator, Dimensions, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native'

import { DataTable } from 'react-native-paper'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import React from 'react'
import { Searchbar } from 'react-native-paper'
import { getDataAPI } from '../_shared/ERP_API'
import styles from '../../styles';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export default class SearchViewIssues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            filteredData: [],
            dimensions: {
                window,
                screen
            }
        };
    }

    search = (searchText) => {
        this.setState({ searchText: searchText });

        let filteredData = this.state.data.filter(function (item) {
            return item[13].toLowerCase().includes(searchText.toLowerCase());
        });

        this.setState({ filteredData: filteredData });
    };

    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    };

    componentDidMount() {
        this.focusSubscription = this.props.navigation.addListener('focus', () => {
            this.setState({ isLoading: true })
            this.getData()
            Dimensions.addEventListener("change", this.onChange);
        })


    }
    async getData() {
        var requestBody = {
            context: 'getAllIssues',
            distributorId: this.props.route.params.distributorId
        }
        var history = await getDataAPI(requestBody)
        this.setState({
            isLoading: false,
            data: history,
            filteredData: history,
        })
    }



    render() {
        const { dimensions } = this.state;
        if (this.state.isLoading) {
            return (
                <View style={{ flex: 1 }}>
                    <Searchbar placeholder="Search Issues by farm..." onChangeText={this.search} value={this.state.searchText} />
                    <ActivityIndicator style={{marginTop: '20%'}} />
                </View>
            );
        }
        if (dimensions.window.width < 500) {
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search Issues by farm..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Farm</DataTable.Title>
                                    <DataTable.Title>Date</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData.map(issue => {
                                                return (
                                                    <DataTable.Row key={issue[0]}
                                                        onPress={() => {
                                                            this.props.navigation.navigate('Farm Details', {
                                                                Id: issue[1],
                                                                CompanyName: issue[12],
                                                                DeviceId: issue[11],
                                                                BoxName: issue[13],
                                                                user: this.props.route.params.user
                                                            })
                                                        }}
                                                    >
                                                        <DataTable.Cell>
                                                            {issue[13]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {issue[6].substring(0, issue[6].indexOf(':') - 2)}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )
        } else {
            return (
                <View style={styles.container}>
                    <KeyboardAwareScrollView
                        style={{ flex: 1, width: '100%' }}
                        keyboardShouldPersistTaps="always">
                        <Searchbar placeholder="Search Issues by farm..." onChangeText={this.search} value={this.state.searchText} />
                        <View>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Farm</DataTable.Title>
                                    <DataTable.Title>Date</DataTable.Title>
                                    <DataTable.Title>Recorded by</DataTable.Title>
                                    <DataTable.Title>Issue details</DataTable.Title>
                                    <DataTable.Title>Status</DataTable.Title>
                                </DataTable.Header>
                                <SafeAreaView >
                                    <ScrollView style={{ height: 600 }}>
                                        {
                                            this.state.filteredData.map(issue => {
                                                return (
                                                    <DataTable.Row key={issue[0]}
                                                        onPress={() => {
                                                            this.props.navigation.navigate('Farm Details', {
                                                                Id: issue[1],
                                                                CompanyName: issue[12],
                                                                BoxName: issue[13],
                                                                user: this.props.route.params.user,
                                                                DeviceId: issue[11]
                                                            })
                                                        }}
                                                    >
                                                        <DataTable.Cell>
                                                            {issue[13]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {issue[6].substring(0, issue[6].indexOf(':') - 2)}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {issue[5]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {issue[2]}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell>
                                                            {issue[3] == true ? 'Resolved' : 'Ongoing'}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                )
                                            })
                                        }
                                    </ScrollView>
                                </SafeAreaView>
                            </DataTable>
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            )
        }
    }
}
